import React, { useEffect, useRef, useState } from "react";
import { Translation, withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Box,
  styled,
  Checkbox,
  TextareaAutosize,
  Input,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../../utils/APIRequest";
import FormValidation from "../../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";
import { Stack } from "@mui/material";
import { Chip } from "@mui/material";
import landingImg from "../../../../../assets/images/Pic.png";
import DownloadIcon from "@mui/icons-material/Download";
// import ImageUpload from "./ImageUpload";
import Toaster from "../../../../../utils/Toaster";
import isEmpty from "../../../../../utils/isEmpty";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ImagesUpload from "./ImagesUpload.jsx";
import MicIcon from "@material-ui/icons/Mic";
import LocalStorage from "../../../../../config/LocalStorage.js";
import { CheckValidation } from "../../../../../utils/Validator.js";
import WaveSurfer from "wavesurfer.js";
import {
  Delete20Filled,
  Pause20Filled,
  Play20Filled,
} from "@fluentui/react-icons";
import { DateConvert } from "../../../../../utils/DataConvert.js";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  approveButton: {
    color: "white",
    backgroundColor: "red",
    boxShadow: "0 2px 4px red",
  },
  rejectButton: {
    color: "black",
    backgroundColor: "yellow",
    boxShadow: "0 2px 4px red",
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const postDetails = {
  description: "",
  referenceUrl: "",
  fragmentedAddress: "",
  attachment: [],
  mobileNo: "",
  name: "",
  address: "",
  title: "",
  tagNo: "",
  postType: "sales",
  audio: {
    type: "",
    name: "",
    url: "",
  },
  location: {
    type: "Point",
    coordinates: ["", ""],
  },
};

function AddUser(props) {
  const [selectedOption, setSelectedOption] = useState("");

  const [location, setLocation] = useState("");
  const [places, setPlaces] = useState([]);
  const [placeId, setPlaceId] = useState([]);
  const [locationNames, setlocationNames] = useState("");
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [userForm, setUserForm] = useState(postDetails);
  const [categorydata, setCategoryData] = useState([]);
  const [sectorlist, setSectorlist] = useState([]);
  const [flag, setFlag] = useState(false);

  const [flag1, setFlag1] = useState(false);
  const [addplan, setAddPlan] = useState([]);
  // const [handloom, setHandloom] = useState(postDetails.sector);
  const userDetails = LocalStorage.userDetails;

  useEffect(() => {
    setUserForm((prev) => ({
      ...prev,
      name: userDetails.name,
      mobileNo: userDetails.mobileNo,
    }));
  }, [userDetails]);

  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);

  useEffect(() => {
    if (waveformRef.current && (audioBlob || userForm?.audio)) {
      if (!wavesurfer.current) {
        wavesurfer.current = WaveSurfer.create({
          container: waveformRef.current,
          waveColor: "#ccc",
          progressColor: "#FF8728",
          cursorColor: "#4a90e2",
          barWidth: 2,
          responsive: true,
          height: 50,
        });
        wavesurfer.current.on("finish", () => setIsPlaying(false));
      }

      if (audioBlob) {
        const audioUrl = URL.createObjectURL(audioBlob);
        wavesurfer.current.load(audioUrl);
      } else if (userForm?.audio?.url) {
        wavesurfer.current.load(userForm.audio.url);
      }
    }
  }, [audioBlob, userForm?.audio]);

  // Start Recording
  const startRecording = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mimeType = "audio/webm";
      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType });
      const chunks = [];

      mediaRecorderRef.current.ondataavailable = (event) =>
        chunks.push(event.data);
      mediaRecorderRef.current.onstop = () => {
        const recordedBlob = new Blob(chunks, { type: mimeType });
        setAudioBlob(recordedBlob);
        uploadAudio(recordedBlob); // Upload audio on stop
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } else {
      alert("Your browser does not support audio recording.");
    }
  };

  // Stop Recording
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  // Play/Pause Audio
  const handlePlayPause = () => {
    if (wavesurfer.current) {
      setIsPlaying(!isPlaying);
      wavesurfer.current.playPause();
    }
  };

  // Delete Audio
  const handleDelete = () => {
    setAudioBlob(null);
    wavesurfer.current && wavesurfer.current.destroy();
    wavesurfer.current = null;
    setUserForm((prev) => ({
      ...prev,
      audio: {
        type: "",
        name: "",
        url: "",
      },
    }));
  };

  // Upload Audio to AWS S3
  const uploadAudio = async (blob) => {
    const formdata = new FormData();
    const audioFile = new File([blob], `audio-${Date.now()}.webm`, {
      type: "audio/webm",
    });
    formdata.append("attachment", audioFile);
    formdata.append("bucketName", "profile");

    try {
      const response = await APIRequest.multipartForDataRequest(
        "PUT",
        ConfigAPIURL.uploadFile,
        formdata
      );

      if (response.data.responseCode === 109) {
        const audio = {
          type: "webm",
          name: audioFile.name,
          url: response?.data?.attachmentUrl[0],
        };

        // const updatedMedia = [...(userForm.attachment || []), audio];
        setUserForm({
          ...userForm,
          // attachment: updatedMedia,
          audio, // Add audio details to form
        });

        console.log("Audio uploaded successfully:", audio.url);
      } else {
        console.error("Error uploading audio:", response.data);
      }
    } catch (error) {
      console.error("Error during audio upload:", error);
    }
  };

  useEffect(() => {
    //changes made here
    // setUserForm(postDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (props?.formDialog?.functionName === "reset") {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    setUserForm(JSON.parse(JSON.stringify(postDetails)));
  };
  const categorylist = () => {
    APIRequest.request("GET", ConfigAPIURL.listcategory, "").then(
      (response) => {
        let userResult = response?.data?.result;
        setCategoryData(userResult);
        // console.log(categorydata, "categorydata");
      }
    );
  };

  useEffect(() => {
    categorylist();
  }, []);
  // console.log(categorydata, "data");

  //getting data for sector
  const sectorListData = () => {
    APIRequest.request("GET", ConfigAPIURL.sectorData, "").then((response) => {
      let userResult = response?.data?.result;
      setSectorlist(userResult);
      // console.log(sectorlist, "sectorData");
    });
  };

  useEffect(() => {
    sectorListData();
  }, []);

  // console.log(sectorlist, "sectorlist");

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "GET",
        ConfigAPIURL.postDetails + "?recordId=" + `${props.rowDetails?._id}`
      ).then((response) => {
        // console.log(response, "response");
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let postResult = response?.data?.rows;
            console.log(postResult, "postResult");
            setUserForm({
              ...userForm,

              author: { ...postResult?.author },
              // address: postResult?.address,
              description: postResult?.description,
              referenceUrl: postResult?.referenceUrl,
              isPermanent: postResult?.isPermanent,
              fragmentedAddress: postResult?.fragmentedAddress,
              attachment: postResult?.attachment,
              subject: postResult?.subject,
              title: postResult?.title,
              tagNo: postResult?.tagNo,
              createdAt: postResult?.createdAt,
              name: postResult?.author?.name,
              mobileNo: postResult?.author?.mobileNo,
              audio: postResult?.audio,
              location: {
                type: postResult.location.type,
                coordinates: [
                  postResult?.location?.coordinates?.[0] || "",
                  postResult?.location?.coordinates?.[1] || "",
                ],
              },
              // images: postResult?.images,
            });

            setSelectedRowId(postResult._id);
          }
        }
      });
    }
  };

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        // handleChange();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = async () => {
    let url = ConfigAPIURL.postCreate;
    let method = "POST";

    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.postUpdate;
      userForm["recordId"] = selectedRowId;
      method = "PUT";
    }

    let formCopy = { ...userForm };
    delete formCopy.range;
    delete formCopy.referenceUrl;
    delete formCopy?.startTime;
    delete formCopy?.media;
    delete formCopy?.subject;
    delete formCopy?.tag;
    delete formCopy?.mobileNo;
    delete formCopy?.name;
    delete formCopy?.address;
    delete formCopy?.title;
    delete formCopy?.audio;
    delete formCopy?.tagNo;
    delete formCopy?.sector;
    delete formCopy?.isPermanent;
    delete formCopy?.description;

    const missingFields = CheckValidation(formCopy);
    console.log(missingFields, "missingFields");

    if (missingFields.length > 0 || formCopy?.attachment?.length === 0) {
      SnackbarUtils.error(
        "Please fill all the mandatory fields",
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }

    props.setOpenForm((prev) => ({
      ...prev,
      functionName: "",
      disable: true,
    }));

    let userFormCopy = { ...userForm };

    try {
      const response = await APIRequest.request(
        method,
        url,
        JSON.stringify(userFormCopy)
      );

      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          const messageKey =
            props.formDialog.divType === "new"
              ? "snackbar.savedSuccess"
              : "snackbar.updatedSuccess";

          SnackbarUtils.sucess(props.t(messageKey), "bottomCenter", 3000).then(
            (notification) => {
              props.publishNotification(notification);
            }
          );

          props.handleFormDialog();
          props.tableQuery({
            keyword: "",
            page: 0,
            pageSize: 10,
            sortField: "",
            userType: "all",
            sortOrder: "false",
            postStatus: "all",
            active: true,
            postType: "sales",
          });
          setUserForm(postDetails);
          setSelectedRowId("");
        } else if (
          response.code === 100 &&
          response.data.responseCode === 114
        ) {
          SnackbarUtils.error(
            props.t("snackbar.dupliRecords"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        }
      }
    } catch (error) {
      console.error("Error occurred during API request:", error);
      SnackbarUtils.error(
        "An error occurred while processing your request",
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    } finally {
      props.setOpenForm((prev) => ({
        ...prev,
        disable: false,
      }));
    }
  };

  //functions for getting location cordinates
  const handleChange = async (event) => {
    const placeName = event.target.value;

    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getLocation}?place=${placeName}`
    ).then((res) => {
      // console.log(res.data.result.predictions);
      setPlaces(res.data.result.predictions);
    });
    setLocation("");
  };

  const handleLocation = async () => {
    // console.log("this is evee optionsss", eve);
    // setlocationNames(selectedOption.description);

    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getLonLan}?place_id=${placeId}`
    ).then((res) => {
      setLocation(res.data?.result?.result?.geometry?.location);
      setUserForm((prev) => ({
        ...prev,
        location: {
          ...prev?.location,
          coordinates: [
            res.data?.result?.result?.geometry?.location?.lat,
            res.data?.result?.result?.geometry?.location?.lng,
          ],
        },
      }));
    });
  };
  useEffect(() => {
    handleLocation();
  }, [placeId]);

  useEffect(() => {
    if (selectedOption) {
      // setSelectedOption(selectedOption);
      setlocationNames(selectedOption.description);
      setPlaceId(selectedOption);
      places.find((option, i) => {
        if (option?.description === selectedOption) {
          setPlaceId(option?.place_id);
        }
      });
      // setAddForm({
      //   ...addForm,
      //   locationName: selectedOption.description,
      // });
      handleLocation();
    }
  }, [selectedOption, placeId]);

  const getPlans = () => {
    APIRequest.request("POST", ConfigAPIURL.plans).then((response) => {
      let userResult = response?.data?.rows;
      setAddPlan(userResult);
      // console.log(userResult);
    });
  };
  useEffect(() => {
    getPlans();
  }, []);
  // console.log("plan", addplan);
  // console.log(userForm, "userForm");

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: "black",
  }));
  // console.log(plan, "planid");
  const expiresOn = "companyName"?.startDate + "companyName"?.endAt * 86400;
  const rangeOptions = [5, 10, 15, 25, 35, "max"];
  const validityTypeOptions = [
    { label: props.t("Permanent"), value: true },
    { label: props.t("Temporary"), value: false },
  ];

  //handaling range function here
  const handleRangeChange = (event, newValue) => {
    let updatedRange = newValue;

    if (newValue === "max") {
      updatedRange = null; // Set range to null when "max" is selected
    } else if (newValue && !Number.isNaN(Number(newValue))) {
      updatedRange = parseInt(newValue); // Convert numeric range values to integers
    }

    setUserForm({
      ...userForm,
      range: updatedRange,
    });
  };

  //for fetching all tags for creation of post
  const [allTags, setAllTags] = useState([]);

  const fetchAllTags = async () => {
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.getAllTagsForPost,
      ""
    );
    if (response?.data?.responseCode === 109) {
      setAllTags(response.data.result);
    }
  };

  useEffect(() => {
    fetchAllTags();
  }, []);
  console.log(userForm, "userForm");
  return (
    <div
      className={classes.root}
      style={{
        marginTop: props.marginTop,
        fontFamily: "Lato",
        position: "relative",
        top: "-13px",
      }}
    >
      <Stack style={{ padding: "20px" }}>
        {/* buttons stack */}

        <Stack
          style={{ background: "#FFECD3", height: " 60px", zIndex: "1" }}
          sx={{
            flexDirection: { lg: "row", sm: "row", md: "row" },
            justifyContent: "space-between",
            rowGap: "10px",
          }}
        >
          <Stack style={{ flexDirection: "row", gap: "7px" }}>
            <Stack
              style={{
                paddingBottom: "3px",
              }}
            >
              <Typography
                className="personaldetails"
                style={{
                  fontWeight: "800",
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "20px",
                  underline: "none",
                  position: "relative",
                  top: "10px",
                  left: "27px",
                }}
              >
                Post Details
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        {/* details stack of users */}

        <Stack
          style={{
            padding: "20px",
            background: "white",
            borderRadius: "8px",
            marginTop: "20px",
            position: "relative",
            top: "-24px",
          }}
        >
          <>
            <Stack>
              <Grid container spacing={2}>
                {props.formDialog.divType === "edit" && (
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      id="referenceUrl"
                      InputProps={{
                        style: {
                          background: "#FFFFFF",
                          borderRadius: "10px",
                        },
                      }}
                      label={props.t("ID Number")}
                      autoComplete="something-unsupported"
                      value={userForm.tagNo}
                      fullWidth
                      style={{ width: "100%", borderRadius: "5px" }}
                      // onChange={(event) =>
                      //   setUserForm({
                      //     ...userForm,
                      //     referenceUrl: event.target.value,
                      //   })
                      // }
                      disabled={true}
                    />
                  </Grid>
                )}

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    label={props.t("Name")}
                    autoComplete="something-unsupported"
                    value={userForm?.name}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        name: event.target.value,
                      })
                    }
                    // disabled={
                    //   props.formDialog.divType === "new" ||
                    //   props.formDialog.divType === "edit"
                    //     ? false
                    //     : true
                    // }
                    disabled={true}
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    label={props.t("Mobile Number")}
                    autoComplete="something-unsupported"
                    value={userForm?.mobileNo}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        mobileNo: event.target.value,
                      })
                    }
                    disabled={true}
                    // disabled={
                    //   props.formDialog.divType === "new" ||
                    //   props.formDialog.divType === "edit"
                    //     ? false
                    //     : true
                    // }
                  />
                </Grid>

                {props.formDialog.divType === "edit" && (
                  <>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{
                        marginTop: "16px",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        id="referenceUrl"
                        InputProps={{
                          style: {
                            background: "#FFFFFF",
                            borderRadius: "10px",
                          },
                        }}
                        label={props.t("Date")}
                        autoComplete="something-unsupported"
                        value={DateConvert.convertTimestampToDate(
                          userForm?.createdAt
                        )}
                        fullWidth
                        style={{ width: "100%", borderRadius: "5px" }}
                        onChange={(event) =>
                          setUserForm({
                            ...userForm,
                            name: event.target.value,
                          })
                        }
                        disabled={true}
                      />
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{
                        marginTop: "16px",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        id="referenceUrl"
                        InputProps={{
                          style: {
                            background: "#FFFFFF",
                            borderRadius: "10px",
                          },
                        }}
                        label={props.t("Time")}
                        autoComplete="something-unsupported"
                        value={DateConvert.convertTimestampToTime(
                          userForm?.createdAt
                        )}
                        fullWidth
                        style={{ width: "100%", borderRadius: "5px" }}
                        onChange={(event) =>
                          setUserForm({
                            ...userForm,
                            name: event.target.value,
                          })
                        }
                        disabled={true}
                      />
                    </Grid>
                  </>
                )}

                {/* <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    label={props.t("Title")}
                    autoComplete="something-unsupported"
                    value={userForm.title}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        title: event.target.value,
                      })
                    }
                    disabled={
                      props.formDialog.divType === "new" ||
                      props.formDialog.divType === "edit"
                        ? false
                        : true
                    }
                  />
                </Grid> */}

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    label={props.t("Link")}
                    autoComplete="something-unsupported"
                    value={userForm.referenceUrl}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        referenceUrl: event.target.value,
                      })
                    }
                    disabled={
                      props.formDialog.divType === "new" ||
                      props.formDialog.divType === "edit"
                        ? false
                        : true
                    }
                  />
                </Grid>

                <Grid
                  item
                  xl={8}
                  lg={8}
                  md={8}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={places?.map((option, i) => option?.description)}
                    // sx={{  height:"54px"}}
                    value={userForm?.fragmentedAddress || ""}
                    // getOptionLabel={(option) => option.description}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handleChange}
                        required
                        label={"Select location"}
                        variant="outlined"
                        // value={addForm.locationName}
                      />
                    )}
                    onChange={(event, value) => {
                      setSelectedOption(value);
                      setUserForm({
                        ...userForm,
                        fragmentedAddress: value,
                      });
                    }}
                  />
                </Grid>
              </Grid>

              {/* here we are adding the subject */}

              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  marginTop: "16px",
                }}
              >
                <TextField
                  variant="outlined"
                  InputProps={{
                    style: {
                      background: "#FFFFFF",
                      borderRadius: "10px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <MicIcon
                          onClick={
                            !isRecording ? startRecording : stopRecording
                          }
                          style={{
                            cursor: "pointer",
                            color: !isRecording ? "black" : "red",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  label={props.t("Audio")}
                  autoComplete="something-unsupported"
                  value={
                    isRecording
                      ? "Recording..."
                      : "Click on mic to record audio"
                  }
                  fullWidth
                  style={{ width: "100%", borderRadius: "5px" }}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      referenceUrl: event.target.value,
                    })
                  }
                  disabled={true}
                />
                {/* Render audio playback after recording */}

                {userForm?.audio?.url && (
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      // justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <button
                      onClick={handlePlayPause}
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        fontSize: "24px",
                        marginRight: "10px",
                        color: isPlaying ? "#e74c3c" : "#2ecc71",
                      }}
                    >
                      {isPlaying ? <Pause20Filled /> : <Play20Filled />}
                    </button>

                    <div
                      ref={waveformRef}
                      style={{
                        width: "60%",
                        height: "50px",
                        // background: "#f5f5f5",
                        // marginRight: "10px",
                      }}
                    ></div>

                    <button
                      onClick={handleDelete}
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        fontSize: "24px",
                        color: "#FF8728",
                      }}
                    >
                      <Delete20Filled />
                    </button>
                  </div>
                )}
              </Grid>

              {/* fifth grid */}

              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },
                  marginTop: "16px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      multiline
                      rows={2}
                      id="description"
                      size="normal"
                      InputProps={{
                        style: {
                          background: "#FFFFFF",
                          borderRadius: "10px",
                        },
                      }}
                      label={props.t("Description")}
                      autoComplete="something-unsupported"
                      value={userForm?.description}
                      fullWidth
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          description: event.target.value,
                        })
                      }
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },
                  marginTop: "16px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <label style={{ fontWeight: 550, padding: "6px" }}>
                      Upload Images <span style={{ color: "red" }}>*</span>
                    </label>
                    <ImagesUpload
                      setUserForm={setUserForm}
                      userForm={userForm}
                      label="Upload Images"
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          </>
        </Stack>
      </Stack>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AddUser)
);
