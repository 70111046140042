import { Box, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { Info20Regular } from "@fluentui/react-icons";
import tooltipContent from "./CountTooltipContent";

// const headData = {
//   headingName: "No.of Users",
//   headingCount: 235,
// };
// const contentData = [
//   {
//     name: "Online Users",
//     count: 122,
//   },
//   {
//     name: "Customers",
//     count: 122,
//   },
//   {
//     name: "Admins",
//     count: 122,
//   },
// ];

function StatisticsCard({
  classes,
  background,
  Icon,
  label,
  totalNumber,
  newlyAddedCount,
  headData,
  contentData,
}) {
  return (
    <Box
      className={classes.statisticsCardContainer}
      style={{
        background: background,
      }}
    >
      <Box className={classes.topBar}>
        <Typography
          style={{
            textTransform: "uppercase",
            color: "#fff",
            fontFamily: "Segoe UI",
            fontSize: "14px",
          }}
        >
          {label}
        </Typography>
        <Tooltip arrow title={tooltipContent(headData, contentData)}>
          <Info20Regular
            style={{ color: "#FFFFFF", marginLeft: "8px", cursor: "pointer" }}
          />
        </Tooltip>
      </Box>
      <Typography
        style={{
          color: "#ffff",
          fontWeight: "700",
          fontFamily: "Segoe UI",
          fontSize: "26px",
        }}
      >
        {totalNumber}
      </Typography>
      <Typography
        style={{
          color: "#ffff",
          display: "block",
          marginTop: "8px",
          textTransform: "capitalize",
          fontFamily: "Segoe UI",
          fontSize: "12px",
        }}
      >
        {newlyAddedCount} New {label} Added
      </Typography>
    </Box>
  );
}

export default StatisticsCard;
