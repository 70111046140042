import {
  Cart24Filled,
  DocumentOnePage24Filled,
  PeopleTeam24Filled,
} from "@fluentui/react-icons";
import React from "react";

export const statsConstData = [
  {
    color: "linear-gradient(to right, #005CEA, #12B1DE)",
    label: "Users",
    icon: <PeopleTeam24Filled style={{ color: "#ffff" }} />,
  },
  {
    color: "linear-gradient(to right, #F84E6B, #F67086)",
    label: "Subscriptions",
    icon: <DocumentOnePage24Filled style={{ color: "#ffff" }} />,
  },
  {
    color: "linear-gradient(to right, #F66B2F, #EFA55F)",
    label: "Posts",
    icon: <Cart24Filled style={{ color: "#ffff" }} />,
  },
  {
    color: "linear-gradient(to right, #74C099, #BCC451)",
    label: " Looms",
    icon: <PeopleTeam24Filled style={{ color: "#ffff" }} />,
  },
  {
    color: "linear-gradient(to right, #4C4AA9, #9492F1)",
    label: "Members",
    icon: <DocumentOnePage24Filled style={{ color: "#ffff" }} />,
  },
  // {
  //   color: "linear-gradient(to right, #25A4BF, #63F0FC)",
  //   label: "Products",
  //   icon: <Cart24Filled style={{ color: "#ffff" }} />,
  // },
]; //constant
