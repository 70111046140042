import { Box, Typography } from "@mui/material";
import React from "react";
import DateConversion from "../../../../../utils/DateConversion";

function LogMessage({
  message,
  userName,
  time,
  isShowLast,
  isUsers,
  moduleName,
}) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          marginLeft: "30px",
          position: "relative",
          marginBottom: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: isShowLast ? "" : "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              backgroundColor: "#6C4595",
            }}
          ></Box>
          {!isShowLast && (
            <Box
              sx={{
                width: "1px",
                height: "100px",
                backgroundColor: "#86868686",
                // marginTop: "2px",
              }}
            ></Box>
          )}
        </Box>
        <Box
          sx={{
            width: "340px",
            minHeight: "80px",
            maxHeight: "160px",
            backgroundColor: "#F5E9FF",
            marginTop: "-3px",
            marginLeft: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            padding: "16px",
            borderTopRightRadius: "8px",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              color: "#000000",
              fontSize: "14px",
              fontWeight: "600",
              marginBottom: "2px",
            }}
          >
            {message}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              color: "#000000",
              fontSize: "14px",
              fontWeight: 350,
            }}
          >
            {`By ${userName}`} {isUsers && `| ${moduleName}`}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              color: "#606060",
              fontSize: "10px",
              fontWeight: "400",
              marginBottom: "2px",
            }}
          >
            {/* {time} */}
            {DateConversion.unixToLocaleDateTime(time)}
          </Typography>
        </Box>
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#F5E9FF",
            width: "18px",
            height: "18px",
            right: "349px",
            top: "1px",
            transform: "rotate(45deg)",

            // position: relative;
            // background-color: #F5E9FF;
            // width: 18px;
            // height: 18px;
            // right: 349px;
            // top: 11px;
            // -webkit-transform: rotate(57deg);
            // -moz-transform: rotate(57deg);
            // -ms-transform: rotate(57deg);
            // transform: rotate(45deg);
          }}
        ></Box>
      </Box>
    </>
  );
}

export default LogMessage;
