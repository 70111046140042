import Piechart from "./Piechart";
import BarIndication from "./BarIndication";
import { onFormatDate } from "../utils/util";
import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { DatePicker } from "@fluentui/react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CustomSelectDropdown from "./CustomSelectDropdown";
import "../../../../../../src/index.css";

const moduleRevenueOptions = {
  looms: "Looms",
  serviceOrder: "Services",
  subscription: "Subscription",
};

function RevenueSection({
  pieChartFiltersData,
  setPieChartFiltersData,
  services,
  classes,
}) {
  const getTotalAmount = () => {
    let total = 0;
    if (services?.pieChartData?.length > 0) {
      services.pieChartData.forEach((item) => {
        total += item.value;
      });
    }
    return total;
  };

  const handleDateChange = (date, type) => {
    setPieChartFiltersData((p) => ({
      ...p,
      [type]: new Date(date) / 1000,
    }));
  };

  const handleRevenueModuleSelection = (value) => {
    setPieChartFiltersData((p) => ({
      ...p,
      moduleType: value,
    }));
  };

  return (
    <Box
      sx={{
        height: "500px",
      }}
    >
      <Box className={classes.revenueHeader}>
        <Typography
          style={{
            fontWeight: "700",
            fontSize: "20px",
            fontFamily: "Segoe UI",
          }}
        >
          Total Revenue
        </Typography>
        <span className={classes.amountSpan}>₹ {getTotalAmount() || "0"}</span>
      </Box>
      <Box
        style={{
          width: "70%",
        }}
      >
        <CustomSelectDropdown
          options={moduleRevenueOptions}
          value={moduleRevenueOptions[pieChartFiltersData?.moduleType]}
          onChange={handleRevenueModuleSelection}
          placeholder="Select Module"
          classes={classes}
        />
      </Box>
      <Box
        className={`${classes.revenueTimeContainer} materialUiDatePicker`}
        style={{
          zIndex: 1,
        }}
      >
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          className="materialUiDatePicker"
        >
          <KeyboardDatePicker
            disableToolbar
            style={{
              width: "120px",
              height: "40px",
              marginRight: "8px",
            }}
            maxDate={new Date(pieChartFiltersData?.endDate * 1000)}
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            placeholder="Start Date"
            value={
              pieChartFiltersData?.startDate
                ? new Date(pieChartFiltersData?.startDate * 1000)
                : null
            }
            onChange={(date) => handleDateChange(date, "startDate")}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            style={{
              width: "120px ",
              height: "40px",
              zIndex: -1,
            }}
            minDate={new Date(pieChartFiltersData?.startDate * 1000)}
            maxDate={new Date()}
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            placeholder="End Date"
            value={
              pieChartFiltersData?.endDate
                ? new Date(pieChartFiltersData?.endDate * 1000)
                : null
            }
            onChange={(date) => handleDateChange(date, "endDate")}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </Box>
      <Box>
        {services?.pieChartData?.length > 0 ? (
          <Piechart
            width={300}
            height={400}
            style={{ bottom: "50px" }}
            pieChartData={services?.pieChartData || []}
          />
        ) : (
          <Typography
            style={{
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            No data is available
          </Typography>
        )}
      </Box>
      <Grid
        container
        spacing={2}
        style={{
          position: "relative",
          bottom: "80px",
        }}
      >
        {services?.pieChartData?.length > 0 &&
          services?.pieChartData?.[0]?.planName !== "No Data" &&
          services?.pieChartData?.map((data) => (
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <BarIndication
                backgroundColor={data?.fill}
                label={data?.name}
                amount={data?.value}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

export default RevenueSection;

{
  /* <DatePicker
          maxDate={new Date(pieChartFiltersData?.endDate * 1000)}
          formatDate={onFormatDate}
          style={{
            width: "150px",
            marginRight: "8px",
          }}
          className={` input__Style`}
          size="large"
          value={
            pieChartFiltersData?.startDate
              ? new Date(pieChartFiltersData?.startDate * 1000)
              : null
          }
          onSelectDate={(e) => {
            setPieChartFiltersData((p) => ({
              ...p,
              startDate: new Date(e).setHours(0, 0, 0, 0) / 1000,
            }));
          }}
          placeholder="Start"
        />
        <DatePicker
          minDate={new Date(pieChartFiltersData?.startDate * 1000)}
          maxDate={new Date()}
          formatDate={onFormatDate}
          style={{
            width: "150px",
          }}
          className={` input__Style`}
          size="large"
          value={
            pieChartFiltersData?.endDate
              ? new Date(pieChartFiltersData?.endDate * 1000)
              : null
          }
          onSelectDate={(e) => {
            setPieChartFiltersData((p) => ({
              ...p,
              endDate: new Date(e).setHours(23, 59, 0, 0) / 1000,
            }));
          }}
          placeholder="End"
        /> */
}
