import { useEffect, useState } from "react";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import SnackbarUtils from "../../../../utils/SnackbarUtils";

const list = {
  infotainmentDuration: null,
  requirementDuration: null,
  salesDuration: null,
  servicesAmount: null,
  serviceDuration: null,
  loomsAmount: null,
  loomsDuration: null,
};

const useHook = (props) => {
  const [userForm, setUserForm] = useState(list);

  const [loading, setLoading] = useState(true); // Loading state

  const listSettings = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.listSettings
      );
      if (response?.data?.responseCode === 109) {
        const listData = response?.data?.result;
        if (Array.isArray(listData) && listData.length > 0) {
          const data = listData[0]; // Get the first object in the array
          console.log("Settings List:", data);
          setUserForm({
            ...userForm,
            infotainmentDuration: data?.infotainmentDuration,
            requirementDuration: data?.requirementDuration,
            salesDuration: data?.salesDuration,
            servicesAmount: data?.servicesAmount,
            serviceDuration: data?.serviceDuration,
            loomsAmount: data?.loomsAmount,
            loomsDuration: data?.loomsDuration,
            settingId: data?._id,
          });
        }

        console.log(userForm);
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    } finally {
      setLoading(false); // Stop loading after fetch
    }
  };
  useEffect(() => {
    listSettings();
  }, []);

  const updateSettings = async (userForm) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.updateSettings,
        JSON.stringify(userForm)
      );
      if (response?.data?.responseCode === 109) {
        console.log("Settings List:", response);
        listSettings();
        SnackbarUtils.sucess(
          props.t("snackbar.updatedSuccess"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
        return;
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
      throw error; // Optionally re-throw the error if needed
    }
  };

  return {
    listSettings,
    userForm,
    setUserForm,
    updateSettings,
    loading,
  };
};
export default useHook;
