export const DateConvert = {
  //convert seconds to days,minu
  toDaysMinutesSeconds: function (totalSeconds) {
    const seconds = Math.floor(totalSeconds % 60);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const days = Math.floor(totalSeconds / (3600 * 24));

    const secondsStr = this.makeHumanReadable(seconds, "second");
    const minutesStr = this.makeHumanReadable(minutes, "minute");
    const hoursStr = this.makeHumanReadable(hours, "hour");
    const daysStr = this.makeHumanReadable(days, "days");

    return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.replace(
      /,\s*$/,
      ""
    );
  },

  convertTimestampToDate: (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert to milliseconds

    const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits for day
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month and ensure two digits
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  },
  convertTimestampToTime: (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert to milliseconds

    const hours = String(date.getHours()).padStart(2, "0"); // Ensure two digits for hours
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Ensure two digits for minutes
    const seconds = String(date.getSeconds()).padStart(2, "0"); // Ensure two digits for seconds

    return `${hours}:${minutes}:${seconds}`; // Return time in HH:MM:SS format
  },
  // convert to human readable like days ,hrs etc
  makeHumanReadable: (num, singular) => {
    return num > 0
      ? num + (num === 1 ? ` ${singular}, ` : ` ${singular}s, `)
      : "";
  },
  formattedDate: (time) => {
    const date = new Date(time * 1000);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const dateString = date.toLocaleDateString(undefined, options);
    return dateString;
  },
};
