import React, { useEffect, useRef, useState } from "react";
import { Translation, withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Box,
  styled,
  Checkbox,
  TextareaAutosize,
  Input,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../../utils/APIRequest.js";
import FormValidation from "../../../../../utils/FormValidation.js";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL.js";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../../utils/SnackbarUtils.js";
import { Stack } from "@mui/material";
import { Chip } from "@mui/material";
import landingImg from "../../../../../assets/images/Pic.png";
import DownloadIcon from "@mui/icons-material/Download";
// import ImageUpload from "./ImageUpload";
import Toaster from "../../../../../utils/Toaster.js";
import isEmpty from "../../../../../utils/isEmpty.js";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ImagesUpload from "./ImagesUpload.jsx";
import MicIcon from "@material-ui/icons/Mic";
import LocalStorage from "../../../../../config/LocalStorage.js";
import { CheckValidation } from "../../../../../utils/Validator.js";
import WaveSurfer from "wavesurfer.js";
import {
  Delete20Filled,
  Pause20Filled,
  Play20Filled,
} from "@fluentui/react-icons";
import { DateConvert } from "../../../../../utils/DataConvert.js";
import { Button } from "@fluentui/react";
import LogMessage from "../Members/LogMessages.jsx";
import ChatInput from "../Members/ChartInput.jsx";
import LogMessages from "../../../../../utils/LogMessages.js";
import SlideInDialog from "../../../../../templates/dialog/SlideInDialog.js";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  approveButton: {
    color: "white",
    backgroundColor: "red",
    boxShadow: "0 2px 4px red",
  },
  rejectButton: {
    color: "black",
    backgroundColor: "yellow",
    boxShadow: "0 2px 4px red",
  },
  approve: {
    color: "green",
    borderColor: "green",
    background: "#FFECD3",
    padding: "10px",
    borderRadius: "10px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#FFECD3",
      color: "green",
    },
  },
  reject: {
    color: "red",
    borderColor: "red",
    background: "#FFECD3",
    padding: "10px",
    borderRadius: "10px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#FFECD3",
      color: "red",
    },
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const postDetails = {
  description: "",
  fragmentedAddress: "",
  attachment: [],
  mobileNo: "",
  name: "",
  address: "",
  title: "",
  tagNo: "",
  loomType: "",
  loomRegistrationNo: "",
  loomNo: "",
  weaverInfo: "",
  status: "",
  location: {
    type: "Point",
    coordinates: ["", ""],
  },
};

function AddUser(props) {
  const [selectedOption, setSelectedOption] = useState("");

  const [location, setLocation] = useState("");
  const [places, setPlaces] = useState([]);
  const [placeId, setPlaceId] = useState([]);
  const [locationNames, setlocationNames] = useState("");
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [userForm, setUserForm] = useState(postDetails);
  const [categorydata, setCategoryData] = useState([]);
  const [sectorlist, setSectorlist] = useState([]);
  const [addplan, setAddPlan] = useState([]);
  const [selectedButton, setSelectedButton] = useState("");
  const [createdFor, setCreatedFor] = useState("");
  // const [handloom, setHandloom] = useState(postDetails.sector);
  const userDetails = LocalStorage.userDetails;
  const { listLogMessages, listLogs, createLogMessage } = LogMessages();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (selectedRowId) {
      listLogMessages("myLooms", selectedRowId);
    }
  }, [selectedRowId]);

  useEffect(() => {
    //changes made here
    // setUserForm(postDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (props?.formDialog?.functionName === "reset") {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    setUserForm(JSON.parse(JSON.stringify(postDetails)));
  };
  const categorylist = () => {
    APIRequest.request("GET", ConfigAPIURL.listcategory, "").then(
      (response) => {
        let userResult = response?.data?.result;
        setCategoryData(userResult);
        // console.log(categorydata, "categorydata");
      }
    );
  };

  useEffect(() => {
    categorylist();
  }, []);
  // console.log(categorydata, "data");

  //getting data for sector
  const sectorListData = () => {
    APIRequest.request("GET", ConfigAPIURL.sectorData, "").then((response) => {
      let userResult = response?.data?.result;
      setSectorlist(userResult);
      // console.log(sectorlist, "sectorData");
    });
  };

  useEffect(() => {
    sectorListData();
  }, []);

  // console.log(sectorlist, "sectorlist");

  const getEditable = () => {
    if (
      (props.rowDetails?._id || selectedRowId) &&
      // props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.loomDetails,
        JSON.stringify({ recordId: props.rowDetails?._id || selectedRowId })
      ).then((response) => {
        // console.log(response, "response");
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let postResult = response?.data?.result;
            console.log(postResult, "postResult");
            setUserForm({
              ...userForm,

              description: postResult?.description,
              fragmentedAddress: postResult?.fragmentedAddress,
              attachment: postResult?.images,
              tagNo: postResult?.tagNo,
              createdAt: postResult?.createdAt,
              name: postResult?.userId?.name,
              mobileNo: postResult?.userId?.mobileNo,
              audio: postResult?.audio,
              loomType: postResult?.loomType,
              loomRegistrationNo: postResult?.loomRegistrationNo,
              loomNo: postResult?.loomNo,
              weaverInfo: postResult?.weaverInfo,
              status: postResult?.status,
              // location: {
              //   type: postResult.location.type,
              //   coordinates: [
              //     postResult.location.coordinates[0],
              //     postResult.location.coordinates[1],
              //   ],
              // },
              // images: postResult?.images,
            });
            setCreatedFor(postResult?.userId?._id);
            setSelectedRowId(postResult._id);
          }
        }
      });
    }
  };

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        // handleChange();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    let url = ConfigAPIURL.postCreate;
    let method = "POST";

    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.loomUpdate;
      userForm["recordId"] = selectedRowId;
      method = "POST";
    }

    let formCopy = { ...userForm };
    // delete formCopy.attachment;
    delete formCopy.range;
    delete formCopy.fragmentedAddress;
    delete formCopy.location;
    delete formCopy.referenceUrl;
    delete formCopy?.startTime;
    delete formCopy?.media;
    delete formCopy?.subject;
    delete formCopy?.tag;
    delete formCopy?.mobileNo;
    delete formCopy?.name;
    delete formCopy?.address;
    delete formCopy?.title;
    delete formCopy?.audio;
    delete formCopy?.tagNo;
    delete formCopy?.sector;
    delete formCopy?.isPermanent;

    const missingFields = CheckValidation(formCopy);
    console.log(missingFields, "misssingFields");
    // if (missingFields.length > 0 || formCopy?.attachment?.length === 0) {
    //   SnackbarUtils.error(
    //     "Please fill all the mandatory fields",
    //     "bottomCenter",
    //     3000
    //   ).then((notification) => {
    //     props.publishNotification(notification);
    //   });
    //   return;
    // }
    if (selectedButton) {
      userForm.status = selectedButton;
    }

    let userFormCopy = { ...userForm };

    APIRequest.request(method, url, JSON.stringify(userFormCopy)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            SnackbarUtils.sucess(
              props.t("snackbar.updatedSuccess"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            props.handleFormDialog();
            props.tableQuery({
              keyword: "",
              page: 0,
              pageSize: 10,
              sortField: "",
              userType: "all",
              sortOrder: "false",
              postStatus: "all",
              active: true,
            });
            setUserForm(postDetails);
            setSelectedRowId("");
          }
          if (response.code === 100 && response.data.responseCode === 114) {
            SnackbarUtils.error(
              props.t("snackbar.dupliRecords"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      }
    );
  };
  //functions for getting location cordinates
  const handleChange = async (event) => {
    const placeName = event.target.value;

    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getLocation}?place=${placeName}`
    ).then((res) => {
      // console.log(res.data.result.predictions);
      setPlaces(res.data.result.predictions);
    });
    setLocation("");
  };

  const handleLocation = async () => {
    // console.log("this is evee optionsss", eve);
    // setlocationNames(selectedOption.description);

    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getLonLan}?place_id=${placeId}`
    ).then((res) => {
      setLocation(res.data?.result?.result?.geometry?.location);
      setUserForm((prev) => ({
        ...prev,
        location: {
          ...prev?.location,
          coordinates: [
            res.data?.result?.result?.geometry?.location?.lat,
            res.data?.result?.result?.geometry?.location?.lng,
          ],
        },
      }));
    });
  };
  useEffect(() => {
    handleLocation();
  }, [placeId]);

  useEffect(() => {
    if (selectedOption) {
      // setSelectedOption(selectedOption);
      setlocationNames(selectedOption.description);
      setPlaceId(selectedOption);
      places.find((option, i) => {
        if (option?.description === selectedOption) {
          setPlaceId(option?.place_id);
        }
      });
      // setAddForm({
      //   ...addForm,
      //   locationName: selectedOption.description,
      // });
      handleLocation();
    }
  }, [selectedOption, placeId]);

  const getPlans = () => {
    APIRequest.request("POST", ConfigAPIURL.plans).then((response) => {
      let userResult = response?.data?.rows;
      setAddPlan(userResult);
      // console.log(userResult);
    });
  };
  useEffect(() => {
    getPlans();
  }, []);
  // console.log("plan", addplan);
  // console.log(userForm, "userForm");

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: "black",
  }));
  // console.log(plan, "planid");
  const expiresOn = "companyName"?.startDate + "companyName"?.endAt * 86400;
  const rangeOptions = [5, 10, 15, 25, 35, "max"];
  const validityTypeOptions = [
    { label: props.t("Permanent"), value: true },
    { label: props.t("Temporary"), value: false },
  ];

  //handaling range function here
  const handleRangeChange = (event, newValue) => {
    let updatedRange = newValue;

    if (newValue === "max") {
      updatedRange = null; // Set range to null when "max" is selected
    } else if (newValue && !Number.isNaN(Number(newValue))) {
      updatedRange = parseInt(newValue); // Convert numeric range values to integers
    }

    setUserForm({
      ...userForm,
      range: updatedRange,
    });
  };

  //for fetching all tags for creation of post
  const [allTags, setAllTags] = useState([]);

  const fetchAllTags = async () => {
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.getAllTagsForPost,
      ""
    );
    if (response?.data?.responseCode === 109) {
      setAllTags(response.data.result);
    }
  };

  useEffect(() => {
    fetchAllTags();
  }, []);

  const handleRejectApprove = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.loomUpdate,
        JSON.stringify({ recordId: selectedRowId, status: selectedButton })
      );

      if (response?.data?.responseCode === 109) {
        console.log(props.rowDetails, props.formDialog, "row");
        getEditable();
        setIsOpen(false);
        SnackbarUtils.sucess(
          props.t(response?.data?.message),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      } else {
        console.log(response);
        SnackbarUtils.sucess(
          props.t(response?.data?.message),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } catch (error) {
      console.error("Error fetching location:", error.message || error);
    }
  };
  console.log(props.rowDetails, "row");
  return (
    <div
      className={classes.root}
      style={{
        marginTop: props.marginTop,
        fontFamily: "Lato",
        position: "relative",
        top: "-13px",
      }}
    >
      <Stack style={{ padding: "20px" }}>
        {/* buttons stack */}

        <Stack
          style={{ background: "#FFECD3", height: " 60px", zIndex: "1" }}
          sx={{
            flexDirection: { lg: "row", sm: "row", md: "row" },
            justifyContent: "space-between",
            rowGap: "10px",
          }}
        >
          <Stack style={{ flexDirection: "row", gap: "7px" }}>
            <Stack
              style={{
                paddingBottom: "3px",
              }}
            >
              <Typography
                className="personaldetails"
                style={{
                  fontWeight: "800",
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "20px",
                  underline: "none",
                  position: "relative",
                  top: "10px",
                  left: "27px",
                }}
              >
                Loom Details
              </Typography>
            </Stack>
          </Stack>
          {props.formDialog.divType === "view" && (
            <Stack
              style={{
                flexDirection: "row",
                gap: "7px",
                marginTop: "10px",
                marginRight: "30px",
              }}
            >
              <Button
                className={classes.reject}
                onClick={() => {
                  setSelectedButton("rejected");
                  setIsOpen(true);
                  // setUserForm((prev) => ({
                  //   ...prev,
                  //   status: "rejected",
                  // }));
                }}
                style={{
                  color: selectedButton === "rejected" ? "white" : "red",
                  backgroundColor: selectedButton === "rejected" ? "red" : null,
                  display: userForm?.status === "approved" && "none",
                }}
                disabled={userForm?.status !== "pending"}
              >
                Reject
              </Button>
              <Button
                className={classes.approve}
                onClick={() => {
                  setSelectedButton("approved");
                  setIsOpen(true);
                  // setUserForm((prev) => ({
                  //   ...prev,
                  //   status: "approved",
                  // }));
                }}
                style={{
                  color: selectedButton === "approved" ? "white" : "green",
                  backgroundColor:
                    selectedButton === "approved" ? "green" : null,
                  display: userForm?.status === "rejected" && "none",
                }}
                disabled={userForm?.status !== "pending"}
              >
                Approve
              </Button>
            </Stack>
          )}
        </Stack>

        {/* details stack of users */}

        <Stack
          style={{
            padding: "20px",
            background: "white",
            borderRadius: "8px",
            marginTop: "20px",
            position: "relative",
            top: "-24px",
          }}
        >
          <>
            <Stack>
              <Grid container spacing={2}>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    label={props.t("Loom Reg No")}
                    autoComplete="something-unsupported"
                    value={userForm.loomRegistrationNo}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    // onChange={(event) =>
                    //   setUserForm({
                    //     ...userForm,
                    //     referenceUrl: event.target.value,
                    //   })
                    // }
                    disabled={true}
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    label={props.t("UserName")}
                    autoComplete="something-unsupported"
                    value={userForm?.name}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        name: event.target.value,
                      })
                    }
                    // disabled={
                    //   props.formDialog.divType === "new" ||
                    //   props.formDialog.divType === "edit"
                    //     ? false
                    //     : true
                    // }
                    disabled={true}
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    label={props.t("Loom Number")}
                    autoComplete="something-unsupported"
                    value={userForm?.loomNo}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        mobileNo: event.target.value,
                      })
                    }
                    disabled={
                      props.formDialog.divType === "new" ||
                      props.formDialog.divType === "edit"
                        ? false
                        : true
                    }
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  {/* <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    label={props.t("Loom Type")}
                    autoComplete="something-unsupported"
                    value={userForm.loomType}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        title: event.target.value,
                      })
                    }
                    disabled={
                      props.formDialog.divType === "new" ||
                      props.formDialog.divType === "edit"
                        ? false
                        : true
                    }
                  /> */}
                  <Autocomplete
                    id="validityType"
                    options={[
                      { label: props.t("Handloom"), value: "handloom" },
                      { label: props.t("PowerLoom"), value: "powerloom" },
                      { label: props.t("JetLoom"), value: "jetloom" },
                    ]}
                    getOptionLabel={(option) => option.label}
                    value={
                      userForm.loomType
                        ? {
                            label: props.t(
                              userForm.loomType.charAt(0).toUpperCase() +
                                userForm.loomType.slice(1)
                            ),
                            value: userForm.loomType,
                          }
                        : null
                    }
                    onChange={(event, newValue) => {
                      setUserForm((prev) => ({
                        ...prev,
                        loomType: newValue.value,
                      }));
                    }}
                    disabled={
                      props.formDialog.divType === "new" ||
                      props.formDialog.divType === "edit"
                        ? false
                        : true
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        required
                        label={props.t("Loom Type")}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            // background: "#FFFFFF",
                            borderRadius: "10px",
                            width: "100%",
                          },
                        }}
                        // autoComplete="something-unsupported"
                        fullWidth
                        style={{
                          width: "100%",
                          borderRadius: "5px",
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    label={props.t("Weaver Name/ ID")}
                    autoComplete="something-unsupported"
                    value={userForm.weaverInfo}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        referenceUrl: event.target.value,
                      })
                    }
                    disabled={
                      props.formDialog.divType === "new" ||
                      props.formDialog.divType === "edit"
                        ? false
                        : true
                    }
                  />
                </Grid>
              </Grid>

              {/* here we are adding the subject */}

              {/* fifth grid */}

              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },
                  marginTop: "16px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      multiline
                      rows={2}
                      id="description"
                      size="normal"
                      InputProps={{
                        style: {
                          background: "#FFFFFF",
                          borderRadius: "10px",
                        },
                      }}
                      label={props.t("Description")}
                      autoComplete="something-unsupported"
                      value={userForm?.description}
                      fullWidth
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          description: event.target.value,
                        })
                      }
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },
                  marginTop: "16px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <label style={{ fontWeight: 550, padding: "6px" }}>
                      Upload Images
                    </label>
                    <ImagesUpload
                      setUserForm={setUserForm}
                      userForm={userForm}
                      label="Upload Images"
                      disabled={props.formDialog.divType === "view"}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
            <br />
            <hr />
            <br />
            {listLogs?.length > 0
              ? listLogs?.map((val, ind) => (
                  <LogMessage
                    message={val?.sendMessage}
                    userName={val?.createdBy?.name}
                    time={val?.createdAt}
                    isShowLast={listLogs?.length - 1 === ind}
                  />
                ))
              : "No logs present"}

            <ChatInput
              createLogMessage={createLogMessage}
              recordId={selectedRowId}
              listLogMessages={listLogMessages}
              moduleType="myLooms"
              createdFor={createdFor}
            />
          </>
        </Stack>
      </Stack>
      <SlideInDialog
        // title={props.t("Delete Post")}
        contentComponent={props.t(
          `Are you sure you want to ${
            selectedButton === "rejected" ? "Reject" : "Approve"
          } ?`
        )}
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus="ok"
        slideInDialog={isOpen}
        setSlideInDialog={setIsOpen}
        handleDeleteRequest={handleRejectApprove}
        enquires={true}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AddUser)
);
