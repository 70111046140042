import React from "react";
import ChatProfile from "./chatProfile";
import { DataContext } from "../index";
import SendMessage from "./SendMessage";
import { Stack, Typography } from "@mui/material";
import MainChat from "./MainChatArea";
import useChat from "../hooks/useChat";

const Index = () => {
  const {
    // selectedChat,
    // sendMessage,
    // conversation,
    // fetchMessages,
    // hasMoreConverstion,
    rowDetails,
    userData,
    handleFormDialog,
    props,
  } = React.useContext(DataContext);

  const {
    // handleConnect,
    sideChatList,
    sendMessage,
    fetchMessages,
    conversation,
    calculateMinutes,
    selectedChat,
    hasMoreConverstion,
    handleSearchList,
    setSelectedChat,
  } = useChat({ userData, rowDetails, handleFormDialog, props });

  return (
    <>
      {rowDetails ? (
        <>
          <Stack
            justifyContent="space-between"
            className="background_image"
            style={{ height: "100%" }}
          >
            <MainChat
              conversation={conversation}
              selectedChat={rowDetails}
              fetchMessages={fetchMessages}
              hasMoreConverstion={hasMoreConverstion}
            />
            <Stack
              style={{
                padding: "1rem 2rem",
                position: "relative",
              }}
            >
              <SendMessage
                sendMessage={sendMessage}
                selectedChat={rowDetails}
                userData={userData}
              />
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack
          justifyContent="space-between"
          className="background_image"
          style={{
            height: "100%",
            objectFit: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "420px",
            opacity: "0.8",

            // backgroundImage: `url(${allImages.chatBg})`,
          }}
        >
          <Stack justifyContent="end" height="100%" mb={4}>
            <Typography
              textAlign="center"
              variant="subtitle1"
              style={{ fontSize: "20px", fontWeight: 600 }}
            >
              Break the silence ignite the conversation
            </Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default Index;
