import { Grid, useTheme } from "@mui/material";
import React from "react";
import { onFormatDate } from "../utils/util";
import { Typography } from "@material-ui/core";
import { DatePicker, Dropdown } from "@fluentui/react";
import { Option } from "antd/es/mentions";
import CustomSelectDropdown from "./CustomSelectDropdown";
import { useStyles } from "../styles/style";
import "../../../../../../src/index.css";
/* eslint-disable no-use-before-define */
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
const moduleOptionsList = {
  users: "Users",
  looms: "Looms",
  posts: "Posts",
  servicesOrder: "Members",
};
const timeData = {
  day: "Day",
  week: "Week",
  month: "Month",
  year: "Year",
};

function GraphHeader({ services, setGraphFiltersData, graphFiltersData }) {
  const theme = useTheme();
  const classes = useStyles();

  const handleModuleSelection = (value) => {
    setGraphFiltersData((p) => ({
      ...p,
      module: value,
    }));
  };

  const handleTimeSelection = (value) => {
    console.log("Selected date:", value);
    setGraphFiltersData((p) => ({
      ...p,
      dateType: value,
    }));
  };

  const handleDateChange = (date, type) => {
    console.log(date, "date");
    setGraphFiltersData((p) => ({
      ...p,
      [type]: new Date(date) / 1000,
    }));
  };

  const defaultProps = {
    options: services.statesList || [],
    getOptionLabel: (option) => option.stateName,
  };

  return (
    <Grid container>
      <Grid item xs={3}>
        <Typography
          style={{
            color: theme?.palette?.primary?.main,
            fontWeight: "600",
            fontFamily: "Segoe UI",
            marginBottom: "14px",
          }}
        >
          Graphical Representation
        </Typography>

        <CustomSelectDropdown
          options={moduleOptionsList}
          value={moduleOptionsList[graphFiltersData?.module]}
          onChange={handleModuleSelection}
          placeholder="Select Module"
          classes={classes}
        />
      </Grid>
      <Grid item xs={2}>
        <Autocomplete
          {...defaultProps}
          selectOnFocus
          style={{
            // width: "120px",
            height: "40px",
            marginRight: "8px",
          }}
          onChange={(event, newValue) => {
            setGraphFiltersData((p) => ({ ...p, state: newValue?.stateName }));
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                placeholder="Search State"
                margin="normal"
              />
            );
          }}
        />
      </Grid>

      <Grid item xs={5} id="materialUiDatePicker">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            style={{
              width: "120px",
              height: "40px",
              marginRight: "8px",
            }}
            maxDate={new Date(graphFiltersData?.endDate * 1000)}
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            placeholder="Start Date"
            value={
              graphFiltersData?.startDate
                ? new Date(graphFiltersData?.startDate * 1000)
                : null
            }
            onChange={(date) => handleDateChange(date, "startDate")}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            style={{
              width: "120px ",
              height: "40px",
            }}
            maxDate={new Date(graphFiltersData?.endDate * 1000)}
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            placeholder="End Date"
            value={
              graphFiltersData?.endDate
                ? new Date(graphFiltersData?.endDate * 1000)
                : null
            }
            onChange={(date) => handleDateChange(date, "endDate")}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid
        item
        xs={2}
        style={{
          marginTop: "16px",
        }}
      >
        <CustomSelectDropdown
          options={timeData}
          value={timeData[graphFiltersData?.dateType]}
          onChange={handleTimeSelection}
          placeholder="Select Date Type"
          classes={classes}
        />
      </Grid>
    </Grid>
  );
}

export default GraphHeader;

{
  /* <Dropdown
          size="large"
          placeholder="Time"
          style={{
            background: "#FFF",
            border: "none",
            paddingLeft: "0px",
            display: "inline",
          }}
          className="time-select"
          value={timeData[graphFiltersData?.dateType]}
          onOptionSelect={(e, data) => {
            setGraphFiltersData((p) => ({
              ...p,
              dateType: data?.optionValue,
            }));
          }}
        >
          {Object.keys(timeData)?.map((data) => (
            <Option key={data} value={data}>
              {timeData[data]}
            </Option>
          ))}
        </Dropdown> */
}
