import React from "react";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";

const useImageUpload = (setUserForm, userForm, handleClosePreview) => {
  const moveImagesToUserForm = (image) => {
    const existingImages = [...userForm.media];
    existingImages.push(image);
    setUserForm({
      ...userForm,
      images: existingImages,
    });
  };

  const onUpload = async (e) => {
    const formdata = new FormData();
    const file = e.target.files[0];
    formdata.append("attachment", file);
    formdata.append("bucketName", "profile");

    const response = await APIRequest.multipartForDataRequest(
      "PUT",
      ConfigAPIURL.uploadFile,
      formdata
    );

    if (response.data.responseCode === 109) {
      const image = {
        type: file?.type.split("/")[1],
        name: file.name,
        url: response?.data?.attachmentUrl[0],
      };

      const updatedMedia = [...userForm.images, image];

      setUserForm({
        ...userForm,
        images: updatedMedia,
      });
    }
  };

  const deleteImages = (ind) => {
    const data = [...userForm.images];
    data.splice(ind, 1);
    setUserForm({
      ...userForm,
      images: data,
    });
    handleClosePreview();
  };

  return { onUpload, deleteImages };
};

export default useImageUpload;
