import { Box, Typography } from "@material-ui/core";
import React from "react";

const tooltipContent = (headData, contentData) => {
  return (
    <Box
      style={{
        backgroundColor: "transparent",
        // height: "110px",
        // width: "160px",
        borderRadius: "10px",
      }}
    >
      <Typography
        style={{
          fontWeight: "600",
          marginBottom: "12px",
          borderBottom: "1px solid #F1F1F1",
          color: "#F1F1F1",
          paddingBottom: "8px",
        }}
      >
        {headData?.headingName} : {headData?.headingCount}
      </Typography>
      {contentData?.map((data) => (
        <Typography
          style={{
            fontSize: "12px",
            fontWeight: "400",
            marginBottom: "4px",
            color: "#F1F1F1",
            display: "block",
          }}
        >
          {data?.name} : {data?.count}
        </Typography>
      ))}
    </Box>
  );
};

export default tooltipContent;
