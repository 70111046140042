import { useState } from "react";
import APIRequest from "./APIRequest";
import ConfigAPIURL from "../config/ConfigAPIURL";

const LogMessages = () => {
  const [loading, setLoading] = useState(true); // Loading state

  const [listLogs, setListLogs] = useState([]);

  const listLogMessages = async (moduleType, recordId) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.listLogs,
        JSON.stringify({ moduleType, recordId })
      );
      if (response?.data?.responseCode === 109) {
        const listData = response?.data?.result;
        setListLogs(listData);
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    } finally {
      setLoading(false); // Stop loading after fetch
    }
  };

  const createLogMessage = async (
    moduleType,
    recordId,
    sendMessage,
    createdFor
  ) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.createLog,
        JSON.stringify({ moduleType, recordId, sendMessage, createdFor })
      );
      if (response?.data?.responseCode === 109) {
        console.log(response);
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  return {
    listLogs,
    listLogMessages,
    createLogMessage,
  };
};
export default LogMessages;
