import React from "react";
import Typography from "@material-ui/core/Typography";

import Link from "@material-ui/core/Link";

/* eslint-disable array-callback-return */
const specialCharacters = [
  "CCY",
  "ccy",
  "Identifier",
  "Id",
  "Fx",
  "MIS",
  "MT103",
];
const specialCharactersWithSpace = ["C C Y", "F X", "M I S", "M T103"];
const specialCharactersReplace = {
  currencyCode: "Currency",
  amountValue: "Amount",
  ccycode: "CCY Code",
};
const StringUtils = {
  JSONKeyToUI: function (value) {
    let tempReplace = "";
    Object.keys(specialCharactersReplace).map((specialCharactersKey) => {
      if (specialCharactersKey === value) {
        return (value = specialCharactersReplace[specialCharactersKey]);
      }
    });
    if (tempReplace === "") {
      value = this.firstCharacterUpperCase(value);
      value = value.replace(/([A-Z])/g, " $1").trim();
      let identifierFlag = false;
      specialCharacters.map((char) => {
        let pos = value.search(char);
        if (char !== "Id" || (char === "Id" && identifierFlag === false)) {
          if (pos > -1) {
            if (char === "Identifier") {
              identifierFlag = true;
              let regex = new RegExp(char, "gi");
              value = value
                .replace(regex, " " + this.firstCharacterUpperCase(char) + " ")
                .trim();
            } else {
              let regex = new RegExp(char, "gi");
              value = value
                .replace(regex, " " + char.toUpperCase() + " ")
                .trim();
            }
          }
        }
        if (
          value.startsWith("Swift") ||
          value.endsWith("Cr Raw") ||
          value.endsWith("Raw Cr") ||
          value.endsWith("Cr") ||
          value.endsWith("List") ||
          value.endsWith("List Of")
        ) {
          value = value.replace(/Swift|Cr|Raw|List|Of/g, "").trim();
        }
      });
      specialCharactersWithSpace.map((char) => {
        let pos = value.search(char);
        if (pos > -1) {
          let regex = new RegExp(char, "gi");
          value = value.replace(regex, char.replace(/\s+/g, "")).trim();
        }
      });
    }

    return value;
  },
  allUpperCaseWithSpace: function (value) {
    let result = value
      ?.replace(/([A-Z])/g, " $1")
      ?.trim()
      ?.toUpperCase();
    return result;
  },
  capitalize: function (value) {
    if (!value) return;
    return (
      value
        ?.split(" ") // Split the name into words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
        .join(" ") || ""
    ); // Join words back into a string
  },

  firstCharacterUpperCase: function (value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  },
  caseStatus: function (value) {
    if (value === "new") {
      return "New";
    } else if (value === "underReview") {
      return "Assigned";
    } else if (value === "processed") {
      return "Processed";
    }
  },
  paymentMessageSplitWithDefaultLength: function (fieldName, value) {
    let tempFieldNumber = Number(fieldName.match(/\d+/));
    if (
      (tempFieldNumber >= 50 && tempFieldNumber <= 70) ||
      tempFieldNumber === 72
    ) {
      return value
        .match(/.{1,35}/g)
        .toString()
        .replace(/,/g, "\n");
    }
    return value;
  },
  modifyUserType: function (value) {
    if (value === "admin") {
      return "Admin User";
    } else if (value === "shop") {
      return "Shop";
    } else if (value === "member") {
      return "Member";
    } else {
      return "-";
    }
  },
  // url: function (value) {
  //   return <Link href={'#/admin/processCase?caseId=' + value}>{value}</Link>;
  // },
  doctor: function (value) {
    return "Dr. " + value;
  },
  objectIntake: function (value) {
    return (
      <div>
        {value.Morning !== "" ? (
          <Typography variant="p" component="p">
            Morning: {value.Morning} Meal
          </Typography>
        ) : (
          <></>
        )}
        {value.Afternoon !== "" ? (
          <Typography variant="p" component="p">
            Afternoon: {value.Afternoon} Meal
          </Typography>
        ) : (
          <></>
        )}

        {value.Evening !== "" ? (
          <Typography variant="p" component="p">
            Evening: {value.Evening} Meal
          </Typography>
        ) : (
          <></>
        )}
        {value.Night !== "" ? (
          <Typography variant="p" component="p">
            Night: {value.Night} Meal
          </Typography>
        ) : (
          <></>
        )}
      </div>
    );
  },
  objectIntakeQuantity: function (value) {
    return (
      <div>
        {value.Morning !== "" ? (
          <Typography variant="p" component="p">
            {value.Morning}
          </Typography>
        ) : (
          <></>
        )}
        {value.Afternoon !== "" ? (
          <Typography variant="p" component="p">
            {value.Afternoon}
          </Typography>
        ) : (
          <></>
        )}

        {value.Evening !== "" ? (
          <Typography variant="p" component="p">
            {value.Evening}
          </Typography>
        ) : (
          <></>
        )}
        {value.Night !== "" ? (
          <Typography variant="p" component="p">
            {value.Night}
          </Typography>
        ) : (
          <></>
        )}
      </div>
    );
  },
  arrayToString: function (value) {
    const filteredResults = value.filter((item) => {
      return item !== "";
    });
    return filteredResults.join(", ");
  },
  ConvertImage: function (path) {
    // return <img style={{width: "60px", height: "60px"}} src={path}/>

    return (
      <img
        src={path}
        style={{ width: "60px", height: "60px" }}
        onClick={(e) => window.open(e.target.src, "_blank")}
      />
    );
  },
  convertToLinkBtn: function (value) {
    if (value === "approve") {
      return (
        <Typography style={{ padding: "0px 4px", color: "#00308F" }}>
          {value}
        </Typography>
      );
    }
    if (value === "blocked") {
      return (
        <Typography style={{ padding: "0px 4px", color: "#0B0B0B" }}>
          {value}
        </Typography>
      );
    }
    if (value === "rejected") {
      return (
        <Typography style={{ padding: "0px 4px", color: "#FF0000" }}>
          {value}
        </Typography>
      );
    }
    if (value === "pending") {
      return (
        <Typography style={{ padding: "0px 4px", color: "#FF8D00" }}>
          {value}
        </Typography>
      );
    }
    if (value === "reported") {
      return (
        <Typography style={{ padding: "0px 4px", color: "#DADADA" }}>
          {value}
        </Typography>
      );
    }
  },
  invoice: function (value) {
    if (value === "recharge" || value === "adminRecharge") {
      return (
        <div>
          <button
            style={{
              background: "#F3E1FE",
              width: "65px",
              height: "24px",
              padding: "6px 10px",
              color: "#9900F0",
              fontFamily: "14px",
              fontWeight: "600",
              fontFamily: "Open Sans",
              border: "none",
            }}
          >
            Invoice
          </button>
        </div>
      );
    } else {
      return (value = "");
    }
  },
};
export default StringUtils;
