import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Mylooms from "./Mylooms/MyloomsIndex";

import {
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  Paper,
  styled,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";

import { Eye24Filled, EyeOff24Filled } from "@fluentui/react-icons";

import { TabContext, TabList } from "@material-ui/lab";
import { Stack, Tab } from "@mui/material";
import { connect } from "react-redux";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import LogMessages from "../../../../utils/LogMessages";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import ChatInput from "../Enquiries/Members/ChartInput";
import LogMessage from "../Enquiries/Members/LogMessages";
import Plans from "./components/Plans";
import ImagesUpload from "./ImagesUpload";
import ReportedBy from "./ReportedBy";
import Reviews from "./Reviews";
import "./styles/user.scss";

const tabsMenu = [
  "Personal Details",
  "Subscription Plans",
  "Reviews",
  "My Looms",
  "Comments",
];

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));
const userFormDetails = {
  userType: "",
  active: true,
  isSuperAdmin: false,
  sector: null,
  occupationType: "",
  userTag: "",
  businessName: "",
  workingHours: "",
  experience: "",
  fragmentedAddress: "",
  workReference: "",
  description: "",
  planType: "",
  activeplan: "",
  expireAt: "",
  subscribedPrice: "",
  planId: "",
  note: "",
  permission: "",
  name: "",
  mobileNo: "",
  email: "",
  address: "",
  address2: "",
  bloodPressure: { high: "", low: "" },
  city: "",
  state: "",
  country: "",
  street: "",
  pin: "",
  age: "",
  dob: null,
  gender: null,
  adminUserName: "",
  images: [],
  profileImage: "",
  // subscriptionId: {
  //   planType:"",
  //   planName:"",
  //   subscribedPrice:"",
  //   expireAt:"",
  //   active:"",
  // },
  // subscriptionId: "",
  password: "",
  passwordAttempt: "",
  commentByAdmin: "",
  referralCode: "",
  // qualification: "",
  loom: [],
  // user_id:"",
};

function AEVForm(props) {
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [userForm, setUserForm] = useState(userFormDetails);

  const [sectorlist, setSectorlist] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [categorydata, setCategoryData] = useState([]);
  const [addplan, setAddplan] = useState(false);
  const [planlist, setPlanList] = useState([]);
  const [viewplan, setViewPlan] = useState([]);
  const [plandata, setPlanData] = useState([]); // plan history state
  const [planhistory, setPlanHistory] = useState([]); // plan history data
  const [activecount, setActiveCount] = useState([]); // plan history data

  const [activeTab, setActiveTab] = useState(0);
  // const [handloom, setHandloom] = useState(userFormDetails.sector);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { listLogMessages, listLogs, createLogMessage } = LogMessages();

  useEffect(() => {
    if (selectedRowId) {
      listLogMessages("users", selectedRowId);
    }
  }, [selectedRowId]);

  useEffect(() => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setUserForm({ ...userFormDetails, userType: "user" });
    }
  }, [props.page]);

  useEffect(() => {
    // setUserForm(userFormDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      getEditable();
      setUserForm({ ...userFormDetails, userType: "user" });
    } else {
      setUserForm(userFormDetails);
    }
  };

  const getEditable = () => {
    const { rowDetails, formDialog } = props;

    if (rowDetails && formDialog.divType !== "new") {
      APIRequest.request(
        "GET",
        ConfigAPIURL.userDetails + `?recordId=${rowDetails?._id}`
      ).then((response) => {
        if (
          response &&
          response.code === 100 &&
          response.data.responseCode === 109
        ) {
          const userResult = response.data.result;
          setCompanyName(userResult);

          setUserForm({
            ...userForm,
            userType: userResult.userType,
            active: userResult.active,
            isSuperAdmin: userResult.isSuperAdmin,
            permission: userResult.permission,
            userTag: userResult.userTag,
            sector: userResult.sector,
            occupationType: userResult.occupationType,
            businessName: userResult.businessName,
            planType: userResult.subscriptionId?.planType,
            planName: userResult.subscriptionId?.planName,
            subscribedPrice: userResult.subscriptionId?.subscribedPrice,
            activeplan: userResult.subscriptionId?.active,
            expireAt: userResult.subscriptionId?.expireAt,
            // subscriptionId: userResult.subscriptionId?._id,
            // subscriptionPlanId: userResult.subscriptionId?.planId,
            name: userResult.name,
            mobileNo: userResult.mobileNo,
            email: userResult.email,
            address: userResult.address,
            // qualification: userResult.qualification,
            fragmentedAddress: userResult.fragmentedAddress,
            workReference: userResult.workReference,
            workingHours: userResult.workingHours,
            description: userResult.description,
            city: userResult.city,
            state: userResult.state,
            country: userResult.country,
            pin: userResult.pin,
            street: userResult.street,
            dob: userResult.dob,
            gender: userResult.gender,
            images: userResult.images,
            experience: userResult.experience,
            password: userResult.password,
            passwordAttempt: userResult.passwordAttempt,
            status: userResult.status,
            referralCode: userResult.referralCode,
            adminUserName: userResult.adminUserName,
            profileImage: userResult.profileImage,
            loom: userResult.loom,
          });

          setSelectedRowId(userResult._id);
        }
      });
    }
  };

  const sectorListData = () => {
    APIRequest.request("GET", ConfigAPIURL.sectorData, "").then((response) => {
      let userResult = response?.data?.result;
      setSectorlist(userResult);
      //console.log(sectorlist, "sectorData");
    });
  };

  useEffect(() => {
    sectorListData();
  }, []);

  const categorylist = () => {
    APIRequest.request("GET", ConfigAPIURL.listcategory, "").then(
      (response) => {
        let userResult = response?.data?.result;
        setCategoryData(userResult);
        // //console.log(response, "response");
      }
    );
  };

  useEffect(() => {
    categorylist();
  }, []);

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    let url = ConfigAPIURL.userCreate;
    let method = "POST";

    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.userUpdate;
      userForm["userId"] = selectedRowId;
      method = "PUT";
    }
    let fieldValidation =
      // ["name", "userType", "mobileNo"];
      userForm.userType === "admin"
        ? ["name", "userType", "mobileNo", "gender", "permission"]
        : ["name", "userType", "mobileNo", "sector", "occupationType"];
    FormValidation.validation(fieldValidation, userForm).then(
      (ValidationResult) => {
        if (ValidationResult) {
          if (companyName?.mobile === userForm?.mobileNo) {
            SnackbarUtils.error(props.t("forms.mobilenoValidation"), 3000).then(
              (notification) => {
                props.publishNotification(notification);
              }
            );
            return;
          }
          // userForm.permission = userForm.permission._id;
          APIRequest.request(method, url, JSON.stringify(userForm)).then(
            (response) => {
              if (response !== undefined && response !== null) {
                if (
                  response.code === 100 &&
                  response.data.responseCode === 109
                ) {
                  SnackbarUtils.sucess(
                    props.t("snackbar.updatedSuccess"),
                    //console.log(props,"printing from userAevForm"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                  props.handleFormDialog();
                  // props.tableQuery({
                  //   keyword: '',
                  //   page: 0,
                  //   pageSize: 10,
                  //   sortField: '',
                  //   userType: 'all',
                  //   sortOrder: 'false',
                  //   active: true,
                  // });
                  setUserForm(userFormDetails);
                  setSelectedRowId("");
                }
                if (
                  response.code === 100 &&
                  response.data.responseCode === 114
                ) {
                  SnackbarUtils.error(
                    props.t("snackbar.dupliRecords"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                }
              }
            }
          );
        }
      }
    );
  };

  // plan history of users api call
  const Planhistory = () => {
    APIRequest.request(
      "GET",
      ConfigAPIURL.userhistoryplans + "?userId=" + `${props?.rowDetails?._id}`,
      ""
    ).then((response) => {
      let userResult = response?.data;
      // setPlanData(userResult);
      // setPlanHistory(userResult?.planHistory)
      // setActiveCount(userResult?.planHistory?.active)
      //console.log(response, "response");
    });
  };
  useEffect(() => {
    Planhistory();
  }, []);

  const getPlans = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.plans,
      JSON.stringify({
        // recordId: "6321a9060d00da3c7010555a",
        userId: props?.rowDetails?._id,
      })
    ).then((response) => {
      let userResult = response?.data?.rows;
      setPlanList(userResult);
      //console.log(userResult);
    });
  };
  useEffect(() => {
    getPlans();
  }, []);
  useEffect(() => {
    const addedplandata = planlist?.find((option) => {
      if (option?._id === userForm?.planId) {
        return setViewPlan(option);
      }
    });
  }, [userForm?.planId, planlist?._id]);

  const resetPassAttempts = (recordId) => {
    APIRequest.request(
      "PUT",
      ConfigAPIURL.userUpdate,
      JSON.stringify({ userId: recordId, passwordAttempt: 0 })
    ).then((response) => {
      if (response?.data?.responseCode === 109) {
        SnackbarUtils.sucess(
          props.t("Password resetAttempts successfully"),
          //console.log(props,"printing from userAevForm"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      } else {
        SnackbarUtils.error(
          props.t("Error password resetAttempts successfully"),
          //console.log(props,"printing from userAevForm"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    });
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: "black",
  }));

  return (
    <div
      className={classes.root}
      style={{ marginTop: props.marginTop, fontFamily: "Lato" }}
    >
      {userForm?.userType !== "admin" ? (
        <Stack style={{ padding: "20px" }}>
          <TabContext value={activeTab} indicatorColor="#FF8D00">
            <TabList
              onChange={(e, value) => setActiveTab(value)}
              aria-label="lab API tabs example"
            >
              {tabsMenu?.map((tab, ind) => (
                <Tab
                  label={tab}
                  value={ind}
                  sx={{
                    textTransform: "capitalize", // Capitalize text
                    fontWeight: activeTab === ind ? 600 : 400, // Make text bold
                    fontSize: "1rem",
                    fontFamily: "Lato",
                  }}
                />
              ))}
            </TabList>
          </TabContext>

          {/* details stack of users */}
          <Stack
            style={{
              padding: "20px",
              background: "white",
              borderRadius: "8px",
              marginTop: "20px",
            }}
          >
            {/* activeTab 0 for personal details */}
            {activeTab === 0 && (
              <>
                <Stack style={{}}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{ padding: "12px", marginTop: { sm: "17px" } }}
                    >
                      <TextField
                        id="userType"
                        fullWidth
                        size="small"
                        // disableUnderline={false}
                        label={props.t("user.userType")}
                        variant="outlined"
                        className="textborder"
                        autoCapitalize="words"
                        select
                        style={{
                          // boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.15)",
                          autoCapitalize: "words",
                          height: "10px",
                        }}
                        // className="textborder"

                        required
                        value={userForm?.userType}
                        onChange={(event) =>
                          setUserForm({
                            ...userForm,
                            userType: event.target.value,
                          })
                        }
                      >
                        {/* <MenuItem key={0} value={"admin"}>
                          {"Admin"}
                        </MenuItem> */}

                        {/*<MenuItem key={1} value={"shop"}>
                           {"Shop"}
                         </MenuItem>*/}

                        <MenuItem key={2} value={"member"}>
                          {"Member"}
                        </MenuItem>
                      </TextField>
                    </Grid>

                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      className="griddata"
                      style={{ padding: "12px", marginTop: { sm: "20px" } }}
                    >
                      <TextField
                        variant="outlined"
                        id="active"
                        size="small"
                        className="textborder"
                        fullWidth
                        label={props.t("user.active")}
                        //  className={classes.userTypeMenuItem}
                        select
                        style={{
                          marginTop: { lg: "6px", sm: "17px" },
                        }}
                        // required
                        // className="textborder"
                        disabled={
                          props.formDialog.divType === "new" ||
                          props.formDialog.divType === "edit"
                            ? false
                            : true
                        }
                        defaultValue={userForm.active}
                        value={userForm.active}
                        onChange={(event) =>
                          setUserForm({
                            ...userForm,
                            active: event.target.value,
                          })
                        }
                      >
                        <MenuItem key={0} value={true}>
                          {props.t("user.active")}
                        </MenuItem>
                        <MenuItem key={1} value={false}>
                          {props.t("user.inactive")}
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{ padding: "12px", marginTop: "-5px" }}
                    >
                      <TextField
                        variant="outlined"
                        id="sector"
                        size="small"
                        className="textborder"
                        required
                        label={"Sector"}
                        autoComplete="something-unsupported"
                        fullWidth
                        select
                        value={userForm?.sector}
                        onChange={(event) =>
                          setUserForm({
                            ...userForm,
                            sector: event.target.value,
                          })
                        }
                        InputLabelProps={{ shrink: true }}
                      >
                        {sectorlist &&
                          sectorlist.length > 0 &&
                          sectorlist?.map((option, index) => {
                            // console.log("category data", option);

                            return (
                              <MenuItem value={option} key={index}>
                                {option}
                              </MenuItem>
                            );
                          })}
                      </TextField>
                    </Grid>
                  </Grid>
                  {/* second grid  */}
                  <Stack
                    style={{
                      flexDirection: {
                        xl: "row",
                        lg: "row",
                        md: "column",
                        sm: "column",
                      },
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ padding: "12px", marginTop: "25px" }}
                      >
                        <TextField
                          variant="outlined"
                          id="userTag"
                          size="small"
                          className="textborder"
                          fullWidth
                          label={props.t("user.idnumber")}
                          //  className={classes.userTypeMenuItem}
                          // style={{
                          //   marginTop: "6px",
                          // }}
                          // required
                          // className="textborder"
                          disabled={props.formDialog.divType === "edit"}
                          defaultValue={userForm.userTag}
                          value={userForm.userTag}
                          onChange={(event) =>
                            setUserForm({
                              ...userForm,
                              userTag: event.target.value,
                            })
                          }
                        ></TextField>
                      </Grid>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ padding: "12px", marginTop: "25px" }}
                      >
                        <TextField
                          variant="outlined"
                          id="name"
                          size="small"
                          className="textborder"
                          label={props.t("user.name")}
                          autoComplete="something-unsupported"
                          required
                          value={userForm?.name}
                          fullWidth
                          onKeyPress={(event) => {
                            return (event.charCode >= 65 &&
                              event.charCode <= 90) ||
                              (event.charCode >= 97 && event.charCode <= 122) ||
                              event.charCode === 32
                              ? event
                              : event.preventDefault();
                          }}
                          onChange={(event) =>
                            setUserForm({
                              ...userForm,
                              name: event.target.value,
                            })
                          }
                          disabled={
                            props.formDialog.divType === "new" ||
                            props.formDialog.divType === "edit"
                              ? false
                              : true
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ padding: "12px", marginTop: "25px" }}
                      >
                        <TextField
                          variant="outlined"
                          id="mobileNo"
                          size="small"
                          className={`${classes.input} textborder`}
                          label={props.t("user.mobileNo")}
                          autoComplete="something-unsupported"
                          required
                          fullWidth
                          // disabled={props.formDialog.divType === "edit"}
                          value={userForm.mobileNo}
                          inputProps={{ maxLength: 10 }}
                          type={"number"}
                          onKeyPress={(event) => {
                            return event.charCode >= 48 && event.charCode <= 57
                              ? event
                              : event.preventDefault();
                          }}
                          onChange={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 10);
                            setUserForm({
                              ...userForm,
                              mobileNo: e.target.value,
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Stack>

                  {/* third grid */}
                  <Stack
                    style={{
                      flexDirection: {
                        xl: "row",
                        lg: "row",
                        md: "column",
                        sm: "column",
                      },
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ padding: "12px", marginTop: "25px" }}
                      >
                        <TextField
                          variant="outlined"
                          id="email"
                          size="small"
                          className="textborder"
                          label={props.t("user.emailAddress")}
                          autoComplete="something-unsupported"
                          fullWidth
                          value={userForm.email}
                          onChange={(event) =>
                            setUserForm({
                              ...userForm,
                              email: event.target.value,
                            })
                          }
                          onKeyPress={(event) => {
                            return (event.charCode >= 65 &&
                              event.charCode <= 90) ||
                              (event.charCode >= 97 && event.charCode <= 122) ||
                              (event.charCode >= 48 && event.charCode <= 57) ||
                              event.charCode === 64 ||
                              event.charCode === 46
                              ? event
                              : event.preventDefault();
                          }}
                          disabled={
                            props.formDialog.divType === "new" ||
                            props.formDialog.divType === "edit"
                              ? false
                              : true
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ padding: "12px", marginTop: "25px" }}
                      >
                        <TextField
                          variant="outlined"
                          id="occupationType"
                          size="small"
                          label={props.t("user.worktype")}
                          autoComplete="something-unsupported"
                          select
                          className="textborder"
                          value={userForm?.occupationType}
                          onChange={(event) =>
                            setUserForm({
                              ...userForm,
                              occupationType: event.target.value,
                            })
                          }
                          onKeyPress={(event) => {
                            return (event.charCode >= 65 &&
                              event.charCode <= 90) ||
                              (event.charCode >= 97 && event.charCode <= 122) ||
                              (event.charCode >= 48 && event.charCode <= 57) ||
                              event.charCode === 64 ||
                              event.charCode === 46
                              ? event
                              : event.preventDefault();
                          }}
                          disabled={
                            props.formDialog.divType === "new" ||
                            props.formDialog.divType === "edit"
                              ? false
                              : true
                          }
                        >
                          {categorydata &&
                            categorydata.length > 0 &&
                            categorydata?.map((option, index) => {
                              // //console.log("category data", option);

                              return (
                                <MenuItem value={option} key={index}>
                                  {option}
                                </MenuItem>
                              );
                            })}
                        </TextField>
                      </Grid>

                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ padding: "12px", marginTop: "25px" }}
                      >
                        <TextField
                          variant="outlined"
                          id="businessName"
                          size="small"
                          className="textborder"
                          label={props.t("user.businessName")}
                          autoComplete="something-unsupported"
                          fullWidth
                          value={userForm.businessName}
                          onChange={(event) =>
                            setUserForm({
                              ...userForm,
                              businessName: event.target.value,
                            })
                          }
                          onKeyPress={(event) => {
                            return (event.charCode >= 65 &&
                              event.charCode <= 90) ||
                              (event.charCode >= 97 && event.charCode <= 122) ||
                              (event.charCode >= 48 && event.charCode <= 57) ||
                              event.charCode === 64 ||
                              event.charCode === 46
                              ? event
                              : event.preventDefault();
                          }}
                          disabled={
                            props.formDialog.divType === "new" ||
                            props.formDialog.divType === "edit"
                              ? false
                              : true
                          }
                        />
                      </Grid>
                    </Grid>
                  </Stack>

                  {/* fourth grid */}
                  <Stack
                    style={{
                      flexDirection: {
                        xl: "row",
                        lg: "row",
                        md: "column",
                        sm: "column",
                      },
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ padding: "12px", marginTop: "17px" }}
                      >
                        <TextField
                          variant="outlined"
                          id="workingHours"
                          size="small"
                          className="textborder"
                          label={props.t("user.workingHours")}
                          autoComplete="something-unsupported"
                          value={userForm.workingHours}
                          fullWidth
                          // onKeyPress={(event) => {
                          //   return (event.charCode >= 65 &&
                          //     event.charCode <= 90) ||
                          //     (event.charCode >= 97 && event.charCode <= 122) ||
                          //     event.charCode === 32
                          //     ? event
                          //     : event.preventDefault();
                          // }}
                          onChange={(event) =>
                            setUserForm({
                              ...userForm,
                              workingHours: event.target.value,
                            })
                          }
                          disabled={
                            props.formDialog.divType === "new" ||
                            props.formDialog.divType === "edit"
                              ? false
                              : true
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ padding: "12px", marginTop: "17px" }}
                      >
                        <TextField
                          variant="outlined"
                          id="experience"
                          size="small"
                          className="textborder"
                          label={props.t("user.experience")}
                          autoComplete="something-unsupported"
                          value={userForm.experience}
                          fullWidth
                          // onKeyPress={(event) => {
                          //   return (event.charCode >= 65 &&
                          //     event.charCode <= 90) ||
                          //     (event.charCode >= 97 && event.charCode <= 122) ||
                          //     event.charCode === 32
                          //     ? event
                          //     : event.preventDefault();
                          // }}
                          onChange={(event) =>
                            setUserForm({
                              ...userForm,
                              experience: event.target.value,
                            })
                          }
                          disabled={
                            props.formDialog.divType === "new" ||
                            props.formDialog.divType === "edit"
                              ? false
                              : true
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ padding: "12px", marginTop: "17px" }}
                      >
                        <TextField
                          variant="outlined"
                          id="street"
                          size="small"
                          className="textborder"
                          label={props.t("user.street")}
                          autoComplete="something-unsupported"
                          required
                          value={userForm.street}
                          fullWidth
                          onChange={(event) =>
                            setUserForm({
                              ...userForm,
                              street: event.target.value,
                            })
                          }
                          disabled={
                            props.formDialog.divType === "new" ||
                            props.formDialog.divType === "edit"
                              ? false
                              : true
                          }
                        />
                      </Grid>
                    </Grid>
                  </Stack>

                  {/* fifth grid */}
                  <Stack
                    style={{
                      flexDirection: {
                        xl: "row",
                        lg: "row",
                        md: "column",
                        sm: "column",
                      },
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ padding: "12px", marginTop: "25px" }}
                      >
                        <TextField
                          variant="outlined"
                          id="city"
                          size="small"
                          className="textborder"
                          label={props.t("user.city")}
                          autoComplete="something-unsupported"
                          required
                          value={userForm.city}
                          fullWidth
                          // onKeyPress={(event) => {
                          //   return (event.charCode >= 65 &&
                          //     event.charCode <= 90) ||
                          //     (event.charCode >= 97 && event.charCode <= 122) ||
                          //     event.charCode === 32
                          //     ? event
                          //     : event.preventDefault();
                          // }}
                          onChange={(event) =>
                            setUserForm({
                              ...userForm,
                              city: event.target.value,
                            })
                          }
                          disabled={
                            props.formDialog.divType === "new" ||
                            props.formDialog.divType === "edit"
                              ? false
                              : true
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ padding: "12px", marginTop: "25px" }}
                      >
                        <TextField
                          variant="outlined"
                          id="state"
                          size="small"
                          className="textborder"
                          style={{ borderRadius: "2px" }}
                          label={props.t("user.state")}
                          autoComplete="something-unsupported"
                          required
                          value={userForm.state}
                          fullWidth
                          onKeyPress={(event) => {
                            return (event.charCode >= 65 &&
                              event.charCode <= 90) ||
                              (event.charCode >= 97 && event.charCode <= 122) ||
                              event.charCode === 32
                              ? event
                              : event.preventDefault();
                          }}
                          onChange={(event) =>
                            setUserForm({
                              ...userForm,
                              state: event.target.value,
                            })
                          }
                          disabled={
                            props.formDialog.divType === "new" ||
                            props.formDialog.divType === "edit"
                              ? false
                              : true
                          }
                        />
                      </Grid>
                      {/* <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ padding: "12px", marginTop: "25px" }}
                      >
                        <TextField
                          variant="outlined"
                          id="workReference"
                          size="small"
                          className="textborder"
                          multiline
                          maxRows={4}
                          label={props.t("user.workReference")}
                          autoComplete="something-unsupported"
                          required
                          value={userForm.workReference}
                          fullWidth
                          onKeyPress={(event) => {
                            return (event.charCode >= 65 &&
                              event.charCode <= 90) ||
                              (event.charCode >= 97 && event.charCode <= 122) ||
                              event.charCode === 32
                              ? event
                              : event.preventDefault();
                          }}
                          onChange={(event) =>
                            setUserForm({
                              ...userForm,
                              workReference: event.target.value,
                            })
                          }
                          disabled={
                            props.formDialog.divType === "new" ||
                            props.formDialog.divType === "edit"
                              ? false
                              : true
                          }
                        />
                      </Grid> */}
                    </Grid>
                  </Stack>

                  {/* sixth grid */}
                  <Stack
                    style={{
                      flexDirection: {
                        xl: "row",
                        lg: "row",
                        md: "column",
                        sm: "column",
                      },
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ padding: "12px", marginTop: "30px" }}
                      >
                        <TextField
                          variant="outlined"
                          id="referralCode"
                          size="small"
                          className="textborder"
                          label={props.t("user.referralCode")}
                          autoComplete="something-unsupported"
                          value={userForm.referralCode}
                          fullWidth
                          onChange={(event) =>
                            setUserForm({
                              ...userForm,
                              referralCode: event.target.value,
                            })
                          }
                          disabled={props.formDialog.divType === "edit"}
                        />
                      </Grid>
                      {/* <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ padding: "12px", marginTop: "30px" }}
                      >
                        <TextField
                          variant="outlined"
                          id="referralCode"
                          size="small"
                          className="textborder"
                          label={"Admin User Name"}
                          autoComplete="something-unsupported"
                          value={userForm.adminUserName}
                          fullWidth
                          // onKeyPress={(event) => {
                          //   return (event.charCode >= 65 &&
                          //     event.charCode <= 90) ||
                          //     (event.charCode >= 97 && event.charCode <= 122) ||
                          //     event.charCode === 32
                          //     ? event
                          //     : event.preventDefault();
                          // }}
                          onChange={(event) =>
                            setUserForm({
                              ...userForm,
                              adminUserName: event.target.value,
                            })
                          }
                          disabled={
                            props.formDialog.divType === "new" ||
                            props.formDialog.divType === "edit"
                              ? false
                              : true
                          }
                        />
                      </Grid> */}
                      <Grid
                        item
                        xl={8}
                        lg={8}
                        md={8}
                        sm={12}
                        xs={12}
                        style={{ padding: "20px" }}
                      >
                        <Typography
                          variant="caption text"
                          style={{
                            color: "rgba(0, 0, 0, 0.38)",
                            marginBottom: "-20px",
                            // marginLeft: "12px",
                          }}
                        >
                          Description
                        </Typography>
                        <TextareaAutosize
                          style={{
                            paddingBottom: "40px",
                            width: "100%",
                            padding: "10px",
                            fontFamily: "Lato",
                            border: "none",
                            boxShadow: "0px 1px 2px #c8c8c8",
                            borderRadius: "8px",
                          }}
                          id="description"
                          aria-label="empty textarea"
                          value={userForm?.description}
                          onChange={(event) =>
                            setUserForm({
                              ...userForm,
                              description: event.target.value,
                            })
                          }
                          // style={{ width: "100%",border:"none",outline:"none" }}
                        />
                      </Grid>
                    </Grid>
                  </Stack>

                  {/* 7 th grid upload images grid  */}
                  <ImagesUpload setUserForm={setUserForm} userForm={userForm} />

                  {/* 8th grid reportedby */}
                  {userForm?.status === "reported" ? (
                    <Stack>
                      <ReportedBy
                        data={companyName?._id}
                        rowDetails={props?.rowDetails?._id}
                      />
                    </Stack>
                  ) : (
                    <></>
                  )}
                </Stack>
              </>
            )}

            {/* plan stack data */}
            {activeTab === 1 && (
              <>
                <Plans
                  {...{
                    userForm,
                    setUserForm,
                    companyName,
                    addplan,
                    setAddplan,
                    sendToServer,
                    viewplan,
                    plandata,
                    planhistory,
                    setPlanData,
                    setPlanHistory,
                    activecount,
                    setActiveCount,
                  }}
                />
              </>
            )}

            {/* revies stack data */}
            {activeTab === 2 && (
              <>
                <Reviews
                  data={companyName}
                  new={props.formDialog.divType === "edit"}
                />
              </>
            )}

            {activeTab === 3 && (
              <>
                <Mylooms
                  data={companyName}
                  edit={props.formDialog.divType === "edit"}
                  setUserForm={setUserForm}
                  userForm={userForm}
                  userId={selectedRowId}
                  showNotification={props.publishNotification}
                  getEditable={getEditable}
                  create={props?.formDialog?.divType === "new"}
                  props={props}
                />
              </>
            )}

            {activeTab === 4 && (
              <>
                {listLogs?.length > 0
                  ? listLogs?.map((val, ind) => (
                      <LogMessage
                        message={val?.sendMessage}
                        userName={val?.createdBy?.name}
                        time={val?.createdAt}
                        isShowLast={listLogs?.length - 1 === ind}
                        isUsers={true}
                        moduleName={val?.moduleType}
                      />
                    ))
                  : "No logs present"}
                <ChatInput
                  createLogMessage={createLogMessage}
                  recordId={selectedRowId}
                  listLogMessages={listLogMessages}
                  moduleType="users"
                  createdFor={selectedRowId}
                />
              </>
            )}
          </Stack>
        </Stack>
      ) : (
        <Stack style={{ background: "white" }}>
          <Stack
            style={{
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "column",
                sm: "column",
              },
              background: "white",
              gap: "10px",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ marginTop: "7px", padding: "20px" }}
              >
                <TextField
                  id="userType"
                  label={props.t("user.userType")}
                  variant="outlined"
                  // style={{ boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.15)" }}
                  disabled={props.formDialog.divType === "edit"}
                  required
                  select
                  fullWidth
                  value={userForm.userType}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      userType: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={"admin"}>
                    {props.t("user.admin")}
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: "20px", marginTop: "10px" }}
              >
                <TextField
                  variant="outlined"
                  id="active"
                  // size="small"
                  label={props.t("user.active")}
                  //  className={classes.userTypeMenuItem}
                  select
                  // style={{
                  //   marginTop: "6px",
                  // }}
                  fullWidth
                  // required
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                  defaultValue={userForm.active}
                  value={userForm.active}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      active: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={true}>
                    {props.t("user.active")}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    {props.t("user.inactive")}
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Stack>

          {/* second grid */}

          <Stack
            style={{
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "column",
                sm: "column",
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: "20px", marginTop: "10px" }}
              >
                <TextField
                  variant="outlined"
                  id="fname"
                  label={props.t("user.name")}
                  autoComplete="something-unsupported"
                  required
                  value={userForm.name}
                  fullWidth
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      event.charCode === 32
                      ? event
                      : event.preventDefault();
                  }}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      name: event.target.value,
                    })
                  }
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                />
              </Grid>

              {/* <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                className="calaneder"
                style={{ padding: "20px", marginTop: "10px" , }}
              >
                <Stack style={{borderBottom:"1px solid black"}}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  style={{border:"1px solid black"}}
                  // variant="outlined"
                >
                  <KeyboardDatePicker
                    // variant="outlined"
                    // margin="normal"
                  
                    id="dob"
                    autoComplete="something-unsupported"
                    fullWidth
                    disabled={
                      props.formDialog.divType === "new" ||
                      props.formDialog.divType === "edit"
                        ? false
                        : true
                    }
                    disableFuture={true}
                    label={props.t("user.dob")}
                    format="dd/MM/yyyy"
                    value={userForm.dob}
                    required
                    // style={{
                    //   marginTop: "-0px",
                    //   boxShadow: "0px 1px 2px #c8c8c8",
                    //   borderRadius: "10px",
                    //   border: "none",
                    //   outline: "none",
                    // }}
                    onChange={(date) => {
                      if (date !== null) {
                        var birthdate = new Date(date);
                        var cur = new Date();
                        var diff = cur - birthdate;
                        var age = Math.floor(diff / 31536000000);
                        setUserForm({
                          ...userForm,
                          dob: date,
                          age: age,
                        });
                      } else {
                        setUserForm({
                          ...userForm,
                          age: "",
                        });
                      }
                    }}
                    // InputProps={{
                    //   disabled: true,
                    // }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
                </Stack>
              </Grid> */}
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: "20px", marginTop: "10px" }}
              >
                <TextField
                  variant="outlined"
                  id="gender"
                  label={props.t("user.gender")}
                  select
                  required
                  autoComplete="something-unsupported"
                  // InputLabelProps={{
                  //   shrink: userForm.gender !== '' ? true : false,
                  // }}
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                  fullWidth
                  defaultValue={userForm.gender}
                  value={userForm.gender}
                  onClick={(event) =>
                    setUserForm({
                      ...userForm,
                      gender: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={"Male"}>
                    {props.t("user.male")}
                  </MenuItem>
                  <MenuItem key={1} value={"Female"}>
                    {props.t("user.female")}
                  </MenuItem>
                  <MenuItem key={2} value={"Other"}>
                    {props.t("user.other")}
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: "20px", marginTop: "10px" }}
              >
                <TextField
                  variant="outlined"
                  id="email"
                  label={props.t("user.emailAddress")}
                  autoComplete="something-unsupported"
                  fullWidth
                  value={userForm.email}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      email: event.target.value,
                    })
                  }
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      (event.charCode >= 48 && event.charCode <= 57) ||
                      event.charCode === 64 ||
                      event.charCode === 46
                      ? event
                      : event.preventDefault();
                  }}
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                />
              </Grid>
            </Grid>
          </Stack>

          {/* third Grid */}

          <Stack
            style={{
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "column",
                sm: "column",
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: "20px" }}
                className={classes.input}
              >
                <TextField
                  variant="outlined"
                  id="mobileNo"
                  label={props.t("user.mobileNo")}
                  autoComplete="something-unsupported"
                  required
                  className=""
                  fullWidth
                  disabled={props.formDialog.divType === "edit"}
                  value={userForm.mobileNo}
                  inputProps={{ maxLength: 10 }}
                  type={"number"}
                  sx={{
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    "& input[type=number]::-webkit-outer-spin-button": {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                    "& input[type=number]::-webkit-inner-spin-button": {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  }}
                  onKeyPress={(event) => {
                    return event.charCode >= 48 && event.charCode <= 57
                      ? event
                      : event.preventDefault();
                  }}
                  onChange={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                    setUserForm({
                      ...userForm,
                      mobileNo: e.target.value,
                    });
                  }}
                />
              </Grid>

              {/* <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: "20px" }}
              >
                <TextField
                  id="qualification"
                  variant="outlined"
                  label={props.t("user.qualification")}
                  value={userForm.qualification}
                  fullWidth
                  // onKeyPress={(event) => {
                  //   return (event.charCode >= 65 && event.charCode <= 90) ||
                  //     (event.charCode >= 97 && event.charCode <= 122) ||
                  //     event.charCode === 32
                  //     ? event
                  //     : event.preventDefault();
                  // }}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      qualification: event.target.value,
                    })
                  }
                ></TextField>
              </Grid> */}

              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: "20px" }}
              >
                <TextField
                  style={{}}
                  id="permission"
                  // size="small"
                  variant="outlined"
                  label={props.t("user.permission")}
                  select
                  required
                  fullWidth
                  defaultValue={userForm.permission}
                  value={userForm.permission}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      permission: event.target.value,
                    })
                  }
                >
                  {props.roles.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Stack>

          {/* fourth grid */}
          <Stack
            style={{
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "column",
                sm: "column",
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: "20px", marginTop: "6px" }}
              >
                <TextField
                  size="large"
                  className={`${classes.input} input__Style`}
                  variant="outlined"
                  placeholder="Enter password"
                  label={"Password"}
                  value={userForm?.password || ""}
                  type={showPassword ? "text" : "password"}
                  // }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <Eye24Filled /> : <EyeOff24Filled />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setUserForm({
                      ...userForm,
                      password: e.target.value.trim(),
                    });
                  }}
                  autoComplete="off"
                  style={{ width: "100%" }}
                />

                {props.formDialog.divType === "edit" && (
                  <div style={{ padding: "12px" }}>
                    <Typography
                      variant="span"
                      style={{
                        color: "#DB2D2E",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                        cursor: "pointer",
                      }}
                      onClick={() => resetPassAttempts(selectedRowId)}
                    >
                      Reset password attempts
                    </Typography>
                  </div>
                )}
              </Grid>
              <Grid
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                // className={classes.formGrid}
                style={{ padding: "20px", marginTop: "6px" }}
              >
                <TextField
                  id="isSuperAdmin"
                  // className={classes.userTypeMenuItem}
                  label={props.t("user.isSuperAdmin")}
                  select
                  variant="outlined"
                  // style={{ marginLeft: "10px", marginTop: "10px" }}
                  required
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                  fullWidth
                  defaultValue={userForm.isSuperAdmin}
                  value={userForm.isSuperAdmin}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      isSuperAdmin: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={true}>
                    {props.t("user.yes")}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    {props.t("user.no")}
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AEVForm)
);
