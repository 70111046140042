import { Box } from "@material-ui/core";
import React from "react";
import StatisticsCard from "./StatisticsCard";
import { useStyles } from "../styles/style";
import { statsConstData } from "../constants/constant";
import GraphHeader from "./GraphHeader";
import BarGraph from "./BarGraph";

function StatsAndGraph({ services, graphFiltersData, setGraphFiltersData }) {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.statastics}>
        {services?.statisticsData?.map((data, i) => (
          <StatisticsCard
            classes={classes}
            background={statsConstData?.[i]?.color}
            Icon={statsConstData?.[i]?.icon}
            label={statsConstData?.[i]?.label}
            totalNumber={data?.Counts || "0"}
            newlyAddedCount={data?.newlyCreatedCount || "0"}
            headData={data?.headData}
            contentData={data?.contentData}
          />
        ))}
      </Box>
      <Box
        sx={{
          marginBottom: "24px",
          marginTop: "30px",
        }}
      >
        <GraphHeader
          graphFiltersData={graphFiltersData}
          setGraphFiltersData={setGraphFiltersData}
          services={services}
        />
      </Box>

      {/* bar graph */}
      <BarGraph
        color={"red"}
        services={services}
        graphFiltersData={graphFiltersData}
      />
    </>
  );
}

export default StatsAndGraph;
