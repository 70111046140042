import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid, makeStyles } from "@material-ui/core";
import TableQuery from "./TableQuery";
import TableHeader from "./TableHeader";
import DataTable from "../../../../templates/tables/DataTable";
import APIRequestDataTableQuery from "../../../../utils/APIRequestDataTableQuery";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import TableButtons from "../../../../templates/buttons/TableButtons";
import SlideInDialog from "../../../../templates/dialog/SlideInDialog";
import AddRole from "./AddRole";
import { connect } from "react-redux";
import LocalStorage from "../../../../config/LocalStorage";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import FormDialog from "../../../../templates/dialog/FormDialog";
import EditRole from "./EditRole";
import AssignRole from "./AssignRole";
import APIRequest from "../../../../utils/APIRequest";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));
const queryBody = {
  keyword: "",
  page: 0,
  pageSize: 10,
  sortField: "",
  sortOrder: "false",
  active: true,
  startDate: null,
  endDate: Math.floor(new Date().getTime() / 1000),
};

function Role(props) {
  const classes = useStyles();
  /* table query body -
	keyword, page, pageSize, sortField, sortOrder should be constant for all the page. 
	Here status is use for filter condition.
	*/
  const [query, setQuery] = useState(queryBody);
  // initial table record
  const [tableData, setTableData] = useState({
    rows: null,
    pages: 0,
    filterRecords: 0,
  });

  //   Edit Form

  const [openForm, setOpenForm] = useState({
    status: false,
    title: "",
    divType: "",
    functionName: "",
  });

  const handleFormDialog = () => {
    setRowDetails(null);
    tableQuery(query);
    setOpenForm({
      status: false,
      title: "",
      divType: "",
      functionName: "",
    });
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const submit = () => {
    setOpenForm({ ...openForm, functionName: "submit" });
  };
  const resetForm = () => {
    setOpenForm({ ...openForm, functionName: "reset" });
  };

  // selected table record information
  const [rowDetails, setRowDetails] = useState(null);
  // selected table record information
  const [slideInDialog, setSlideInDialog] = useState(false);
  // buttons list for data table

  const [buttonList, setButtonDetails] = useState([]);
  // useful of on page load
  useEffect(() => {
    tableQuery(query); // calling table api on page load by default value
    setButtonDetails(LocalStorage.adminButtonPermission);
  }, [LocalStorage.adminButtonPermission]);

  // table query body value handler
  const handleQuery = (query) => {
    setQuery(query);
    tableQuery(query);
  };
  // table query body value reset handler
  const resetQuery = () => {
    setQuery(queryBody);
    tableQuery(queryBody);
  };
  // fetch table record from server with current query body
  const tableQuery = (query) => {
    if (
      LocalStorage.adminButtonPermission.find(function (element) {
        return element.button === "query" ? true : false;
      })
    ) {
      APIRequestDataTableQuery.request(
        "POST",
        ConfigAPIURL.roleTableURL,
        JSON.stringify(query)
      ).then((tableData) => {
        setTableData(tableData);
      });
    } else {
      // notification(props.t('toaster.tableRecordPermission'), 'error');
    }
  };
  const notification = (message, type) => {
    SnackbarUtils[type](message, "bottomCenter", 3000).then((notification) => {
      props.publishNotification(notification);
    });
  };
  // table record selected callback
  const tableCallBack = (rowDetails) => {
    // if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
    setRowDetails(rowDetails);
    // }
  };
  //table button call back function
  const tableAdd = () => {
    if (rowDetails?.length > 0) {
      SnackbarUtils.error(
        props.t("Please unselect the row."),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    } else {
      setSlideInDialog(true);
      // setOpenForm((prev) => ({
      //   ...prev,
      //   status: true,
      //   title: props.t("Add Post"),
      //   divType: "new",
      //   functionName: "",
      // }));
    }
    // setSlideInDialog(true);
  };
  const tableEdit = () => {
    if (rowDetails?.length > 1) {
      SnackbarUtils.error(
        props.t("Select only one row"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    } else if (rowDetails?.length === 1) {
      setOpenForm({
        status: true,
        title: props.t("role.editRole"),
        divType: "edit",
        functionName: "",
      });
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };
  const tableAssign = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      //   console.log('tableEdit');
      setOpenForm({
        status: true,
        title: props.t("role.assignRole"),
        divType: "assign",
        functionName: "",
      });
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };
  const handleDeleteModal = () => {
    setDeleteModal(false);
  };

  const tableDelete = (response) => {
    if (response === "agree") {
      const recordIds = rowDetails.map((row) => row._id);
      APIRequest.request(
        "PUT",
        ConfigAPIURL.deleteRole,
        JSON.stringify({ recordIds: recordIds })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            tableQuery(query);
            setRowDetails(null);
            setOpenForm({
              status: false,
              title: "",
              divType: "",
              functionName: "",
            });
            SnackbarUtils.sucess(
              props.t("snackbar.updatedSuccess"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            handleDeleteModal();
          }
        }
      });
    } else {
      handleDeleteModal();
      setRowDetails(null);
      setOpenForm({ status: false, title: "", divType: "", functionName: "" });
    }
  };
  const handleSlideInDialog = () => {
    setSlideInDialog(false);
    tableQuery(query);
  };

  const handleTableDelete = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      if (query.active === true) {
        setDeleteModal(true);
      } else {
        SnackbarUtils.error(
          props.t("snackbar.recordAlreadyDeleted"),
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } else {
      SnackbarUtils.error(props.t("snackbar.selectRow"), 3000).then(
        (notification) => {
          props.publishNotification(notification);
        }
      );
    }
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
          <DataTable
            tableHeader={TableHeader.tableHeader} // table column header
            tableData={tableData} // pass table record
            query={query} // pass query condition
            setQuery={handleQuery} // update query fields
            resetQuery={resetQuery} // reset filter menu values
            selectAll={true} //select all check box props
            openForm={openForm} //un select all check box props
            tableCallBack={tableCallBack} // table record response back
            searchLabel={props.t("role.searchLabel")}
            queryComponent={<TableQuery query={query} setQuery={handleQuery} />} //filter menu option
            buttonComponent={
              <TableButtons
                tableAdd={tableAdd}
                tableEdit={tableEdit}
                tableDelete={handleTableDelete}
                tableAssign={tableAssign}
                buttonList={buttonList}
              />
            }
          />
        </Grid>
      </Grid>

      <FormDialog
        sideDrawerData={props.sideDrawerData}
        formDialog={openForm}
        handleFormDialog={handleFormDialog}
        submit={submit}
        resetForm={resetForm}
        formComponent={
          openForm.divType === "edit" ? (
            <EditRole
              formDialog={openForm}
              handleFormDialog={handleFormDialog}
              rowDetails={rowDetails?.[0]}
              tableQuery={tableQuery}
            />
          ) : (
            <AssignRole
              formDialog={openForm}
              handleFormDialog={handleFormDialog}
              rowDetails={rowDetails?.[0]}
              tableQuery={tableQuery}
            />
          )
        }
      />
      <SlideInDialog
        title={props.t("role.addRole")}
        contentComponent={<AddRole setSlideInDialog={handleSlideInDialog} />}
        fullWidth={true}
        maxWidth={"sm"}
        slideInDialog={slideInDialog}
        setSlideInDialog={handleSlideInDialog}
      />

      <SlideInDialog
        title="Delete Role"
        contentComponent={props.t("dialog.delete")}
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus={true}
        slideInDialog={deleteModal}
        setSlideInDialog={handleDeleteModal}
        handleDeleteRequest={tableDelete}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(Role)
);
