import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { Stack } from "@mui/material";
import DateConversion from "../../../../../utils/DateConversion";
import Modals from "../Modal1";
import PlanStack from "../PlanStack";
import StringUtils from "../../../../../utils/StringUtils";

export default function Plans({
  userForm,
  setUserForm,
  companyName,
  addplan,
  setAddplan,
  sendToServer,
  viewplan,
  plandata,
  planhistory,
  setPlanData,
  setPlanHistory,
  activecount,
  setActiveCount,
}) {
  console.log("plansss", userForm);
  return (
    <Stack style={{ marginTop: "20px" }} className="k4">
      <Grid container>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          // style={{ padding: "20px", marginTop: "7px" }}
        >
          <Stack className="currentdata">
            <Stack className="currentplan">
              <Typography className="plannames">Current Plan</Typography>
            </Stack>
            <Stack style={{ background: "white" }}>
              <Stack style={{ padding: "15px" }}>
                {/* plan name and price */}
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack className="planType">
                    <Typography className="plantypename">
                      {StringUtils.capitalize(userForm?.planName)}
                    </Typography>
                  </Stack>
                  <Stack justifyContent={"center"}>
                    <Typography className="plantypename1">
                      {"₹" + userForm?.subscribedPrice}
                    </Typography>
                  </Stack>
                </Stack>
                {/* experies on stack */}
                <Stack style={{ marginTop: "10px" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={"5px"}
                    alignItems={"center"}
                  >
                    <Typography
                      style={{
                        fontFamily: "Lato",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "14px",

                        color: " #868686",
                      }}
                    >
                      Expires on :
                    </Typography>
                    <Typography fontWeight={600}>
                      {/* {expireAt} */}
                      {/* {expireAt.toLocaleDateString()} */}
                      {DateConversion.unixToLocaleDate(userForm?.expireAt)}
                    </Typography>
                  </Stack>
                </Stack>

                {/* Reneview plan button */}
                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  style={{ marginTop: "15px" }}
                >
                  <Stack>
                    <Typography className="reneviewplan">Renew Plan</Typography>
                    <Typography className="clickbutton">
                      Click Renew button to add plan
                    </Typography>
                  </Stack>

                  <Stack>
                    <Modals
                      userForm={userForm}
                      setUserForm={setUserForm}
                      data={companyName}
                      addplan={addplan}
                      setAddplan={setAddplan}
                      // handelfunction={handelLoginNo}
                      //  handereject={showDeleteConfirm}
                      handelserver={sendToServer}
                    />
                  </Stack>
                </Stack>
                {/* {planhistory[0].planId===userForm?.planId?"krishna":"sai"} */}
                {/* {userForm?.subscriptionId===viewplan?._id?"krishna":"sai"} */}

                {addplan ? (
                  <>
                    {viewplan && (
                      //  setViewPlan(option)

                      // //console.log(viewplan,"plan")

                      // //console.log(option, "option")
                      <Stack className="addedplan">
                        <Typography
                          style={{
                            fontFamily: "Lato",
                            fontWeight: "700",
                            fontSize: "16px",
                            padding: "10px",
                            // color: "#000000",
                          }}
                        >
                          Added Plan
                        </Typography>

                        {/* plan name and price */}
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Stack
                            className="planType"
                            style={{ padding: "2px,8px,2px,8px" }}
                          >
                            <Typography className="plantypename">
                              {viewplan?.title}
                            </Typography>
                          </Stack>
                          <Stack justifyContent={"center"}>
                            <Typography className="plantypename1">
                              {"₹" + viewplan?.offerPrice}
                            </Typography>
                          </Stack>
                        </Stack>
                        {/* experies on stack */}
                        {/* <Stack style={{ marginTop: "10px" }}>
                          <Stack
                            flexDirection={"row"}
                            gap={"5px"}
                            alignItems={"center"}
                          >
                            <Typography
                              style={{
                                fontFamily: "Lato",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "14px",

                                color: " #868686",
                              }}
                            >
                              Expires on :
                            </Typography>
                            <Typography fontWeight={600}>
                              {expireAt.toLocaleDateString()}
                            </Typography>
                          </Stack>
                        </Stack> */}

                        {/* Note for the added plan */}
                        <Stack
                          flexDirection={"row"}
                          style={{
                            marginTop: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#868686",
                            }}
                          >
                            Note:
                          </Typography>
                          <Typography
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {userForm?.note}
                          </Typography>
                        </Stack>
                      </Stack>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          //  style={{ border:"1px solid red" }}
        >
          <Stack className="currentdata">
            <Stack className="currentplan1">
              <Typography className="plannames">Plan History</Typography>
            </Stack>
            <Stack>
              <PlanStack
                data={companyName}
                plandata={plandata}
                setPlanData={setPlanData}
                planhistory={planhistory}
                setPlanHistory={setPlanHistory}
                activecount={activecount}
                setActiveCount={setActiveCount}
              ></PlanStack>
            </Stack>

            <Stack></Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
