import React, { useEffect, useRef, useState } from "react";
import { Translation, withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Box,
  styled,
  Checkbox,
  TextareaAutosize,
  Input,
  Card,
  CardContent,
  Avatar,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../../utils/APIRequest.js";
import FormValidation from "../../../../../utils/FormValidation.js";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL.js";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../../utils/SnackbarUtils.js";
import { Chip } from "@mui/material";
import landingImg from "../../../../../assets/images/Pic.png";
import DownloadIcon from "@mui/icons-material/Download";
// import ImageUpload from "./ImageUpload";
import Toaster from "../../../../../utils/Toaster.js";
import isEmpty from "../../../../../utils/isEmpty.js";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ImagesUpload from "./ImagesUpload.jsx";
import MicIcon from "@material-ui/icons/Mic";
import LocalStorage from "../../../../../config/LocalStorage.js";
import { CheckValidation } from "../../../../../utils/Validator.js";
import { DateConvert } from "../../../../../utils/DataConvert.js";
import { Button } from "@fluentui/react";
import LogMessage from "../Members/LogMessages.jsx";
import ChatInput from "../Members/ChartInput.jsx";
import { Image, Visibility } from "@material-ui/icons";
import { Stack } from "@mui/material";
import LogMessages from "../../../../../utils/LogMessages.js";
import SlideInDialog from "../../../../../templates/dialog/SlideInDialog.js";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  approveButton: {
    color: "white",
    backgroundColor: "red",
    boxShadow: "0 2px 4px red",
  },
  rejectButton: {
    color: "black",
    backgroundColor: "yellow",
    boxShadow: "0 2px 4px red",
  },
  approve: {
    color: "green",
    borderColor: "green",
    background: "#FFECD3",
    padding: "10px",
    borderRadius: "10px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#FFECD3",
      color: "green",
    },
  },
  reject: {
    color: "red",
    borderColor: "red",
    background: "#FFECD3",
    padding: "10px",
    borderRadius: "10px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#FFECD3",
      color: "red",
    },
  },
  heading: {
    fontSize: "16px",
    fontWeight: 700,
  },
  subhead: {
    color: "#868686",
    fontSize: "14px",
    fontWeight: 400,
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const postDetails = {
  description: "",
  reportImage: "",
  status: "",
  userDetails: {
    mobileNo: "",
    name: "",
    email: "",
    fragmentedAddress: "",
    location: {
      type: "Point",
      coordinates: ["", ""],
    },
    category: "",
  },
  reportedDetails: {
    mobileNo: "",
    name: "",
    email: "",
    fragmentedAddress: "",
    location: {
      type: "Point",
      coordinates: ["", ""],
    },
    category: "",
  },
};

function AddUser(props) {
  const [selectedOption, setSelectedOption] = useState("");

  const [location, setLocation] = useState("");
  const [places, setPlaces] = useState([]);
  const [placeId, setPlaceId] = useState([]);
  const [locationNames, setlocationNames] = useState("");
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [userForm, setUserForm] = useState(postDetails);
  const [categorydata, setCategoryData] = useState([]);
  const [sectorlist, setSectorlist] = useState([]);
  const [addplan, setAddPlan] = useState([]);
  const [selectedButton, setSelectedButton] = useState("");
  // const [handloom, setHandloom] = useState(postDetails.sector);
  const [createdFor, setCreatedFor] = useState("");
  const userDetails = LocalStorage.userDetails;
  const { listLogMessages, listLogs, createLogMessage } = LogMessages();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (selectedRowId) {
      listLogMessages("reports", selectedRowId);
    }
  }, [selectedRowId]);

  useEffect(() => {
    setUserForm((prev) => ({
      ...prev,
      name: userDetails.name,
      mobileNo: userDetails.mobileNo,
    }));
  }, [userDetails]);

  useEffect(() => {
    //changes made here
    // setUserForm(postDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (props?.formDialog?.functionName === "reset") {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    setUserForm(JSON.parse(JSON.stringify(postDetails)));
  };
  const categorylist = () => {
    APIRequest.request("GET", ConfigAPIURL.listcategory, "").then(
      (response) => {
        let userResult = response?.data?.result;
        setCategoryData(userResult);
        // console.log(categorydata, "categorydata");
      }
    );
  };

  useEffect(() => {
    categorylist();
  }, []);
  // console.log(categorydata, "data");

  //getting data for sector
  const sectorListData = () => {
    APIRequest.request("GET", ConfigAPIURL.sectorData, "").then((response) => {
      let userResult = response?.data?.result;
      setSectorlist(userResult);
      // console.log(sectorlist, "sectorData");
    });
  };

  useEffect(() => {
    sectorListData();
  }, []);

  // console.log(sectorlist, "sectorlist");

  const getEditable = () => {
    if (
      (props.rowDetails?._id || selectedRowId) &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.reportDetails,
        JSON.stringify({ recordId: props.rowDetails?._id || selectedRowId })
      ).then((response) => {
        // console.log(response, "response");
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let reportDetails = response?.data?.reportDetails;
            console.log(reportDetails, "postResult");
            setUserForm({
              ...userForm,

              userDetails: {
                mobileNo: reportDetails?.userId?.mobileNo,
                name: reportDetails?.userId?.name,
                email: reportDetails?.userId?.email,
                fragmentedAddress: reportDetails?.userId?.fragmentedAddress,
                location: {
                  type: reportDetails?.userId?.location.type,
                  coordinates: [
                    reportDetails?.userId?.location?.coordinates?.[0],
                    reportDetails?.userId?.location?.coordinates?.[0],
                  ],
                },
                category: reportDetails?.userId?.category,
              },
              reportedDetails: {
                mobileNo: reportDetails?.receiverId?.mobileNo,
                name: reportDetails?.receiverId?.name,
                email: reportDetails?.receiverId?.email,
                fragmentedAddress: reportDetails?.receiverId?.fragmentedAddress,
                category: reportDetails?.receiverId?.category,
                location: {
                  type: reportDetails?.receiverId?.location.type,
                  coordinates: [
                    reportDetails?.receiverId?.location?.coordinates?.[0],
                    reportDetails?.receiverId?.location?.coordinates?.[0],
                  ],
                },
              },
              description: reportDetails?.description,
              reportImage: reportDetails?.reportImage,
              status: reportDetails?.status,
            });
            setCreatedFor(reportDetails?.receiverId?._id);
            setSelectedRowId(reportDetails._id);
          }
        }
      });
    }
  };

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        // handleChange();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    let url = ConfigAPIURL.postCreate;
    let method = "POST";

    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.reportUpdate;
      userForm["recordId"] = selectedRowId;
      method = "POST";
    }

    let formCopy = { ...userForm };
    // delete formCopy.attachment;

    // const missingFields = CheckValidation(formCopy);
    // console.log(missingFields, "misssingFields");
    // if (missingFields.length > 0 || formCopy?.attachment?.length === 0) {
    //   SnackbarUtils.error(
    //     "Please fill all the mandatory fields",
    //     "bottomCenter",
    //     3000
    //   ).then((notification) => {
    //     props.publishNotification(notification);
    //   });
    //   return;
    // }
    if (selectedButton) {
      userForm.status = selectedButton;
    }
    let userFormCopy = { ...userForm };

    APIRequest.request(method, url, JSON.stringify(userFormCopy)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            SnackbarUtils.sucess(
              props.t("snackbar.updatedSuccess"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            props.handleFormDialog();
            props.tableQuery({
              keyword: "",
              page: 0,
              pageSize: 10,
              sortField: "",
              userType: "all",
              sortOrder: "false",
              postStatus: "all",
              active: true,
            });
            setUserForm(postDetails);
            setSelectedRowId("");
          }
          if (response.code === 100 && response.data.responseCode === 114) {
            SnackbarUtils.error(
              props.t("snackbar.dupliRecords"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      }
    );
  };
  //functions for getting location cordinates
  const handleChange = async (event) => {
    const placeName = event.target.value;

    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getLocation}?place=${placeName}`
    ).then((res) => {
      // console.log(res.data.result.predictions);
      setPlaces(res.data.result.predictions);
    });
    setLocation("");
  };

  const handleLocation = async () => {
    // console.log("this is evee optionsss", eve);
    // setlocationNames(selectedOption.description);

    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getLonLan}?place_id=${placeId}`
    ).then((res) => {
      setLocation(res.data?.result?.result?.geometry?.location);
      setUserForm((prev) => ({
        ...prev,
        location: {
          ...prev?.location,
          coordinates: [
            res.data?.result?.result?.geometry?.location?.lat,
            res.data?.result?.result?.geometry?.location?.lng,
          ],
        },
      }));
    });
  };
  useEffect(() => {
    handleLocation();
  }, [placeId]);

  useEffect(() => {
    if (selectedOption) {
      // setSelectedOption(selectedOption);
      setlocationNames(selectedOption.description);
      setPlaceId(selectedOption);
      places.find((option, i) => {
        if (option?.description === selectedOption) {
          setPlaceId(option?.place_id);
        }
      });
      // setAddForm({
      //   ...addForm,
      //   locationName: selectedOption.description,
      // });
      handleLocation();
    }
  }, [selectedOption, placeId]);

  const getPlans = () => {
    APIRequest.request("POST", ConfigAPIURL.plans).then((response) => {
      let userResult = response?.data?.rows;
      setAddPlan(userResult);
      // console.log(userResult);
    });
  };
  useEffect(() => {
    getPlans();
  }, []);
  // console.log("plan", addplan);
  // console.log(userForm, "userForm");

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: "black",
  }));
  // console.log(plan, "planid");
  const expiresOn = "companyName"?.startDate + "companyName"?.endAt * 86400;
  const rangeOptions = [5, 10, 15, 25, 35, "max"];
  const validityTypeOptions = [
    { label: props.t("Permanent"), value: true },
    { label: props.t("Temporary"), value: false },
  ];

  //handaling range function here
  const handleRangeChange = (event, newValue) => {
    let updatedRange = newValue;

    if (newValue === "max") {
      updatedRange = null; // Set range to null when "max" is selected
    } else if (newValue && !Number.isNaN(Number(newValue))) {
      updatedRange = parseInt(newValue); // Convert numeric range values to integers
    }

    setUserForm({
      ...userForm,
      range: updatedRange,
    });
  };

  //for fetching all tags for creation of post
  const [allTags, setAllTags] = useState([]);

  const fetchAllTags = async () => {
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.getAllTagsForPost,
      ""
    );
    if (response?.data?.responseCode === 109) {
      setAllTags(response.data.result);
    }
  };

  useEffect(() => {
    fetchAllTags();
  }, []);

  const handleRejectApprove = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.reportUpdate,
        JSON.stringify({ recordId: selectedRowId, status: selectedButton })
      );
      if (response?.data?.responseCode === 109) {
        getEditable();
        setIsOpen(false);
        SnackbarUtils.sucess(
          props.t(response?.data?.message),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      } else {
        console.log(response);
        SnackbarUtils.sucess(
          props.t(response?.data?.message),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } catch (error) {
      console.error("Error fetching location:", error.message || error);
    }
  };
  const disableData = props.formDialog.divType === "view";
  console.log(userForm, "userForm");
  return (
    <div
      className={classes.root}
      style={{
        marginTop: props.marginTop,
        fontFamily: "Lato",
        position: "relative",
        top: "-13px",
      }}
    >
      <Stack style={{ padding: "20px" }}>
        {/* buttons stack */}

        <Stack
          style={{ background: "#FFECD3", height: " 60px", zIndex: "1" }}
          sx={{
            flexDirection: { lg: "row", sm: "row", md: "row" },
            justifyContent: "space-between",
            rowGap: "10px",
          }}
        >
          <Stack style={{ flexDirection: "row", gap: "7px" }}>
            <Stack
              style={{
                paddingBottom: "3px",
              }}
            >
              <Typography
                className="personaldetails"
                style={{
                  fontWeight: "800",
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "20px",
                  underline: "none",
                  position: "relative",
                  top: "10px",
                  left: "27px",
                }}
              >
                Report Details
              </Typography>
            </Stack>
          </Stack>
          {props.formDialog.divType === "view" && (
            <Stack
              style={{
                flexDirection: "row",
                gap: "7px",
                marginTop: "10px",
                marginRight: "30px",
              }}
            >
              <Button
                className={classes.reject}
                onClick={() => {
                  setSelectedButton("rejected");
                  setIsOpen(true);
                  // setUserForm((prev) => ({
                  //   ...prev,
                  //   status: "rejected",
                  // }));
                }}
                style={{
                  color: selectedButton === "rejected" ? "white" : "red",
                  backgroundColor: selectedButton === "rejected" ? "red" : null,
                  display: userForm?.status === "approved" && "none",
                }}
                disabled={userForm?.status !== "sent"}
              >
                Reject
              </Button>
              <Button
                className={classes.approve}
                onClick={() => {
                  setSelectedButton("approved");
                  setIsOpen(true);
                  // setUserForm((prev) => ({
                  //   ...prev,
                  //   status: "approved",
                  // }));
                }}
                style={{
                  color: selectedButton === "approved" ? "white" : "green",
                  backgroundColor:
                    selectedButton === "approved" ? "green" : null,
                  display: userForm?.status === "rejected" && "none",
                }}
                disabled={userForm?.status !== "sent"}
              >
                Approve
              </Button>
            </Stack>
          )}
        </Stack>

        {/* details stack of users */}

        <Stack
          style={{
            padding: "20px",
            background: "white",
            borderRadius: "8px",
            marginTop: "20px",
            position: "relative",
            top: "-24px",
          }}
        >
          <>
            <Box sx={{ padding: "16px" }}>
              <Grid container spacing={2}>
                {/* User Details Section */}
                <Grid item xs={12} md={6}>
                  <Typography className={classes.heading}>
                    Reported By
                  </Typography>
                  <Card variant="outlined">
                    <CardContent>
                      <Box display="flex" alignItems="center" mr={1}>
                        <Avatar
                          src="https://via.placeholder.com/150"
                          alt={userForm?.userDetails?.name}
                          sx={{ width: 88, height: 88, marginRight: 2 }}
                        />
                        <Box style={{ padding: "10px" }}>
                          <Typography style={{ fontWeight: 700 }}>
                            {userForm?.userDetails?.name}
                          </Typography>
                          <Typography
                            style={{ fontWeight: 400, fontSize: "14px" }}
                          >
                            {userForm?.userDetails?.email}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            style={{ fontSize: "14px" }}
                          >
                            {userForm?.userDetails?.mobileNo} |{" "}
                            {userForm?.userDetails?.category}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        mt={2}
                      >
                        Address
                      </Typography>
                      <Typography
                        style={{ fontFamily: "Segoe UI", fontSize: "14px" }}
                      >
                        {userForm?.userDetails?.fragmentedAddress}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Reported By Section */}
                <Grid item xs={12} md={6}>
                  <Typography className={classes.heading}>
                    Reported Person
                  </Typography>
                  <Card variant="outlined">
                    <CardContent>
                      <Box display="flex" alignItems="center" mr={1}>
                        <Avatar
                          src="https://via.placeholder.com/150"
                          alt="Sanjay Agarwal"
                          sx={{ width: 88, height: 88, marginRight: 2 }}
                        />
                        <Box style={{ padding: "10px" }}>
                          <Typography style={{ fontWeight: 700 }}>
                            {userForm?.reportedDetails?.name}
                          </Typography>
                          <Typography
                            style={{ fontWeight: 400, fontSize: "14px" }}
                          >
                            {userForm?.reportedDetails?.email}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            style={{ fontSize: "14px" }}
                          >
                            {userForm?.reportedDetails?.mobileNo} |{" "}
                            {userForm?.reportedDetails?.category}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        mt={2}
                      >
                        Address
                      </Typography>
                      <Typography
                        style={{ fontFamily: "Segoe UI", fontSize: "14px" }}
                      >
                        {userForm?.reportedDetails?.fragmentedAddress}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Remarks Section */}
                <Grid item xs={12} md={6}>
                  {/* <Card variant="outlined">
                    <CardContent>
                      <Typography
                        variant="subtitle2"
                        fontWeight="bold"
                        color="textSecondary"
                      >
                        Remarks
                      </Typography>
                      <Typography mt={2}>
                        He is making threats and engaging in harmful behavior
                        that’s why I’m reporting him.
                      </Typography>
                    </CardContent>
                  </Card> */}
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="textSecondary"
                  >
                    Remarks
                  </Typography>
                  <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    multiline
                    rows={3}
                    // label={props.t("Remarks")}
                    autoComplete="something-unsupported"
                    value={userForm?.description}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    // onChange={(event) =>
                    //   setUserForm({
                    //     ...userForm,
                    //     name: event.target.value,
                    //   })
                    // }
                    disabled={true}
                  />
                </Grid>

                {/* Photos Section */}
                {userForm?.reportImage && (
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="subtitle2"
                      fontWeight="bold"
                      color="textSecondary"
                    >
                      Photos
                    </Typography>
                    <Card variant="outlined" style={{ borderRadius: "10px" }}>
                      <CardContent>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          mt={1}
                          p={1}
                          sx={{
                            background: "#FFFFFF",
                            borderRadius: "10px",
                            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <Box display="flex" alignItems="center" gap={1}>
                            <Image style={{ color: "#FF8C00" }} />
                            <Typography fontWeight="500">
                              Reported Image
                            </Typography>
                          </Box>
                          <Button
                            style={{
                              // background: "#FF8C00",
                              color: "#FF8C00",
                              border: "none",
                              // borderRadius: "50%",
                              // minWidth: "40px",
                              // height: "40px",
                              boxShadow: "none",
                              // "&:hover": {
                              //   background: "#FF8C00",
                              // },
                            }}
                          >
                            <Visibility
                              onClick={() => {
                                window.open(userForm?.reportImage, "_blank");
                              }}
                            />
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Box>
            <br />
            <hr />
            <br />
            {listLogs?.length > 0
              ? listLogs?.map((val, ind) => (
                  <LogMessage
                    message={val?.sendMessage}
                    userName={val?.createdBy?.name}
                    time={val?.createdAt}
                    isShowLast={listLogs?.length - 1 === ind}
                  />
                ))
              : "No logs present"}

            <ChatInput
              createLogMessage={createLogMessage}
              recordId={selectedRowId}
              listLogMessages={listLogMessages}
              moduleType="reports"
              createdFor={createdFor}
            />
          </>
        </Stack>
      </Stack>
      <SlideInDialog
        // title={props.t("Delete Post")}
        contentComponent={props.t(
          `Are you sure you want to ${
            selectedButton === "rejected" ? "Reject" : "Approve"
          }`
        )}
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus="ok"
        slideInDialog={isOpen}
        setSlideInDialog={setIsOpen}
        handleDeleteRequest={handleRejectApprove}
        enquires={true}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AddUser)
);
