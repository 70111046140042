import {
  Box,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Button, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import Useupload from "./Useupload";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";
import APIRequest from "../../../../../utils/APIRequest";
import { DataContext } from "./MyloomsIndex";
import Dialog from "@material-ui/core/Dialog";
import useLoomHook from "./useLoomHook";
import { Close } from "@material-ui/icons";

const Create = (props) => {
  const {
    open,
    handleClose,
    recordId,
    createData,
    setCreateData,
    sendToServer,
  } = props;
  const { setUserForm, userForm, userId, showNotification } =
    React.useContext(DataContext);

  const handleInputChange = (field, value) => {
    setCreateData({
      ...createData,
      [field]: value,
    });
  };

  const validationCheck = () => {
    if (
      createData?.loomRegistrationNo === "" ||
      createData?.loomNo === "" ||
      createData?.loomType === "" ||
      !createData?.images ||
      !Array.isArray(createData?.images) ||
      createData?.images.length === 0
    ) {
      SnackbarUtils.error(
        "Please fill all the required fields!",
        "bottomCenter",
        3000
      ).then((notification) => {
        showNotification(notification);
      });
      return false;
    } else {
      sendToServer(recordId);
      return true;
    }
  };

  useEffect(() => {
    if (props.activeTab === "create") {
      setCreateData({
        loomRegistrationNo: "",
        loomNo: "",
        loomType: "",
        description: "",
        images: [],
      });
    }
  }, [props.activeTab === "create"]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Stack style={{ background: "white", padding: "20px" }}>
          <Stack
            style={{
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "column",
                sm: "column",
              },
              background: "white",
              gap: "10px",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ marginTop: "7px", padding: "20px" }}
              >
                <TextField
                  id="serviceNo"
                  label="Service Number"
                  variant="outlined"
                  required
                  fullWidth
                  value={createData?.loomRegistrationNo}
                  onChange={(event) =>
                    handleInputChange("loomRegistrationNo", event.target.value)
                  }
                  disabled={true}
                />
              </Grid>

              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: "20px", marginTop: "10px" }}
              >
                <TextField
                  variant="outlined"
                  id="loomNo"
                  required
                  label="Loom Number"
                  fullWidth
                  value={createData?.loomNo}
                  onChange={(event) =>
                    handleInputChange("loomNo", event.target.value)
                  }
                />
              </Grid>
              <Grid
                itema
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: "20px", marginTop: "10px" }}
              >
                <TextField
                  variant="outlined"
                  id="loomType"
                  required
                  label="Loom Type"
                  fullWidth
                  value={createData?.loomType}
                  placeholder="handloom"
                  onChange={(event) =>
                    handleInputChange("loomType", event.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack
            style={{
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "column",
                sm: "column",
              },
              background: "white",
              gap: "10px",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "7px", padding: "20px" }}
              >
                <Useupload
                  userForm={userForm}
                  setUserForm={setUserForm}
                  handleInputChange={handleInputChange}
                  createData={createData}
                  setCreateData={setCreateData}
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack
            style={{
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "column",
                sm: "column",
              },
              background: "white",
              gap: "10px",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "7px", padding: "20px" }}
              >
                <TextField
                  id="notes"
                  variant="outlined"
                  placeholder="Notes"
                  label="Notes"
                  style={{
                    width: "100%",
                    height: "100px",
                    padding: "10px",
                    resize: "vertical",
                    wordWrap: "break-word",
                  }}
                  value={createData?.description}
                  onChange={(event) =>
                    handleInputChange("description", event.target.value)
                  }
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack
            style={{
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "column",
                sm: "column",
              },
              background: "white",
              gap: "10px",
            }}
          >
            <Grid container spacing={2} justifyContent={"center"} mt={3}>
              <Button
                style={{
                  width: "Hug (138px)px",
                  height: "Hug (56px)px",
                  padding: "16px 36px 16px 36px",
                  gap: "8px",
                  borderRadius: "21px",
                  opacity: "0px",
                  background: "#FF8D00",
                  color: "#FFFFFF",
                }}
                onClick={() => validationCheck()}
              >
                <Typography style={{ fontFamily: "Lato" }}>Update</Typography>
              </Button>
            </Grid>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default Create;
