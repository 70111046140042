import DateFnsUtils from "@date-io/date-fns";
import { Grid, makeStyles, MenuItem, TextField } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import { withTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  innerPadding: {
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function TableQuery(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container className={classes.innerPadding}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label={props.t("query.activeStatus")}
            select
            defaultValue={props.query.active}
            value={props.query.active}
            onChange={(event) =>
              props.setQuery({ ...props.query, active: event.target.value })
            }
            style={{
              width: "100%",
              marginBottom: 10,
              color: "#000 !important",
            }}
          >
            <MenuItem key={0} value={true}>
              {props.t("role.active")}
            </MenuItem>
            <MenuItem key={1} value={false}>
              {props.t("role.inactive")}
            </MenuItem>
          </TextField>
        </Grid>

        <TextField
          label={props.t("Live Status")}
          select
          // defaultValue={props.query.liveStatus}
          value={props.query.liveStatus}
          onChange={(event) =>
            props.setQuery({
              ...props.query,
              liveStatus: event.target.value,
            })
          }
          style={{ width: "100%", marginBottom: 10 }}
        >
          <MenuItem key={1} value={"live"}>
            {"Live"}
          </MenuItem>
          <MenuItem key={2} value={"expired"}>
            {"Expired"}
          </MenuItem>
        </TextField>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="startDate"
                fullWidth
                maxDate={
                  props.query.endDate !== null
                    ? new Date(props.query.endDate * 1000)
                    : new Date()
                }
                label={props.t("query.startFrom")}
                format="dd/MM/yyyy"
                value={
                  props.query.startDate !== null
                    ? new Date(props.query.startDate * 1000)
                    : null
                }
                style={{
                  width: "100%",
                  marginBottom: 10,
                }}
                onChange={(date) =>
                  props.setQuery({
                    ...props.query,
                    startDate: Math.floor(
                      new Date(
                        new Date(new Date(date).setHours(0)).setMinutes(0)
                      ).setSeconds(0) / 1000
                    ),
                  })
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12} className="querydate">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="endDate"
                fullWidth
                minDate={
                  props.query.startDate !== null
                    ? new Date(props.query.startDate * 1000)
                    : new Date(0)
                }
                maxDate={new Date()}
                label={props.t("query.endBefore")}
                format="dd/MM/yyyy"
                value={
                  props.query.endDate !== null
                    ? new Date(props.query.endDate * 1000)
                    : null
                }
                onChange={(date) =>
                  props.setQuery({
                    ...props.query,
                    endDate: Math.floor(
                      new Date(
                        new Date(new Date(date).setHours(23)).setMinutes(59)
                      ).setSeconds(59) / 1000
                    ),
                  })
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
export default withTranslation("translations")(TableQuery);
