import { useState } from "react";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import APIRequest from "../../../../../utils/APIRequest";

const useUpload = (handleMedia) => {
  const [fileUploading, setFileUploading] = useState(false);
  const mediaUploadPart = (media) => {
    media &&
      media.forEach((value) => {
        const type = (value && value.split(".").pop()) || "";
        const name = (value && value.split("/").pop()) || "";

        const data = {
          type,
          name,
          url: value || "",
        };
        handleMedia(data);
      });
  };
  const fileUpload = async (e) => {
    try {
      const formdata = new FormData();
      const files = e.target.files;

      if (files) {
        files.forEach((file) => {
          formdata.append("attachment", file);
          formdata.append("bucketName", "files");
        });

        setFileUploading(true);

        const response = await APIRequest.multipartForDataRequest(
          "PUT",
          ConfigAPIURL.uploadFile,
          formdata
        );

        if (response?.data?.responseCode === 109) {
          mediaUploadPart(response?.data?.attachmentUrl); // successfully uploaded as array
        } else {
          return false; // an error occurred
        }
      }
    } catch (error) {
      console.error("File upload failed:", error);
    } finally {
      setFileUploading(false); // Ensure that uploading state is reset, regardless of success or failure
    }
  };

  return { fileUpload, fileUploading };
};

export default useUpload;
