import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import SideBarMenu from "./SideBarMenu";
import { withTranslation } from "react-i18next";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";
import Collapse from "@material-ui/core/Collapse";
import { Link } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import LocalStorage from "../../../../config/LocalStorage";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
// import APIRequest from '../../utils/APIRequest';
// import ConfigAPIURL from '../../config/ConfigAPIURL';
const drawerWidth = 250;
const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
      fontFamily: "Lato",
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: "Lato",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
      fontFamily: "Lato",
    },
  },
  drawerDrawerClose: {
    flexShrink: 0,
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      fontFamily: "Lato",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
      fontFamily: "Lato",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    boxShadow: theme.custom.sideDrawer.boxShadow,
    backdropFilter: theme.custom.sideDrawer.backdropFilter,
    // backgroundColor: theme.custom.sideDrawer.backgroundColor,
    backgroundColor: "#FFFFFF",
    fontFamily: "Lato",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    fontFamily: "Lato",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontFamily: "Lato",
  },
  companyName: {
    color: "black",
    fontFamily: "Lato",
    fontSize: "16px",
    alignItems: "center",
    fontWeight: "700",
    justifyContent: "center",
    textDecoration: "none",
    background: "transparent",
  },
  textAlignCenter: {},
  userImage: {
    width: "70px",
    height: "70px",
    fontFamily: "Lato",
    background: "#17024B",
  },
  userInfoBox: {
    background: "transparent",
    textAlign: "center",
    fontFamily: "Lato",
  },
  userName: {
    color: "black",
    fontSize: "18px",
    alignItems: "center",
    fontWeight: "600",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: "Lato",
  },
  nested: {
    paddingLeft: theme.spacing(5),
    fontFamily: "Lato",
  },
  menuLink: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Lato",
  },
  textLimit: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "Lato",
    overflow: "hidden",
  },
  menuStyle: {
    color: "black",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontFamily: "Lato",
  },
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [values, setMenuStatus] = React.useState({});
  const [profile, setProfile] = useState([]);
  const location = useLocation();
  useEffect(() => {
    let menuStatus = values;
    SideBarMenu.menu.map((data) => {
      if (data.display !== undefined && data.display !== null) {
        data.subMenu.map((subData) => {
          if (subData.link === location.pathname) {
            props.callback(subData.display);
          }
          return (menuStatus[subData.eventKey] =
            menuStatus[subData.eventKey] !== undefined &&
            menuStatus[subData.eventKey] !== null
              ? menuStatus[subData.eventKey]
              : false);
        });
        if (data.link === location.pathname) {
          props.callback(data.display);
        }
        return (menuStatus[data.eventKey] =
          menuStatus[data.eventKey] !== undefined &&
          menuStatus[data.eventKey] !== null
            ? menuStatus[data.eventKey]
            : false);
      }
      return null;
    });
    setMenuStatus(menuStatus);
  }, [location]);
  const Login = () => {
    APIRequest.request("GET", ConfigAPIURL.sessionValidation, "").then(
      (response) => {
        let userResult = response?.data?.user;
        setProfile(userResult);
        // console.log(userResult,"response")
        // console.log(response,"response")
      }
    );
  };
  useEffect(() => {
    Login();
  }, []);
  const history = useHistory();
  const path = history.location.pathname;

  const CompanyUserInfo = () => {
    return (
      <div className={classes.toolbar}>
        {/* Company Name & Icon */}
        <Box
          m={1}
          bgcolor="background.paper"
          p={1}
          style={{ display: "flex", fontFamily: "Lato" }}
          className={classes.companyName}
        >
          <Avatar
            alt="Nekaaramitra"
            src="https://nekaaramitra.s3.ap-south-1.amazonaws.com/advertisement/Nekaaramitra1675835227.png"
            m={3}
            className={classes.small}
          />
          <Box
            component="span"
            color="text.primary"
            ml={1}
            className={classes.companyName}
          >
            Nekaaramitra
          </Box>
        </Box>
        {/* User Name & Icon */}
        <Box bgcolor="background.paper" p={1} className={classes.userInfoBox}>
          <StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            <Avatar
              className={classes.userImage}
              alt="User Logo"
              src={
                LocalStorage.userDetails.profileImage !== undefined &&
                LocalStorage.userDetails.profileImage !== null &&
                LocalStorage.userDetails.profileImage !== ""
                  ? LocalStorage.userDetails.profileImage
                  : "https://nekaaramitra.s3.ap-south-1.amazonaws.com/advertisement/Nekaaramitra1675835227.png"
              }
            />
          </StyledBadge>
        </Box>
        <Box p={1} className={classes.userInfoBox}>
          <Box
            component="span"
            // color='text.primary'
            ml={1}
            className={classes.userName}
          >
            {/* <Link href='#/admin/profile'> */}
            {/* {LocalStorage.userDetails.name !== undefined &&
              LocalStorage.userDetails.name !== null &&
              LocalStorage.userDetails.name !== ''
                ? LocalStorage.userDetails.name
                : LocalStorage.userDetails.email !== undefined &&
                  LocalStorage.userDetails.email !== null &&
                  LocalStorage.userDetails.email !== ''
                ? LocalStorage.userDetails.email
                : LocalStorage.userDetails.mobileNo !== undefined &&
                  LocalStorage.userDetails.mobileNo !== null &&
                  LocalStorage.userDetails.mobileNo !== ''
                ? LocalStorage.userDetails.mobileNo
                : 'Nekaaramitra'} */}
            {profile.name !== undefined &&
            profile.name !== null &&
            profile.name !== ""
              ? profile.name
              : profile.email !== undefined &&
                profile.email !== null &&
                profile.email !== ""
              ? profile.email
              : profile.mobileNo !== undefined &&
                profile.mobileNo !== null &&
                profile.mobileNo !== ""
              ? profile.mobileNo
              : "Nekaaramitra"}

            {/* </Link> */}
            {/* {console.log( LocalStorage.userDetails.name,"name")} */}
          </Box>
        </Box>
      </div>
    );
  };
  const handleMenu = (eventKey) => {
    if (eventKey !== undefined && eventKey !== null) {
      setMenuStatus({
        ...values,
        [eventKey]:
          values !== undefined && values !== null
            ? values[eventKey] !== undefined && values[eventKey] !== null
              ? !values[eventKey]
              : false
            : false,
      });
    }
  };
  const SubMenu = (menu) => {
    return menu.subMenu.map((subMenu, subMenuIndex) =>
      LocalStorage.maintenanceOptions.includes(subMenu.permissionLevel) ||
      subMenu.permissionLevel === "" ? (
        <Collapse
          in={
            values !== undefined && values !== null
              ? values[menu.eventKey] !== undefined &&
                values[menu.eventKey] !== null
                ? values[menu.eventKey]
                : false
              : false
          }
          timeout="auto"
          unmountOnExit
          key={subMenuIndex}
        >
          <List component="div" disablePadding>
            <Link
              href={"#" + subMenu.link}
              underline="none"
              className={classes.menuLink}
            >
              <ListItem
                button
                selected={location.pathname === subMenu.link ? true : false}
                className={classes.nested}
              >
                <ListItemIcon>
                  {" "}
                  {subMenu?.link === path
                    ? subMenu?.filledIcon
                    : subMenu?.regularIcon}
                </ListItemIcon>
                <Tooltip title={props.t(subMenu.display)}>
                  <ListItemText
                    className={classes.menuStyle}
                    primary={props.t(subMenu.display)}
                    style={{ color: subMenu?.link === path && "#FF8D00" }}
                  />
                </Tooltip>
              </ListItem>
            </Link>
          </List>
        </Collapse>
      ) : null
    );
  };
  const MainMenu = (menu) =>
    menu.subMenu.length === 0 &&
    (LocalStorage.maintenanceOptions.includes(menu.permissionLevel) ||
      menu.permissionLevel === "") ? (
      <Link
        href={"#" + menu.link}
        underline="none"
        className={classes.menuLink}
      >
        <ListItem
          button
          key={menu.eventKey}
          selected={location.pathname === menu.link ? true : false}
        >
          <ListItemIcon>
            {menu?.link === path ? menu?.filledIcon : menu?.regularIcon}
          </ListItemIcon>
          <Tooltip title={props.t(menu.display)}>
            <ListItemText
              className={classes.menuStyle}
              primary={props.t(menu.display)}
              style={{ color: menu?.link === path && "#FF8D00" }}
            />
          </Tooltip>
        </ListItem>
      </Link>
    ) : menu.subMenu.length > 0 ? (
      <div>
        <ListItem button onClick={() => handleMenu(menu.eventKey)}>
          <ListItemIcon style={{ color: "white", fontFamily: "Lato" }}>
            {menu?.link === path ? menu?.filledIcon : menu?.regularIcon}
          </ListItemIcon>
          <Tooltip title={props.t(menu.display)}>
            <ListItemText
              primaryTypographyProps={{
                className: classes.menuStyle,
              }}
              primary={props.t(menu.display)}
            />
          </Tooltip>
          {values !== undefined && values !== null ? (
            values[menu.eventKey] !== undefined &&
            values[menu.eventKey] !== null ? (
              values[menu.eventKey] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : (
              <ExpandMore />
            )
          ) : <ExpandMore /> ? (
            <ExpandMore />
          ) : (
            <ExpandMore />
          )}
        </ListItem>
        <SubMenu {...menu} />
      </div>
    ) : null;
  const DrawerMenu = () => {
    return (
      <div>
        <CompanyUserInfo />
        {/* <Divider /> */}
        <List>
          {SideBarMenu.menu.map((menu, menuIndex) =>
            menu.display !== undefined && menu.display !== null ? (
              <MainMenu key={menuIndex} {...menu} />
            ) : (
              // <Divider key={menuIndex} />
              <></>
            )
          )}
        </List>
      </div>
    );
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <nav
      className={props.mobileOpen ? classes.drawerDrawerClose : classes.drawer}
      aria-label="mailbox folders"
    >
      <Hidden only={["xl", "lg", "md"]}>
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerMenu />
        </Drawer>
      </Hidden>
      <Hidden only={["sm", "xs"]}>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant={props.mobileOpen ? "temporary" : "permanent"}
          open={!props.mobileOpen}
        >
          <DrawerMenu />
        </Drawer>
      </Hidden>
    </nav>
  );
}
ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};
export default withTranslation("translations")(ResponsiveDrawer);
