import { Grid, Typography } from "@material-ui/core";
import { Avatar, Stack } from "@mui/material";
import React from "react";
import StringUtils from "../../../../../utils/StringUtils";

export default function TopBar({ rowDetails, markAsResolve, openForm }) {
  const { requestedBy, status, complaintId } = rowDetails; //_id: complaintId
  return (
    <Grid item sm={12} md={12}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        style={{ background: "#FFF5E9" }}
        alignContent={"center"}
      >
        <Stack direction={"row"} spacing={1} padding={"8px"}>
          <Avatar style={{ background: "#17024B", color: "#fff" }}>
            {requestedBy && requestedBy?.name?.charAt(0).toUpperCase()}
          </Avatar>
          <Stack direction={"column"} alignItems="flexStart">
            <Typography variant="subtitle1" fontWeight={"700"}>
              {StringUtils.capitalize(requestedBy?.name)}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {requestedBy?.mobileNo}
            </Typography>
          </Stack>
        </Stack>
        {openForm?.divType !== "chat" && (
          <Stack>
            <button
              style={{
                height: "40px",
                width: "156px",
                background: "#FF8D00",
                color: "white",
                padding: "10px, 24px, 10px, 24px",
                border: "none",
                marginRight: "4px",
                marginTop: "10px",
                cursor:
                  complaintId?.status === "pending" ? "pointer" : "not-allowed",
              }}
              disabled={complaintId?.status === "pending" ? false : true}
              onClick={() => {
                markAsResolve(complaintId?._id);
              }}
            >
              <Typography>
                {complaintId?.status !== "pending"
                  ? StringUtils.capitalize(complaintId?.status)
                  : "Mark as Resolved"}
              </Typography>
            </button>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
}
