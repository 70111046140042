import {
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // minWidth: 250,
  },
  innerPadding: {
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function TableQuery(props) {
  const classes = useStyles();
  const [specializationOptions, setSpecializationOptions] = useState([]);

  // useEffect(() => {
  //   APIRequest.request("GET", ConfigAPIURL.classifiedsget).then((response) => {
  //     console.log(response);
  //     if (response !== undefined && response !== null) {
  //       if (response.code === 100 && response.data.responseCode === 109) {
  //         setSpecializationOptions(response.data.result);
  //       }
  //     }
  //   });
  // }, []);
  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Grid container className={classes.innerPadding}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label={props.t("query.activeStatus")}
              select
              defaultValue={props.query.active}
              value={props.query.active}
              onChange={(event) =>
                props.setQuery({ ...props.query, active: event.target.value })
              }
              style={{ width: "100%", marginBottom: 10 }}
            >
              <MenuItem key={0} value={true}>
                {props.t("query.active")}
              </MenuItem>
              <MenuItem key={1} value={false}>
                {props.t("query.inActive")}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label={props.t("Sector")}
              select
              defaultValue={props.query.sector}
              value={props.query.sector}
              onChange={(event) =>
                props.setQuery({ ...props.query, sector: event.target.value })
              }
              style={{ width: "100%", marginBottom: 10 }}
            >
              <MenuItem key={0} value="powerloom">
                {props.t("Powerloom")}
              </MenuItem>
              <MenuItem key={1} value="handloom">
                {props.t("Handloom")}
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="startDate"
                  fullWidth
                  maxDate={
                    props.query.endDate !== null
                      ? new Date(props.query.endDate * 1000)
                      : new Date()
                  }
                  label={props.t("query.startFrom")}
                  format="dd/MM/yyyy"
                  defaultValue={
                    props.query.startDate !== null
                      ? new Date(props.query.startDate * 1000)
                      : null
                  }
                  value={
                    props.query.startDate !== null
                      ? new Date(props.query.startDate * 1000)
                      : null
                  }
                  onChange={(date) =>
                    props.setQuery({
                      ...props.query,
                      startDate: Math.floor(
                        new Date(
                          new Date(new Date(date).setHours(0)).setMinutes(0)
                        ).setSeconds(0) / 1000
                      ),
                    })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  style={{ width: "100%", marginBottom: 10 }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="endDate"
                  fullWidth
                  minDate={
                    props.query.startDate !== null
                      ? new Date(props.query.startDate * 1000)
                      : new Date(0)
                  }
                  maxDate={new Date()}
                  label={props.t("query.endBefore")}
                  format="dd/MM/yyyy"
                  defaultValue={
                    props.query.endDate !== null
                      ? new Date(props.query.endDate * 1000)
                      : null
                  }
                  value={
                    props.query.endDate !== null
                      ? new Date(props.query.endDate * 1000)
                      : null
                  }
                  onChange={(date) =>
                    props.setQuery({
                      ...props.query,
                      endDate: Math.floor(
                        new Date(
                          new Date(new Date(date).setHours(23)).setMinutes(59)
                        ).setSeconds(59) / 1000
                      ),
                    })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
export default withTranslation("translations")(TableQuery);
