/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import SlideInDialog from "../../../../templates/dialog/SlideInDialog";
import "./style.css";
import TableQuery from "./TableQuery";
import TableHeader from "./TableHeader";
import DataTable from "../../../../templates/tables/DataTable";
import LocalStorage from "../../../../config/LocalStorage";
import TableButtons from "../../../../templates/buttons/TableButtons";
import ChatArea from "./chatArea/Index";
import { connect } from "react-redux";
import FormDialog from "../../../../templates/dialog/FormDialog";
import { Container, Stack } from "@mui/material";
import useChat from "./hooks/useChat";
import useTableFuntion from "./hooks/useTableFuntion";
import { Avatar } from "@mui/material";
import View from "./components/View";
import TopBar from "./components/TopBar";
export const DataContext = React.createContext();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px%",
  },
}));

function CustomerService(props) {
  const userDetails = LocalStorage.userDetails;
  const userData = { ...userDetails };

  //here we are using custom hook for table
  const {
    tableData,
    query,
    buttonList,
    openForm,
    handleQuery,
    resetQuery,
    tableCallBack,
    tableDBLCallBack,
    tableAdd,
    tableEdit,
    tableView,
    tableDelete,
    tableUpload,
    tableApprove,
    tableReject,
    tableAssign,
    tableChat,
    handleFormDialog,
    submit,
    resetForm,
    rowDetails,
    setRowDetails,
    slideInDialog,
    handleSlideInDialog,
    handleDeleteRequest,
    markAsResolve,
    complaintDetails,
  } = useTableFuntion(props);

  //here we are using custom hook functions for chat

  console.log("rowDetails", rowDetails);
  // const {
  //   handleConnect,
  //   sideChatList,
  //   sendMessage,
  //   fetchMessages,
  //   conversation,
  //   calculateMinutes,
  //   selectedChat,
  //   hasMoreConverstion,
  //   handleSearchList,
  //   setSelectedChat,
  // } = useChat({ userData, rowDetails, handleFormDialog, props });
  useEffect(() => {
    // if any value found state location then that is the reviever
    if (!location?.state) return;
    setRowDetails({
      ...location?.state,
    });
  }, []);
  const classes = useStyles();

  const location = useLocation();

  return (
    <DataContext.Provider
      value={{
        // handleConnect,
        // sideChatList,
        // selectedChat,
        userData,
        // sendMessage,
        // fetchMessages,
        // conversation,
        // calculateMinutes,
        // hasMoreConverstion,
        // handleSearchList,
        rowDetails,
        handleFormDialog,
        props,
      }}
    >
      <div className={classes.root}>
        <Grid container>
          <Grid
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ textTransform: "capitalize" }}
          >
            <DataTable
              tableHeader={TableHeader.adminTableHeader} // table column header
              tableData={tableData} // pass table record
              query={query} // pass query condition
              setQuery={handleQuery} // update query fields
              resetQuery={resetQuery} // reset filter menu values
              selectAll={false} //select all check box props
              openForm={openForm} //un select all check box props
              tableCallBack={tableCallBack}
              searchLabel={"Search by Name,Mobile Number"}
              tableDBLCallBack={tableDBLCallBack}
              queryComponent={
                <TableQuery query={query} setQuery={handleQuery} />
              } //filter menu option
              buttonComponent={
                <TableButtons
                  tableAdd={tableAdd}
                  tableEdit={tableEdit}
                  tableView={tableView}
                  tableDelete={tableDelete}
                  tableUpload={tableUpload}
                  tableApprove={tableApprove}
                  tableReject={tableReject}
                  tableAssign={tableAssign}
                  tableChat={tableChat}
                  buttonList={buttonList}
                />
              }
            />
          </Grid>
        </Grid>
        <FormDialog
          sideDrawerData={props.sideDrawerData}
          formDialog={openForm}
          handleFormDialog={handleFormDialog}
          submit={submit}
          resetForm={resetForm}
          formComponent={
            <>
              {openForm?.divType === "view" && (
                <View
                  complaintDetails={complaintDetails}
                  markAsResolve={markAsResolve}
                />
              )}
              {openForm?.divType === "chat" && (
                <Container className="chat__container" style={{ padding: 0 }}>
                  <Grid
                    container
                    className="chat__gridcontainer"
                    style={{ height: "100%", marginTop: "4rem" }}
                  >
                    <TopBar rowDetails={rowDetails} openForm={openForm} />
                    <Grid item sm={12} md={12} xs={12} xl={12}>
                      <ChatArea />
                    </Grid>
                  </Grid>
                </Container>
              )}
            </>
          }
        />
      </div>
      <SlideInDialog
        title={"Delete Complaint ?"}
        contentComponent={props.t("dialog.delete")}
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus={true}
        slideInDialog={slideInDialog}
        setSlideInDialog={handleSlideInDialog}
        handleDeleteRequest={handleDeleteRequest}
      />
    </DataContext.Provider>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(CustomerService)
);
