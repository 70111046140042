import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import { DateConvert } from "../../../../../utils/DataConvert";
import ObjectUtils from "../../../../../utils/ObjectUtils";
import { useHistory } from "react-router-dom";

let socket;
let currentValue = null;
const coversationPageLength = 25;
const chatPageLength = 1000;

const useChat = ({ userData, rowDetails, handleFormDialog, props }) => {
  const [isConnected, setIsConnected] = useState(socket?.connected);
  const [sideChatList, setSideChatList] = useState([]);
  const [conversation, setConversation] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [hasMoreConverstion, setHasMoreConversation] = useState(true);
  console.log("rowDetails", rowDetails);

  //below we are writting funtion for mark as resolve for chat

  const handlReadMessage = async () => {
    console.log("read messages");
    // const userId = selectedChat?._id;
    const receiverId = rowDetails?.requestedBy?._id;
    await readMessages(receiverId);
  };

  const handleSelectedChat = async (rowDetails) => {
    console.log("select chaa");
    if (!rowDetails) return;
    // resetting everything like clearing the converstion once we select another chat
    await new Promise((res, rej) => {
      res(setConversation([]), setHasMoreConversation(true)); //resetting conversation
    });
    // reset page
    fetchMessages(rowDetails?.requestedBy?._id);
    await handlReadMessage();
    currentValue = rowDetails;
    // fetchChatsList(userData?._id);
  };
  useEffect(() => {
    console.log("select chat");
    handleSelectedChat(rowDetails);
    //whenever the selected chat is selected then we need to get the whole chat of specific user
  }, [rowDetails]);

  // helps to diconnect from socket io
  const handleDisconnect = () => {
    socket.disconnect();
  };

  // helps to connect to socket io
  const handleConnect = async (senderId = "") => {
    console.log("senderId", senderId);
    // if (!senderId) return;
    socket = io(
      ConfigAPIURL.baseUrl, // comment this in production  socketUrl
      // window.location.origin, // uncomment this in  production
      {
        autoConnect: true,
        query: { senderId }, // make it dynamic from userData
      }
    );
  };

  // once the user gets an event on connect
  async function onConnect() {
    await new Promise((res, rej) => {
      res(setIsConnected(true));
    });
    // fetchChatsList(userData?._id);
  }

  // once the user gets an event on disconnect
  function onDisconnect() {
    setIsConnected(false);
  }

  const convertMessages = (result) => {
    // console.log(result, "from result");
    let messages = [];
    console.log(result, "result");
    if (result) {
      result.forEach((outerValue) => {
        // Push complaintFormData
        // if (outerValue && outerValue.complaintFormData) {
        //   const formDataObject = {
        //     msg: outerValue.complaintFormData.reason,
        //     time: outerValue.complaintFormData.createdAt,
        //     isRead: true, // Assuming complaintFormData is always read
        //     isMedia: false,
        //     media: "",
        //     sendUserId: outerValue.complaintFormData.requestedBy,
        //   };
        //   messages.push(formDataObject);
        // }

        // Push complaintWiseMsgs
        if (outerValue && outerValue.complaintWiseMsgs) {
          outerValue.complaintWiseMsgs.forEach((innerValue) => {
            const messageObject = {
              msg: innerValue.message,
              time: innerValue.createdAt,
              isRead: innerValue.isRead,
              isMedia: innerValue.message ? false : true,
              media: innerValue.attachment || "",
              sendUserId: innerValue.sentBy,
            };
            messages.push(messageObject);
          });
        }
      });
    }

    return messages;
  };

  // list of  the conversation with specifc user
  const readMessages = async (receiverId) => {
    console.log("rendering"); // *

    if (!receiverId || !socket) return;
    return socket.emit("getAllComplaintInteractionWithUser", {
      receiverId,
    });
  };

  // coverstion of specific user
  const getConverstions = (data) => {
    console.log(data, "data");
    const response = convertMessages(data);
    console.log(response, "response");
    // handlReadMessage(data);
    setConversation((p) => [...p, ...response]);
    if (data?.length === 0 || data?.length < coversationPageLength)
      return setHasMoreConversation(false);
    console.log(conversation, "conversation");
  };

  // fetches incoming msg from event
  const fetchIncomingMsg = (data) => {
    // console.log(data, "from incoming");
    // when the message is send we will get back the sended message as obj
    // firstly check the sender id is same  as the current value becuase sender we will be selected
    if (data) {
      const response = convertMessages([data]);
      // if its same then push it
      setConversation((p) => [...p, ...response]);
      handlReadMessage(data);
    }

    //once we send the message update the side bar
    // fetchChatsList(userData?._id);
  };

  // searchChat list fn
  const handleSearchList = (searchKey) => {
    // fetchChatsList(userData?._id, searchKey);
  };

  useEffect(() => {
    console.log(isConnected);
    (async () => {
      if (!isConnected) {
        await handleConnect(rowDetails?.requestedBy?._id);
        //once its connected then update the state
        await socket.on("connect", onConnect);
      }
    })();

    // firstly connects to socket if its not connected
    console.log(isConnected, socket, "socket");
    if (isConnected) {
      console.log(isConnected);
      socket.on("disconnect", onDisconnect);
      // getting converstions
      socket.on("getAllComplaintInteractionWithUser", getConverstions);
      // const receiverId = rowDetails?.requestedBy?._id;
      // socket.emit("getAllComplaintInteractionWithUser", {
      //   receiverId,
      // });
      // socket.on("fetchSupportChats", updateSideChatList);
      // socket.on("getAllComplaintInteractionWithUser", fetchIncomingMsg);
    }

    console.log(isConnected, "isConnected");
    return () => {
      // if (socket) {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      // socket.off("fetchSupportChats", updateSideChatList);
      // socket.off("getAllComplaintInteractionWithUser", getConverstions);
      socket.off("newMessageArrived", fetchIncomingMsg);
      // }
    };
  }, [isConnected, rowDetails]);

  const sendMessage = (receiverId, msg, attachment) => {
    console.log(userData?._id, receiverId, "iddd");
    const result = {
      senderId: userData?._id,
      sendingTo: receiverId,
      message: msg || "",
      attachment,
    };
    // if (socket) {
    socket.emit("createComplaintMsgforUser", result);
    // }

    const messageObject = {
      msg: msg || "",
      time: new Date().getTime() / 1000, // Set the current time
      isRead: false, // Assuming the message is not read immediately after sending
      isMedia: !msg, // If there is no text message, consider it as media
      media: attachment || "",
      profileImage: userData?.profileImage,
      sendUserId: userData?._id,
    };

    setConversation((prevConversation) => [...prevConversation, messageObject]);
  };

  // helps to fetch specific conversation with a user
  const fetchMessages = (
    receiverId,
    pageNo = 0,
    pageSize = coversationPageLength
  ) => {
    // if (socket) {
    socket.emit("newMessageArrived", {
      receiverId: receiverId,
      pageNo: pageNo,
      pageSize: pageSize,
    });
    // }
  };
  // calculate minutes,secs for displaying
  const calculateMinutes = (data) => {
    let duration = new Date() - new Date(data * 1000);
    duration /= 1000;

    // get seconds
    var seconds = Math.round(duration);
    var minutes = Math.floor(seconds / 60);
    if (minutes === 0) {
      return `now`;
    }
    if (minutes < 60) {
      return `${minutes}m`;
    } else if (minutes > 60 && minutes < 1440) {
      function display(a) {
        var hours = Math.trunc(a / 60);
        var minutes = a % 60;
        return hours + "h";
      }
      return display(minutes);
    } else if (minutes > 1440) {
      const value =
        DateConvert.toDaysMinutesSeconds(duration).slice(0, 1) + "d";
      return value;
    }
  };
  return {
    handleConnect,
    sideChatList,
    handleDisconnect,
    sendMessage,
    fetchMessages,
    conversation,
    calculateMinutes,
    selectedChat,
    setSelectedChat,
    hasMoreConverstion,
    handleSearchList,
  };
};

export default useChat;
