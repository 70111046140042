import React from "react";
import useImageUpload from "./useImageUpload";
import ReactDOM from "react-dom";

import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@mui/icons-material/Add";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";

import {
  Typography,
  Dialog,
  Box,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import pdf from "../../../../../assets/images/pdfIcon.png";
import { useState } from "react";
import Item from "antd/es/list/Item";
import { Delete20Regular } from "@fluentui/react-icons";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Stack } from "@fluentui/react";

const ImagesUpload = ({ setUserForm, userForm }) => {
  const [previewImageIndex, setPreviewImageIndex] = useState(null);
  const handlePreviewImage = (index) => {
    setPreviewImageIndex(index);
  };

  const handleClosePreview = () => {
    setPreviewImageIndex(null);
  };
  const { onUpload, deleteImages } = useImageUpload(
    setUserForm,
    userForm,
    handleClosePreview
  );
  // console.log(userForm);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewVideo, setPreviewVideo] = useState(null);
  const [openFileUrl, setOpenFileUrl] = useState(null);

  const Icon = () => {
    return <img src={pdf} alt="pdf" width={24} height={24} />;
  };

  const handlePreviewVideo = (video) => {
    setPreviewVideo(video.url || video);
    setPreviewImage(null);
  };

  const handleOpenFile = (attachment) => {
    setOpenFileUrl(attachment.url);
  };

  const handleDownloadFile = (attachment) => {
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(7);
    const fileName = `file_${timestamp}_${randomString}.pdf`;

    const link = document.createElement("a");
    link.href = attachment.url;
    link.download = fileName;
    link.click();
  };

  const handleClosePreview2 = () => {
    setOpenFileUrl(null);
  };

  const displayImage = userForm?.media?.[0];
  const handleOpenImageInNewTab = (imageUrl) => {
    const newTab = window.open("", "_blank");
    newTab.document.write(
      `<html><body style="margin: 0;"><img src="${imageUrl}" style="width: 100%; height: 100%; object-fit: contain;"></body></html>`
    );
  };

  // const openPDFINNewTab = (pdf) => {
  //   const newTab = window.open();
  //   newTab.document.write(`
  //     <html>
  //       <body style="margin: 0;">
  //         <iframe src=${pdf} width="100%" height="100%" style="border: none;">
  //           <p>Your browser does not support PDFs. <a href=${pdf}>Download the PDF</a></p>
  //         </iframe>
  //       </body>
  //     </html>
  //   `);
  // };

  const openPDFINNewTab = (pdf) => {
    window.open(pdf, "_blank");
  };

  return (
    <Stack style={{ gap: "4px", width: "100%" }}>
      {/* <Backdrop open={isUploading} />
      <Field className={classes.label} label={label}  required/> */}
      <Typography
        variant="subtitle1"
        style={{
          position: "relative",
          // top: "-25px",
          fontWeight: "bold",
          fontSize: "13px",
        }}
      >
        Uploads<span style={{ color: "red" }}>*</span>
      </Typography>
      <Stack
        horizontal
        horizontalAlign="space-between"
        style={{
          background: "white",
          padding: "16px",
          height: "142px",
          borderRadius: "8px",
          gap: "8px",
          border: "1px solid #E5E5E5",
          overflowX: "auto",
        }}
      >
        <Stack horizontal>
          {/* image array */}
          {userForm?.attachment?.length > 0 &&
            userForm?.attachment?.map(
              (value, i) =>
                value?.url && (
                  <Stack style={{ position: "relative" }}>
                    <Stack
                      verticalAlign="center"
                      horizontalAlign="center"
                      style={{
                        width: "28px",
                        height: "28px",
                        background: "white",
                        position: "absolute",
                        borderRadius: "100%",
                        top: -8,
                        right: -5,
                        cursor: "pointer",
                      }}
                    >
                      <Delete20Regular
                        onClick={() => deleteImages(i)}
                        className="deleteIcon"
                      />
                    </Stack>
                    {value.url.endsWith(".pdf") ? (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => window.open(value.url, "_blank")}
                      >
                        <img
                          // src={value.url}
                          src="https://cdn-icons-png.flaticon.com/512/337/337946.png"
                          style={{
                            width: "100px",
                            height: "100px",
                            border: "none",
                            cursor: "pointer",
                          }}
                          title="PDF Preview"
                        />
                      </div>
                    ) : value.url.endsWith(".doc") ||
                      value.url.endsWith(".docx") ? (
                      <img
                        style={{
                          borderRadius: "10px",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                        height={100}
                        width={100}
                        src="https://via.placeholder.com/100x100.png?text=DOC"
                        alt="document"
                        onClick={() => window.open(value.url, "_blank")}
                      />
                    ) : (
                      <img
                        style={{
                          borderRadius: "10px",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                        height={100}
                        width={100}
                        onClick={() => window.open(value.url, "_blank")}
                        src={value.url}
                        alt="clipperImage"
                      />
                    )}
                  </Stack>
                )
            )}

          {/* <img
                      style={{
                        borderRadius: "10px",
                        width: "117px",
                        height: "100%",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      key={i}
                      onClick={() => {
                        const newWindow = window.open("", "_blank");
                        if (newWindow) {
                          const imgElement = new Image();
                          imgElement.onload = function () {
                            newWindow.resizeTo(
                              1000,
                              (imgElement.height / imgElement.width) * 1000
                            );
                            imgElement.style.width = "1000px";
                            newWindow.document.body.appendChild(imgElement);
                          };
                          imgElement.src = value?.url;
                        } else {
                          console.error("Failed to open new window");
                        }
                      }}
                      src={value?.url}
                      alt="clipperImage"
                    /> */}
          {/* </Stack>
                )
              )} */}
        </Stack>

        <Stack>
          <Stack>
            <label htmlFor="file4">
              <CloudUploadOutlinedIcon style={{ cursor: "pointer" }} />
            </label>

            <input
              accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
              multiple
              id="file4"
              type="file"
              style={{ display: "none" }}
              onChange={onUpload}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ImagesUpload;
