import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Checkbox,
  Box,
  styled,
  TextareaAutosize,
} from "@material-ui/core";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { Stack } from "@mui/material";
import "./styles/user.scss";
import landingImg from "../../../../assets/images/Pic.png";
import DownloadIcon from "@mui/icons-material/Download";
import ModalReported from "./ModalReported";

const ReportedBy = (props) => {
  const { data, rowDetails } = props;
  const [reportedata, setReportedData] = useState([]);
  console.log(props);
  const Reported = () => {
    APIRequest.request(
      "GET",
      ConfigAPIURL.reportedby + "?userId=" + `${props?.rowDetails}`,
      ""
    ).then((response) => {
      let userResult = response?.data?.result;
      setReportedData(userResult);
      console.log(response);
    });
  };
  console.log(reportedata);
  useEffect(() => {
    Reported();
  }, []);
  return (
    <div>
      <Stack>
        <Stack style={{ backgroundColor: "#FFECD3", height: "62px" }}>
          <Stack style={{ paddingLeft: "20px", marginTop: "10px" }}>
            <Typography style={{ fontSize: "20px", fontWeight: "700" }}>
              Referred By
            </Typography>
          </Stack>
        </Stack>
        <Stack
          className="k1"
          style={{
            height: "170px",
            overflow: "auto",
          }}
        >
          {reportedata &&
            reportedata?.length > 0 &&
            reportedata?.map((option) => {
              return (
                <Stack className="k2">
                  <Stack
                    className="k3"
                    style={{
                      flexDirection: {
                        xl: "row",
                        lg: "row",
                        md: "column",
                        sm: "column",
                      },
                      padding: "20px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ padding: "12px" }}
                      >
                        <TextField
                          variant="outlined"
                          id="workingHours"
                          label={"Name"}
                          autoComplete="something-unsupported"
                          required
                          value={option?.userId?.name}
                          size="small"
                          className="textborder"

                          // disabled={
                          //   props.formDialog.divType === "new" ||
                          //   props.formDialog.divType === "edit"
                          //     ? false
                          //     : true
                          // }
                        />
                      </Grid>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ padding: "12px" }}
                      >
                        <TextField
                          variant="outlined"
                          id="experience"
                          label={"Category"}
                          autoComplete="something-unsupported"
                          required
                          value={option?.userId?.occupationType}
                          size="small"
                          className="textborder"

                          // disabled={
                          //   props.formDialog.divType === "new" ||
                          //   props.formDialog.divType === "edit"
                          //     ? false
                          //     : true
                          // }
                        />
                      </Grid>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ padding: "12px" }}
                      >
                        <TextField
                          variant="outlined"
                          id="street"
                          className="textborder"
                          size="small"
                          label={"Phone Number"}
                          autoComplete="something-unsupported"
                          required
                          value={option?.userId?.mobileNo}
                          fullWidth
                          // onChange={(event) =>
                          //   setUserForm({
                          //     ...userForm,
                          //     street: event.target.value,
                          //   })
                          // }
                          // disabled={
                          //   props.formDialog.divType === "new" ||
                          //   props.formDialog.divType === "edit"
                          //     ? false
                          //     : true
                          // }
                        />
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{ paddingLeft: "20px" }}
                      >
                        <TextareaAutosize
                          style={{
                            paddingBottom: "32px",
                            width: "100%",
                            paddingLeft: "10px",
                            border: "none",
                            boxShadow: "0px 1px 2px #c8c8c8",
                            borderRadius: "10px",
                          }}
                          size="small"
                          id="description"
                          aria-label="empty textarea"
                          placeholder="Empty"
                          value={option?.description}
                          // onChange={(event) =>
                          //   setUserForm({
                          //     ...userForm,
                          //     description: event.target.value,
                          //   })
                          // }
                          // style={{ width: "100%",border:"none",outline:"none" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{ paddingLeft: "20px" }}
                      >
                        <Stack
                          style={{
                            height: "48px",
                            // border:'0.1px solid rgba(0, 0, 0, 0.26)'
                          }}
                        >
                          <ModalReported reportedata={reportedata} />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                </Stack>
              );
            })}
        </Stack>
      </Stack>
    </div>
  );
};

export default ReportedBy;
