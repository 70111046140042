import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import APIRequest from "../../utils/APIRequest";
import Toaster from "../../utils/Toaster";
import ConfigAPIURL from "../../config/ConfigAPIURL";
import { Alert } from "@material-ui/lab";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import SlideInDialog from "../../templates/dialog/SlideInDialog";

import LocalStorage from "../../config/LocalStorage";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="/">
        Nekaaramitra
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: "no-repeat",
    backgroundColor: "#8BC6EC",
    backgroundImage: "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  loginGrid: {
    background:
      "linear-gradient(-45deg, rgb(200 207 212) 0%, rgb(200 208 215) 33%, rgb(195 244 238) 100%)",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "10px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textAlign: {
    textAlign: "end",
  },
}));

function Login(props) {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");

  const [disableOtpBtn, setDisableOtpBtn] = useState(false);
  const [slideInDialog, setSlideInDialog] = useState(false);

  const [otp, setOTP] = useState("");
  const [twoFactAut, setTowFactAuth] = useState(false);
  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });
  const notificationClose = (event) => {
    setSnakbarValues({ status: false, severity: "", message: "" });
  };
  useEffect(() => {
    isLogin();
  }, []);

  const handlePasswordChange = () => {
    if (mobileNo === "") {
      setSnakbarValues({
        status: true,
        severity: "error",
        message: "Please enter your mobile number",
      });
      return;
    }
    setSlideInDialog(true);
  };

  const handleSlideInDialog = () => {
    setSlideInDialog(false);
  };

  const handleDeleteRequest = (response) => {
    if (response === "agree") {
      APIRequest.request(
        "GET",
        ConfigAPIURL.forgotPassword + "?username=" + mobileNo,
        ""
      ).then((response) => {
        setSlideInDialog(false);
        if (response.code === 100 && response.data.responseCode === 101) {
          setSnakbarValues({
            status: true,
            severity: "info",
            message: "New password is generated and sent over the email",
          });
        } else if (
          response.code === 100 &&
          response.data.responseCode === 116
        ) {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "Exceeded password attempt",
          });
        } else if (
          response.code === 100 &&
          response.data.responseCode === 115
        ) {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "User login email is not verified",
          });
        } else {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "User email id not found / wrong email id /deactivated",
          });
        }
      });
    } else {
      setSlideInDialog(false);
    }
  };

  const submitMobileLogin = () => {
    if (mobileNo === "") {
      return Toaster.error("Enter your Mobile Number", "topRight");
    }
    if (password === "") {
      return Toaster.error("Enter your Password", "topRight");
    }
    APIRequest.request(
      "POST",
      ConfigAPIURL.accountLogin,
      JSON.stringify({
        email: mobileNo,
        password: password,
      })
    ).then((response) => {
      //redirect based on user type
      if (
        response.data.responseCode === 108 ||
        response.data.responseCode === 103
      ) {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Wrong Credentials",
        });
      } else if (response.data.responseCode === 116) {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Exceeded Password attempt",
        });
      } else if (
        response.data.responseCode === 109 ||
        response.data.responseCode === 105
      ) {
        isLogin();
      } else if (
        response.data.responseCode === 109 ||
        response.data.responseCode === 122
      ) {
        setSnakbarValues({
          status: true,
          severity: "success",
          message: "OTP sent to your mobile number",
        });
        setTowFactAuth(true);
      } else if (response.data.responseCode === 104) {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Password is not matching, please check your password",
        });
      } else {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Server Error, Please try after sometime",
        });
      }
    });
  };

  const verifyOTP = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.verifyOtp,
      JSON.stringify({
        otpVal: otp,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null &&
          response.data.responseCode === 109
        ) {
          isLogin();
        } else if (response.data.responseCode === 118) {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "Invalid OTP",
          });
        }
      } else {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Server Error, Please try after sometime",
        });
      }
    });
  };
  const isLogin = () => {
    APIRequest.request("GET", ConfigAPIURL.sessionValidation, "").then(
      (response) => {
        //redirect based on user type
        if (response !== undefined && response !== null) {
          if (response?.data !== undefined && response?.data !== null) {
            if (
              response.data.responseCode !== undefined &&
              response.data.responseCode !== null
            ) {
              if (
                response.data.responseCode === 108 ||
                response.data.responseCode === 103
              ) {
                // setSnakbarValues({
                // 	status: true,
                // 	severity: 'error',
                // 	message: 'Wrong OTP',
                // });
              } else if (response.data.responseCode === 109) {
                let userDetails = {};
                if (
                  response.data.user !== null &&
                  response.data.user.permission !== undefined
                ) {
                  userDetails = {
                    email: response.data.user.email,
                    mobileNo: response.data.user.mobileNo,
                    name: response?.data.user?.name,
                    permission: response.data.user.permission,
                    profileImage: response.data.user.profileImage,
                    userName: response.data.user.userName,
                    userType: response.data.user.userType,
                    _id: response?.data?.user?._id,
                  };
                  LocalStorage.userDetails = userDetails;
                } else {
                  setSnakbarValues({
                    status: true,
                    severity: "error",
                    message: "User does not have permission to login",
                  });

                  logoutFunction();
                }

                //redirect based on user type
                if (
                  userDetails.userType !== undefined &&
                  userDetails.userType === "admin"
                ) {
                  props.history.replace("/admin");
                  // } else if (userDetails.userType === 'user') {
                  //   props.history.replace('/user');
                } else {
                  props.history.replace("/login");
                }
              } else {
                setSnakbarValues({
                  status: true,
                  severity: "error",
                  message: "Server Error, Please try after sometime",
                });
              }
            } else {
              setSnakbarValues({
                status: true,
                severity: "error",
                message: "Server Error, Please try after sometime",
              });
            }
          } else {
            setSnakbarValues({
              status: true,
              severity: "error",
              message: "Server Error, Please try after sometime",
            });
          }
        } else {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "Server Error, Please try after sometime",
          });
        }
      }
    );
  };
  console.log(LocalStorage.userDetails, "userDetails");
  const _enableResendOTPButton = () => {
    setTimeout(function () {
      setDisableOtpBtn(false);
    }, 5000);
  };

  const logoutFunction = (props) => {
    APIRequest.request("GET", ConfigAPIURL.userLogout, "").then((response) => {
      console.log(response);
      if (response.code === 100) {
        setTimeout(function () {
          window.location.reload();
        }, 5000);
      }
    });
    sessionStorage.clear();
    localStorage.clear();
    // LocalStorage.permission = null;
  };

  const resendOTP = () => {
    // setDisableOtpBtn(true);
    APIRequest.request("POST", ConfigAPIURL.accountLogin, "").then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (
            response.data.responseCode !== undefined &&
            response.data.responseCode !== null &&
            response.data.responseCode === 109
          ) {
            _enableResendOTPButton();

            setSnakbarValues({
              status: true,
              severity: "info",
              message: "Sucessfully sent OTP",
            });
          } else {
            setDisableOtpBtn(false);
            setSnakbarValues({
              status: true,
              severity: "info",
              message: "Please try after some time",
            });
          }
        } else {
          setDisableOtpBtn(false);
          setSnakbarValues({
            status: true,
            severity: "info",
            message: "Please try after some time",
          });
        }
      }
    );
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Hidden only={["md", "sm", "xs"]}>
        <Grid item xl={8} lg={8} className={classes.image}></Grid>
      </Hidden>
      <Grid
        item
        xl={4}
        lg={4}
        sm={12}
        xs={12}
        component={Paper}
        elevation={6}
        square
        className={classes.loginGrid}
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h3">
            Sign in
          </Typography>
          {!twoFactAut ? (
            <div className={classes.form} style={{ rowGap: "10px" }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={mobileNo}
                onKeyPress={(event) => {
                  return event.charCode >= 48 && event.charCode <= 57
                    ? event
                    : event.preventDefault();
                }}
                onChange={(event) => setMobileNo(event.target.value)}
                id="mobileNo"
                autoComplete="off"
                label="Mobile Number"
                name="mobileNo"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ marginBottom: "-10px" }}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                fullWidth
                name="password"
                autoComplete="off"
                label="Password"
                type="password"
                id="password"
              />
              <Button
                fullWidth
                variant="contained"
                // color='primary'
                style={{
                  backgroundColor: "#16004D",
                  color: "white",
                }}
                className={classes.submit}
                onClick={() => submitMobileLogin()}
              >
                Continue
              </Button>

              {/* <Grid container>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Link
                    href='javascript:void(0)'
                    onClick={handlePasswordChange}
                    variant='body2'
                    style={{color:"#16004D"}}
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid> */}
            </div>
          ) : (
            <div className={classes.form}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                value={otp}
                onKeyPress={(event) => {
                  return event.charCode >= 48 && event.charCode <= 57
                    ? event
                    : event.preventDefault();
                }}
                onChange={(event) => setOTP(event.target.value)}
                fullWidth
                autoComplete="off"
                name="otp"
                label="OTP"
                type={showPassword ? "text" : "password"}
                id="otp"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography
                style={{
                  color: "#16004D",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  submitMobileLogin();
                }}
              >
                Resend OTP
              </Typography>
              <Button
                fullWidth
                variant="contained"
                className={classes.submit}
                style={{
                  backgroundColor: "#16004D",
                  color: "white",
                }}
                onClick={() => verifyOTP()}
              >
                Submit
              </Button>
              <Grid container>
                {/* <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  {!disableOtpBtn ? (
                    <Link
                      href='javascript:void(0)'
                      onClick={() => resendOTP()}
                      variant='body2'
                      style={{color:"#16004D"}}
                    >
                      Resend OTP
                    </Link>
                  ) : (
                    <></>
                  )}
                </Grid> */}
              </Grid>
            </div>
          )}

          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
      <Snackbar
        open={snakbarValues.status}
        onClose={notificationClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert severity={snakbarValues.severity}>{snakbarValues.message}</Alert>
      </Snackbar>
      <SlideInDialog
        title={"Need to reset your password?"}
        contentComponent={
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="justify">
              <Typography variant="body1">
                I would like to reset my password
              </Typography>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              align="justify"
              style={{ margin: "10px 0px" }}
            >
              <Divider />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="justify">
              <Typography variant="body1">
                By clicking “Agree” below you will get an email with a link to
                reset your password. After clicking the link to reset your
                password in that email you will get another email with system
                generated password.'
              </Typography>
            </Grid>
          </Grid>
        }
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus={true}
        slideInDialog={slideInDialog}
        setSlideInDialog={handleSlideInDialog}
        handleDeleteRequest={handleDeleteRequest}
      />
    </Grid>
  );
}

export default Login;
