// import { Tooltip } from "@mui/material";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import CustomTooltip from "./CustomTooltip";

const defaultData = [
  {
    totalCount: 0,
    value: "No data",
  },
];

function BarGraph({ color, services, graphFiltersData }) {
  console.log(services?.graphData, graphFiltersData, "datha");

  const data =
    services?.graphData?.length > 0 ? services?.graphData : defaultData;

  console.log(data, "data");

  return (
    <BarChart
      width={600}
      height={300}
      data={data} //  data
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
      barSize={20}
    >
      <XAxis
        dataKey={graphFiltersData?.dateType}
        scale="point"
        padding={{ left: 25, right: 25 }}
      />
      <YAxis />
      <Tooltip
        content={<CustomTooltip moduleType={graphFiltersData?.module} />}
      />
      <Bar dataKey="totalCount" fill={"#F0A45E"} />
    </BarChart>
  );
}

export default BarGraph;
