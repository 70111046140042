import React from "react";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import APIRequest from "../../../../../utils/APIRequest";

const useImageUpload = (setUserForm, userForm, handleClosePreview) => {
  const onUpload = async (e, field) => {
    const formdata = new FormData();
    const file = e.target.files[0];
    formdata.append("attachment", file);
    formdata.append("bucketName", "profile");

    const response = await APIRequest.multipartForDataRequest(
      "PUT",
      ConfigAPIURL.uploadFile,
      formdata
    );

    if (response.data.responseCode === 109) {
      const url = response?.data?.attachmentUrl[0]; // Extract the URL from the response

      // Directly update the attachment field with the URL
      setUserForm({
        ...userForm,
        [field]: url, // Update attachment as a string
      });
    }
  };

  const deleteImages = () => {
    setUserForm({
      ...userForm,
      gst: null, // Clear the attachment field
    });
    if (handleClosePreview) handleClosePreview(); // Close the preview if applicable
  };

  return { onUpload, deleteImages };
};

export default useImageUpload;
