import { Box, Typography } from "@material-ui/core";
import React from "react";

function BarIndication({ amount, label, backgroundColor }) {
  return (
    <>
      <Box
        style={{
          width: "30px",
          height: "30px",
          backgroundColor: backgroundColor, //#707BF7
          borderRadius: "50%",
          marginRight: "8px",
        }}
      ></Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          style={{
            fontWeight: "600",
            fontSize: "14px",
            fontFamily: "Segoe UI",
          }}
        >
          ₹ {amount}
        </Typography>
        <Typography
          style={{
            color: "#868686",
            fontFamily: "Segoe UI",
            fontSize: "12px",
          }}
        >
          {`${label}`}
        </Typography>
      </Box>
    </>
  );
}

export default BarIndication;
