import React, { useState } from "react";
// import { Box, TextField, Button, IconButton } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, Button, IconButton, TextField } from "@mui/material";

const ChatInput = ({
  createLogMessage,
  recordId,
  listLogMessages,
  moduleType,
  createdFor,
}) => {
  const [message, setMessage] = useState();
  const handleSendMessage = () => {
    if (!message.trim()) {
      return;
    }
    createLogMessage(moduleType, recordId, message, createdFor);
    setMessage("");
    setTimeout(() => {
      listLogMessages(moduleType, recordId);
    }, 500);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
        position: "relative",
      }}
    >
      {/* Chat Input Box */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flex: 1,
          border: "1px solid #ccc",
          borderRadius: "8px",
          padding: "4px",
          boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
        }}
      >
        {/* Text Input */}
        <TextField
          placeholder="Type here"
          variant="standard"
          fullWidth
          InputProps={{
            disableUnderline: true,
            sx: { padding: "4px", fontSize: "14px" },
          }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSendMessage();
            }
          }}
        />

        {/* Attachment Icon */}
        {/* <IconButton
          sx={{
            color: "#ff8c00",
            marginLeft: "10px",
          }}
        >
          <AttachFileIcon />
        </IconButton> */}
      </Box>

      {/* Send Button */}
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#ff8c00",
          color: "#fff",
          textTransform: "none",
          padding: "8px 16px",
          borderRadius: "4px",
          minWidth: "80px",
          height: "40px",
          "&:hover": {
            backgroundColor: "#e57700",
          },
        }}
        // onClick={() => {
        //   if (!message) {
        //     return;
        //   }
        //   createLogMessage(moduleType, recordId, message);
        //   setMessage("");
        //   setTimeout(() => {
        //     listLogMessages(moduleType, recordId);
        //   }, 500);
        // }}
        onClick={handleSendMessage}
      >
        Send
      </Button>
    </Box>
  );
};

export default ChatInput;
