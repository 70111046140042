import {
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  Paper,
  styled,
  TextField,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import ImagesUpload from "./ImagesUpload";
import "./styles/user.scss";
// import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import { Eye24Filled, EyeOff24Filled } from "@fluentui/react-icons";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));
const userFormDetails = {
  userType: "member",
  active: true,
  isSuperAdmin: false,
  sector: null,
  occupationType: "",
  userTag: "",
  businessName: "",
  workingHours: "",
  experience: "",
  fragmentedAddress: "",
  workReference: "",
  description: "",
  // plan: "free plan",
  // activeplan: "",
  expiresOn: "",
  location: {
    type: "Point",
    coordinates: [0, 0],
  },
  // plan: "",
  lat: 0,
  lng: 0,
  permission: "",
  name: "",
  mobileNo: "",
  email: "",
  address: [
    {
      city: "",
      state: "",
      country: "",
      postalCode: "",
    },
  ],
  address2: "",
  bloodPressure: { high: "", low: "" },
  flatNo: "",
  city: "",
  state: "",
  country: "",
  postalCode: "",
  age: "",
  dob: null,
  gender: null,
  description: "",
  images: [],
  note: "",
  referralCode: "",
};

function AddUser(props) {
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [userForm, setUserForm] = useState(userFormDetails);
  const [categorydata, setCategoryData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [flag1, setFlag1] = useState(false);
  const [addplan, setAddPlan] = useState([]);
  const [plan, setPlan] = useState("");
  const [handloomchecked, setHandloomChecked] = useState(false);
  const [powerloomchecked, setPowerloomChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // const[handloom,setHandloom]=useState(userFormDetails.sector)
  useEffect(() => {}, []);
  useEffect(() => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setUserForm({ ...userFormDetails, userType: "user" });
    }
  }, [props.page]);

  console.log("formDialog", props.formDialog);
  useEffect(() => {
    // setUserForm(userFormDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setUserForm({ ...userFormDetails, userType: "user" });
    } else {
      setUserForm(userFormDetails);
    }
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const categorylist = () => {
    APIRequest.request("GET", ConfigAPIURL.listcategory, "").then(
      (response) => {
        let userResult = response?.data?.result;
        setCategoryData(userResult);
        // console.log(response, "response");
      }
    );
  };
  useEffect(() => {
    categorylist();
  }, []);
  console.log(categorydata, "data");
  const [sectorlist, setSectorlist] = useState([]);

  const sectorListData = () => {
    APIRequest.request("GET", ConfigAPIURL.sectorData, "").then((response) => {
      let userResult = response?.data?.result;
      setSectorlist(userResult);
      // console.log(sectorlist, "sectorData");
    });
  };

  useEffect(() => {
    sectorListData();
  }, []);

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "GET",
        ConfigAPIURL.postDetails + `?recordId=` + `${props.rowDetails._id}`
        // JSON.stringify({ userId: props.rowDetails._id })
      ).then((response) => {
        console.log(response);
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let userResult = response?.data?.result;
            // userResult.dob = new Date(userResult.dob * 1000);
            // userResult.allergies =
            //   userResult.allergies !== ''
            //     ? userResult.allergies.split(',')
            //     : [];
            // userResult.chronicConditions =
            //   userResult.chronicConditions !== ''
            //     ? userResult.chronicConditions.split(',')
            //     : [];
            // userResult.familyHistory =
            //   userResult.familyHistory !== ''
            //     ? userResult.familyHistory.split(',')
            //     : [];
            // userResult.surgeries =
            //   userResult.surgeries !== ''
            //     ? userResult.surgeries.split(',')
            //     : [];

            // let permissions = {};
            // if (props.roles !== undefined && props.roles !== null) {
            //   props.roles.map((role) => {
            //     if (role._id === userResult.permission) {
            //       permissions = role;
            //     }
            //   });
            // }

            setUserForm({
              ...userForm,
              userType: userResult?.userType,
              active: userResult?.active,
              isSuperAdmin: userResult?.isSuperAdmin,
              sector: userResult?.sector,
              permission: userResult?.permissions,
              name: userResult.name,
              mobileNo: userResult?.mobileNo,
              email: userResult?.email,
              address: userResult?.address,
              // address:userResult?.address?.city,
              // stateuser:userResult?.address?.state,
              // countryuser:userResult?.address?.country,
              // postalCodeuser:userResult?.address?.postalCode,
              flatNo: userResult.flatNo,
              city: userResult?.city,
              state: userResult?.state,
              country: userResult?.country,
              pin: userResult?.pin,
              lat: userResult?.location?.coordinates[0],
              lng: userResult?.location?.coordinates[1],
              // age: userResult.age,
              dob: userResult?.dob,
              gender: userResult?.gender,

              // weight: userResult.weight,
              // bloodGroup: userResult.bloodGroup,
              // height: userResult.height,
              // bloodPressure:
              //   userResult.bloodPressure !== undefined &&
              //   userResult.bloodPressure !== null
              //     ? userResult.bloodPressure
              //     : "",
              // isSmoker: userResult.isSmoker,
              // alcoholIntake: userResult.alcoholIntake,
              // allergies: userResult.allergies,
              // chronicConditions: userResult.chronicConditions,
              // familyHistory: userResult.familyHistory,
              // surgeries: userResult.surgeries,
            });

            setSelectedRowId(userResult._id);
          }
        }
      });
    }
  };
  console.log(handloomchecked, "userForm", powerloomchecked);
  // sector check box function

  //keyboard shortcut

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();

        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    let url = ConfigAPIURL.userCreate;
    let method = "POST";

    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.userUpdate;
      userForm["recordId"] = selectedRowId;
      method = "PUT";
    }
    let fieldValidation =
      userForm.userType !== "admin"
        ? [
            "name",
            "userType",
            "mobileNo",
            // "gender",
            "sector",
            "occupationType",
            // "plan",
          ]
        : ["name", "userType", "mobileNo", "permission"];
    FormValidation.validation(fieldValidation, userForm).then(
      (ValidationResult) => {
        if (ValidationResult) {
          if (userForm.mobileNo.length < 9 || userForm.mobileNo.length > 10) {
            SnackbarUtils.error(props.t("forms.mobilenoValidation"), 3000).then(
              (notification) => {
                props.publishNotification(notification);
              }
            );

            return;
          }
          // if (userForm.userType !== "admin") {
          //   if (userForm?.lat !== Number && userForm?.lng !== Number) {
          //     SnackbarUtils.error(props.t("forms.lat"), 3000).then(
          //       (notification) => {
          //         props.publishNotification(notification);
          //       }
          //     );
          //   }

          //   return;
          // }
          if (userForm?.userType === "admin") {
            delete userForm?.sector;
          }
          // userForm.permission = userForm.permission._id;
          APIRequest.request(method, url, JSON.stringify(userForm)).then(
            (response) => {
              if (response !== undefined && response !== null) {
                if (
                  response.code === 100 &&
                  response.data.responseCode === 109
                ) {
                  if (props.formDialog.divType === "new") {
                    SnackbarUtils.sucess(
                      props.t("snackbar.savedSuccess"),
                      "bottomCenter",
                      3000
                    ).then((notification) => {
                      props.publishNotification(notification);
                    });
                  } else {
                    SnackbarUtils.sucess(
                      props.t("snackbar.updatedSuccess"),
                      "bottomCenter",
                      3000
                    ).then((notification) => {
                      props.publishNotification(notification);
                    });
                  }

                  props.handleFormDialog();
                  props.tableQuery({
                    keyword: "",
                    page: 0,
                    pageSize: 10,
                    sortField: "",
                    userType: "all",
                    sortOrder: "false",
                    active: true,
                  });
                  setUserForm(userFormDetails);
                  setSelectedRowId("");
                }
                if (
                  response.code === 100 &&
                  response.data.responseCode === 114
                ) {
                  SnackbarUtils.error(
                    props.t("Mobile Number already exists"), //snackbar.dupliRecords
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                }
              }
            }
          );
        }
      }
    );
  };

  const getPlans = () => {
    APIRequest.request("POST", ConfigAPIURL.plans).then((response) => {
      let userResult = response?.data?.rows;
      setAddPlan(userResult);
      console.log(userResult);
    });
  };
  useEffect(() => {
    getPlans();
  }, []);
  console.log("plan", addplan);
  console.log(userForm, "userForm");

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: "black",
  }));
  console.log(plan, "planid");
  const expiresOn = "companyName"?.starAt + "companyName"?.endAt * 86400;
  return (
    <div className={classes.root} style={{ marginTop: props.marginTop }}>
      <Stack className="maincontainer" style={{ borderRadius: "8px" }}>
        <Stack
          style={{
            backgroundColor: "#FFDDB3",
            padding: "15px",
            borderRadius: "8px",
          }}
        >
          <Stack
            style={{
              backgroundColor: "white",
              borderRadius: "8px",
              padding: "15px",
            }}
          >
            {/* first grid */}
            <Stack>
              <Grid container spacing={2}>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{ padding: "20px", marginTop: "7px" }}
                >
                  <TextField
                    id="userType"
                    fullWidth
                    // disableUnderline={false}
                    label={props.t("user.userType")}
                    variant="outlined"
                    autoCapitalize="words"
                    select
                    style={{
                      // boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.15)",
                      autoCapitalize: "words",
                    }}
                    // className="textborder"
                    disabled={
                      props.formDialog.divType === "new" ||
                      props.formDialog.divType === "edit"
                        ? false
                        : true
                    }
                    required
                    value={userForm.userType}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        userType: event.target.value,
                      })
                    }
                  >
                    <MenuItem key={0} value={"admin"}>
                      {"Admin"}
                    </MenuItem>

                    <MenuItem key={2} value={"member"}>
                      {"Member"}
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{ padding: "20px" }}
                >
                  <TextField
                    variant="outlined"
                    id="active"
                    fullWidth
                    label={props.t("user.active")}
                    //  className={classes.userTypeMenuItem}
                    select
                    style={{
                      marginTop: "6px",
                    }}
                    // required
                    // className="textborder"
                    disabled={
                      props.formDialog.divType === "new" ||
                      props.formDialog.divType === "edit"
                        ? false
                        : true
                    }
                    defaultValue={userForm.active}
                    value={userForm.active}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        active: event.target.value,
                      })
                    }
                  >
                    <MenuItem key={0} value={true}>
                      {props.t("user.active")}
                    </MenuItem>
                    <MenuItem key={1} value={false}>
                      {props.t("user.inactive")}
                    </MenuItem>
                  </TextField>
                </Grid>

                {/* <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{ padding: "20px", marginTop: "7px" }}
                >
                  <TextField
                    id="gender"
                    fullWidth
                    // disableUnderline={false}
                    label={props.t("user.gender")}
                    variant="outlined"
                    autoCapitalize="words"
                    select
                    style={{
                      // boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.15)",
                      autoCapitalize: "words",
                    }}
                    // className="textborder"
                    disabled={
                      props.formDialog.divType === "new" ||
                      props.formDialog.divType === "edit"
                        ? false
                        : true
                    }
                    required
                    value={userForm.gender}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        gender: event.target.value,
                      })
                    }
                  >
                    <MenuItem key={0} value={"male"}>
                      {"Male"}
                    </MenuItem>

                    <MenuItem key={1} value={"female"}>
                      {"Female"}
                    </MenuItem>

                    <MenuItem key={2} value={"others"}>
                      {"Others"}
                    </MenuItem>
                  </TextField>
                </Grid> */}
              </Grid>
            </Stack>

            {/* second Grid */}
            <Stack
              style={{
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "column",
                  sm: "column",
                },
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{ padding: "20px" }}
                >
                  <TextField
                    variant="outlined"
                    id="name"
                    label={props.t("user.name")}
                    autoComplete="something-unsupported"
                    required
                    value={userForm.name}
                    fullWidth
                    // onKeyPress={(event) => {
                    //   return (event.charCode >= 65 && event.charCode <= 90) ||
                    //     (event.charCode >= 97 && event.charCode <= 122) ||
                    //     event.charCode === 32
                    //     ? event
                    //     : event.preventDefault();
                    // }}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        name: event.target.value,
                      })
                    }
                    disabled={
                      props.formDialog.divType === "new" ||
                      props.formDialog.divType === "edit"
                        ? false
                        : true
                    }
                  />
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{ padding: "20px" }}
                >
                  <TextField
                    variant="outlined"
                    id="email"
                    label={props.t("user.emailAddress")}
                    autoComplete="something-unsupported"
                    fullWidth
                    value={userForm.email}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        email: event.target.value,
                      })
                    }
                    type="email"
                    onKeyPress={(event) => {
                      return (event.charCode >= 65 && event.charCode <= 90) ||
                        (event.charCode >= 97 && event.charCode <= 122) ||
                        (event.charCode >= 48 && event.charCode <= 57) ||
                        event.charCode === 64 ||
                        event.charCode === 46
                        ? event
                        : event.preventDefault();
                    }}
                    disabled={
                      props.formDialog.divType === "new" ||
                      props.formDialog.divType === "edit"
                        ? false
                        : true
                    }
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{ padding: "20px" }}
                  className={classes.input}
                >
                  {/* <TextField
                    variant="outlined"
                    id="mobileNo"
                    label={props.t("user.mobileNo")}
                    // autoComplete="something-unsupported"
                    required
                    fullWidth
                    disabled={props.formDialog.divType === "edit"}
                    value={userForm.mobileNo}
                    inputProps={{ maxLength: 10 }}
                    type={"number"}
                    sx={{
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                      "& input[type=number]::-webkit-outer-spin-button": {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                      "& input[type=number]::-webkit-inner-spin-button": {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    }}
                    onKeyPress={(event) => {
                      return event.charCode >= 48 && event.charCode <= 57
                        ? event
                        : event.preventDefault();
                    }}
                    onChange={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                      setUserForm({
                        ...userForm,
                        mobileNo: e.target.value,
                      });
                    }}
                  /> */}
                  <TextField
                    variant="outlined"
                    id="mobileNo"
                    label={props.t("user.mobileNo")}
                    value={userForm.mobileNo}
                    type="number"
                    inputProps={{ maxLength: 10 }}
                    required
                    fullWidth
                    onChange={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                      setUserForm({
                        ...userForm,
                        mobileNo: e.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Stack>

            {/* third Stack */}
            {userForm.userType !== "admin" ? (
              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },
                }}
              >
                <Grid container spacing={2}>
                  {/* <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ padding: "20px" }}
                  >
                    <Autocomplete
                      // multiple
                      // limitTags={2}
                      required
                      options={sectorlist}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      InputProps={{
                        style: {
                          background: "#FFFFFF",
                        },
                      }}
                      // renderOption={(props, option, { selected }) => (
                      //   <li {...props}>
                      //     <Checkbox
                      //       defaultChecked={sectorlist}
                      //       icon={icon}
                      //       checkedIcon={checkedIcon}
                      //       checked={selected}
                      //     />
                      //     {option}
                      //   </li>
                      // )}
                      renderInput={(params) => (
                        <TextField
                          label="Sector"
                          {...params}
                          defaultValue={sectorlist}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              background: "#FFFFFF",
                            },
                          }}
                        />
                      )}
                      onChange={(event, value) => {
                        setUserForm({
                          ...userForm,
                          sector: value,
                        });
                      }}
                    />
                  </Grid> */}
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ padding: "20px" }}
                  >
                    <TextField
                      required
                      variant="outlined"
                      id="sector"
                      label={"Sector"}
                      autoComplete="something-unsupported"
                      fullWidth
                      select
                      value={userForm?.sector}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          sector: event.target.value,
                        })
                      }
                    >
                      {sectorlist &&
                        sectorlist.length > 0 &&
                        sectorlist?.map((option, index) => {
                          // console.log("category data", option);

                          return (
                            <MenuItem value={option} key={index}>
                              {option}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ padding: "20px" }}
                  >
                    <TextField
                      required
                      variant="outlined"
                      id="occupationType"
                      label={props.t("user.worktype")}
                      autoComplete="something-unsupported"
                      fullWidth
                      select
                      value={userForm?.occupationType}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          occupationType: event.target.value,
                        })
                      }
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                    >
                      {categorydata &&
                        categorydata.length > 0 &&
                        categorydata?.map((option, index) => {
                          // console.log("category data", option);

                          return (
                            <MenuItem value={option} key={index}>
                              {option}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Grid>
                  {userForm.userType === "shop" ? (
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{ padding: "20px", marginTop: "8px" }}
                    >
                      <TextField
                        variant="outlined"
                        id="qualification"
                        label={props.t("user.businessName")}
                        autoComplete="something-unsupported"
                        fullWidth
                        value={userForm.businessName}
                        onChange={(event) =>
                          setUserForm({
                            ...userForm,
                            businessName: event.target.value,
                          })
                        }
                        // onKeyPress={(event) => {
                        //   return (event.charCode >= 65 &&
                        //     event.charCode <= 90) ||
                        //     (event.charCode >= 97 && event.charCode <= 122) ||
                        //     (event.charCode >= 48 && event.charCode <= 57) ||
                        //     event.charCode === 64 ||
                        //     event.charCode === 46
                        //     ? event
                        //     : event.preventDefault();
                        // }}
                        disabled={
                          props.formDialog.divType === "new" ||
                          props.formDialog.divType === "edit"
                            ? false
                            : true
                        }
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Stack>
            ) : (
              <></>
            )}

            {/* fouth grid */}
            {userForm.userType !== "admin" ? (
              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ padding: "20px" }}
                  >
                    <TextField
                      variant="outlined"
                      id="workingHours"
                      label={props.t("user.workingHours")}
                      autoComplete="something-unsupported"
                      // required
                      type="number"
                      className={classes.input}
                      value={userForm.workingHours}
                      fullWidth
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          workingHours: event.target.value,
                        })
                      }
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ padding: "20px" }}
                  >
                    <TextField
                      variant="outlined"
                      id="experience"
                      label={props.t("user.experience")}
                      autoComplete="something-unsupported"
                      // required
                      value={userForm.experience}
                      fullWidth
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          experience: event.target.value,
                        })
                      }
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                    />
                  </Grid>
                  {/* {userForm.userType === "shop" ? ( */}
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ padding: "20px" }}
                  >
                    <TextField
                      variant="outlined"
                      id="flatNo"
                      label={props.t("user.flatNo")}
                      autoComplete="something-unsupported"
                      required
                      value={userForm.flatNo}
                      fullWidth
                      // onKeyPress={(event) => {
                      //   return (event.charCode >= 65 &&
                      //     event.charCode <= 90) ||
                      //     (event.charCode >= 97 && event.charCode <= 122) ||
                      //     event.charCode === 32
                      //     ? event
                      //     : event.preventDefault();
                      // }}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          flatNo: event.target.value,
                        })
                      }
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                    />
                  </Grid>
                  {/* ) : ( */}
                  {/* // <Grid
                    //   item
                    //   xl={4}
                    //   lg={4}
                    //   md={4}
                    //   sm={12}
                    //   xs={12}
                    //   style={{ padding: "20px" }}
                    // >
                    //   <TextField
                    //     variant="outlined"
                    //     id="flatNo"
                    //     label={props.t("user.flatNo")}
                    //     autoComplete="something-unsupported"
                    //     required
                    //     value={userForm.flatNo}
                    //     fullWidth
                    //     onChange={(event) => {
                    //       let flatNo = [...userForm?.address];
                    //       flatNo[0].flatNo = event.target.value;
                    //       setUserForm({
                    //         ...userForm,
                    //         address: flatNo,
                    //       });
                    //     }}
                    //     disabled={
                    //       props.formDialog.divType === "new" ||
                    //       props.formDialog.divType === "edit"
                    //         ? false
                    //         : true
                    //     }
                    //   />
                    // </Grid> */}
                  {/* <></> */}
                  {/* )} */}
                </Grid>
              </Stack>
            ) : (
              <></>
            )}

            {/* fifth grid */}
            {userForm.userType !== "admin" ? (
              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },
                }}
              >
                <Grid container spacing={2}>
                  {/* {userForm.userType === "shop" ? ( */}
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ padding: "20px" }}
                  >
                    <TextField
                      variant="outlined"
                      id="street"
                      label={props.t("user.street")}
                      autoComplete="something-unsupported"
                      required
                      value={userForm.street}
                      fullWidth
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          street: event.target.value,
                        })
                      }
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                    />
                  </Grid>
                  {/* ) : ( */}
                  {/* // <Grid
                    //   item
                    //   xl={4}
                    //   lg={4}
                    //   md={4}
                    //   sm={12}
                    //   xs={12}
                    //   style={{ padding: "20px" }}
                    // >
                    //   <TextField
                    //     variant="outlined"
                    //     id="street"
                    //     label={props.t("user.street")}
                    //     autoComplete="something-unsupported"
                    //     required
                    //     value={userForm?.address[0]?.street}
                    //     fullWidth
                    //     onChange={(event) => {
                    //       let street = [...userForm?.address];
                    //       street[0].street = event.target.value;
                    //       setUserForm({
                    //         ...userForm,
                    //         address: street,
                    //       });
                    //     }}
                    //     disabled={
                    //       props.formDialog.divType === "new" ||
                    //       props.formDialog.divType === "edit"
                    //         ? false
                    //         : true
                    //     }
                    //   />
                    // </Grid> */}
                  {/* <></> */}
                  {/* )} */}
                  {/* {userForm.userType === "shop" ? ( */}
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ padding: "20px" }}
                  >
                    <TextField
                      variant="outlined"
                      id="city"
                      label={props.t("user.city")}
                      autoComplete="something-unsupported"
                      required
                      value={userForm.city}
                      fullWidth
                      // onKeyPress={(event) => {
                      //   return (event.charCode >= 65 &&
                      //     event.charCode <= 90) ||
                      //     (event.charCode >= 97 && event.charCode <= 122) ||
                      //     event.charCode === 32
                      //     ? event
                      //     : event.preventDefault();
                      // }}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          city: event.target.value,
                        })
                      }
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                    />
                  </Grid>
                  {/* ) : (
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{ padding: "20px" }}
                    >
                      <TextField
                        variant="outlined"
                        id="city"
                        label={props.t("user.city")}
                        autoComplete="something-unsupported"
                        required
                        value={userForm?.address[0]?.city}
                        fullWidth
                        // onKeyPress={(event) => {
                        //   return (event.charCode >= 65 &&
                        //     event.charCode <= 90) ||
                        //     (event.charCode >= 97 && event.charCode <= 122) ||
                        //     event.charCode === 32
                        //     ? event
                        //     : event.preventDefault();
                        // }}
                        onChange={(event) => {
                          let city = [...userForm?.address];
                          city[0].city = event.target.value;
                          setUserForm({
                            ...userForm,
                            address: city,
                          });
                        }}
                        disabled={
                          props.formDialog.divType === "new" ||
                          props.formDialog.divType === "edit"
                            ? false
                            : true
                        }
                      />
                    </Grid>
                  )} */}
                  {/* {userForm?.userType === "shop" ? ( */}
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ padding: "20px" }}
                  >
                    <TextField
                      variant="outlined"
                      id="state"
                      label={props.t("user.state")}
                      autoComplete="something-unsupported"
                      required
                      value={userForm.state}
                      fullWidth
                      onKeyPress={(event) => {
                        return (event.charCode >= 65 && event.charCode <= 90) ||
                          (event.charCode >= 97 && event.charCode <= 122) ||
                          event.charCode === 32
                          ? event
                          : event.preventDefault();
                      }}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          state: event.target.value,
                        })
                      }
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                    />
                  </Grid>
                  {/* ) : ( */}
                  {/* <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{ padding: "20px" }}
                    >
                      <TextField
                        variant="outlined"
                        id="state"
                        label={props.t("user.state")}
                        autoComplete="something-unsupported"
                        required
                        value={userForm?.address[0]?.state}
                        fullWidth
                        onKeyPress={(event) => {
                          return (event.charCode >= 65 &&
                            event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122) ||
                            event.charCode === 32
                            ? event
                            : event.preventDefault();
                        }}
                        onChange={(event) => {
                          let state = [...userForm?.address];
                          state[0].state = event.target.value;
                          setUserForm({
                            ...userForm,
                            address: state,
                          });
                        }}
                        disabled={
                          props.formDialog.divType === "new" ||
                          props.formDialog.divType === "edit"
                            ? false
                            : true
                        }
                      />
                    </Grid>
                  )} */}
                </Grid>
              </Stack>
            ) : (
              <></>
            )}

            {userForm.userType !== "admin" ? (
              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ padding: "20px" }}
                  >
                    {/* shop postal code */}
                    <TextField
                      className={classes.input}
                      variant="outlined"
                      id="postalCode"
                      label={props.t("user.postalCode")}
                      autoComplete="something-unsupported"
                      required
                      value={userForm.postalCode}
                      fullWidth
                      type="number"
                      onChange={(event) => {
                        const value = event.target.value.slice(0, 6); // Limit to 6 characters
                        setUserForm({
                          ...userForm,
                          postalCode: value,
                        });
                      }}
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                    />
                  </Grid>
                  {/* <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ padding: "20px" }}
                  >
                    <TextField
                      id="plan"
                      fullWidth
                      // disableUnderline={false}
                      label={props.t("user.planType")}
                      variant="outlined"
                      autoCapitalize="words"
                      select
                      style={{
                        // boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.15)",
                        autoCapitalize: "words",
                      }}
                      // className="textborder"
                      // disabled={props.formDialog.divType === "edit"}
                      required
                      value={userForm.plan}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          plan: event.target.value,
                          // plan: setPlan(event.target.value),
                        })
                      }
                    >
                      {addplan &&
                        addplan.length > 0 &&
                        addplan.map((option, ind) => {
                          return (
                            <MenuItem key={ind} value={option?._id}>
                              {option?.planType}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Grid> */}

                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ padding: "20px" }}
                  >
                    <TextField
                      id="referralId"
                      fullWidth
                      // disableUnderline={false}
                      label={props.t("user.referralCode")}
                      variant="outlined"
                      autoCapitalize="words"
                      style={
                        {
                          // boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.15)",
                          // autoCapitalize: "words",
                        }
                      }
                      // className="textborder"
                      // disabled={props.formDialog.divType === "new"}
                      // required
                      value={userForm?.referralCode}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          referralCode: event.target.value,
                        })
                      }
                    ></TextField>
                  </Grid>
                </Grid>
              </Stack>
            ) : (
              <></>
            )}
            {userForm.userType === "admin" ? (
              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ padding: "20px", marginTop: "6px" }}
                  >
                    <TextField
                      style={{}}
                      id="permission"
                      // size="small"
                      variant="outlined"
                      label={props.t("user.permission")}
                      select
                      required
                      fullWidth
                      defaultValue={userForm.permission}
                      value={userForm.permission}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          permission: event.target.value,
                        })
                      }
                    >
                      {props.roles.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ padding: "20px", marginTop: "6px" }}
                  >
                    {/* <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        size="large"
                        className={`${classes.input} input__Style`}
                        placeholder="Enter password"
                        variant="outlined"
                        value={userForm?.password || ""}
                        type={showPassword ? "text" : "password"}
                        contentAfter={
                          showPassword ? (
                            <Eye24Filled
                              style={{ cursor: "pointer" }}
                              onClick={togglePasswordVisibility}
                            />
                          ) : (
                            <EyeOff24Filled
                              style={{ cursor: "pointer" }}
                              onClick={togglePasswordVisibility}
                            />
                          )
                        }
                        onChange={(e) => {
                          setUserForm({
                            ...userForm,
                            password: e.target.value.trim(),
                          });
                        }}
                        autoComplete="off"
                      />
                      <LoopIcon
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                          onClick={() => {
                            const newPassword = generateRandomPassword();
                            setUserForm({
                              ...userForm,
                              password: newPassword,
                            });
                            //  services?.resetPassAttempts(recordId);
                          }}
                        />
                    </div> */}
                    <TextField
                      id="password"
                      // size="small"
                      variant="outlined"
                      label={"Password"}
                      type={showPassword ? "text" : "password"}
                      autoComplete="off"
                      fullWidth
                      required
                      value={userForm.password}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          password: event.target.value,
                        })
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={togglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <Eye24Filled />
                              ) : (
                                <EyeOff24Filled />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {/* <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ padding: "20px", marginTop: "6px" }}
                  >
                    <TextField
                      variant="outlined"
                      id="qualification"
                      label={props.t("user.qualification")}
                      autoComplete="something-unsupported"
                      fullWidth
                      value={userForm.qualification}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          qualification: event.target.value,
                        })
                      }
                      onKeyPress={(event) => {
                        return (event.charCode >= 65 && event.charCode <= 90) ||
                          (event.charCode >= 97 && event.charCode <= 122) ||
                          (event.charCode >= 48 && event.charCode <= 57) ||
                          event.charCode === 64 ||
                          event.charCode === 46
                          ? event
                          : event.preventDefault();
                      }}
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                    />
                  </Grid> */}
                </Grid>
              </Stack>
            ) : (
              <></>
            )}

            {/* description and workrefence stack */}

            {userForm?.userType !== "admin" ? (
              <Stack>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={8}
                    lg={8}
                    md={8}
                    sm={12}
                    xs={12}
                    style={{ padding: "20px", marginTop: "7px" }}
                  >
                    <TextField
                      id="description"
                      fullWidth
                      // disableUnderline={false}
                      label={props.t("user.description")}
                      variant="outlined"
                      autoCapitalize="words"
                      multiline
                      maxRows={6}
                      // select
                      style={{
                        // boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.15)",
                        autoCapitalize: "words",
                      }}
                      // required
                      value={userForm.description}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          description: event.target.value,
                        })
                      }
                    ></TextField>
                  </Grid>

                  {/* <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    style={{ padding: "20px", marginTop: "7px" }}
                  >
                    <TextField
                      id="workReference"
                      fullWidth
                      // disableUnderline={false}
                      label={props.t("user.workReference")}
                      variant="outlined"
                      autoCapitalize="words"
                      multiline
                      maxRows={6}
                      // select
                      style={{
                        // boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.15)",
                        autoCapitalize: "words",
                      }}
                      required
                      value={userForm.workReference}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          workReference: event.target.value,
                        })
                      }
                    ></TextField>
                  </Grid> */}
                </Grid>
                <Grid container spacing={2}></Grid>
              </Stack>
            ) : (
              <></>
            )}
            {/* images uplaod stack */}
            {userForm?.userType !== "admin" ? (
              <ImagesUpload setUserForm={setUserForm} userForm={userForm} />
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AddUser)
);
