import React from "react";
import { withTranslation } from "react-i18next";
import { Route, Switch, Redirect } from "react-router-dom";
import Role from "./role";
import User from "./user";
import Dashboard from "./dash_board";
import Customer from "../admin/CustomerService/index";
//Implemented post here
import Post from "./posts";
import Infotainment from "./Post/Infotainment";
import Profile from "./profile";
import Setting from "./setting";
import Categories from "./Category";
import Advertisement from "./advertisement";
import Plans from "./plans";
// const Recharge=React.lazy(()=>import("./Recharge"));
import Recharge from "./Recharge";
import Requirement from "./Post/Requiremnets";
import Sales from "./Post/Sales";
import Members from "./Enquiries/Members";
import myLooms from "./Enquiries/My Looms";
import Reports from "./Enquiries/Reports";
import Services from "./Orders/Services";

// const Category=React.lazy(() => import("./Category"));
function AdminContentRouting(props) {
  return (
    <Switch>
      <Route
        exact
        path="/admin"
        render={() => <Redirect to="/admin/users" />}
      />
      <Route exact path={["/admin/security/role"]} component={Role} />
      <Route exact path={["/admin/dashboard"]} component={Dashboard} />
      <Route exact path={["/admin/users"]} component={User} />
      <Route exact path={["/admin/posts"]} component={Post} />
      <Route exact path={["/admin/profile"]} component={Profile} />
      <Route exact path={["/admin/setting"]} component={Setting} />
      <Route exact path={["/admin/category"]} component={Categories} />
      <Route exact path={["/admin/advertisement"]} component={Advertisement} />
      <Route exact path={["/admin/plans"]} component={Plans} />
      <Route exact path={["/admin/recharge"]} component={Recharge} />
      <Route exact path={["/admin/customer"]} component={Customer} />
      <Route
        exact
        path={["/admin/posts/infotainment"]}
        component={Infotainment}
      />
      <Route
        exact
        path={["/admin/posts/requirement"]}
        component={Requirement}
      />
      <Route exact path={["/admin/posts/sales"]} component={Sales} />
      <Route exact path={["/admin/enquiries/members"]} component={Members} />
      <Route exact path={["/admin/enquiries/myLooms"]} component={myLooms} />
      <Route exact path={["/admin/enquiries/reports"]} component={Reports} />
      <Route exact path={["/admin/orders/services"]} component={Services} />

      <Route render={() => <h3>Coming soon.</h3>} />
    </Switch>
  );
}
export default withTranslation("translations")(AdminContentRouting);
