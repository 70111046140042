import React, { useState } from "react";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";

const useImageUpload = (setUserForm, userForm) => {
  const [isUploading, setIsUploading] = useState({ loading: false, type: "" });
  const moveImagesToUserForm = (image) => {
    setUserForm((prevUserForm) => ({
      ...prevUserForm,
      images: [...prevUserForm.images, image],
    }));
  };

  const setProfilePicture = (image) => {
    setUserForm((p) => ({ ...p, profileImage: image }));
  };

  const onUpload = async (e, type) => {
    setIsUploading({ loading: true, type }); // Start loading for the specific type

    const files = Array.from(e.target.files);

    for (const file of files) {
      const formdata = new FormData();
      formdata.append("attachment", file);
      formdata.append("bucketName", "profile");

      const response = await APIRequest.multipartForDataRequest(
        "PUT",
        ConfigAPIURL.uploadFile,
        formdata
      );
      console.log("response?.data", response?.data);
      if (response.data.responseCode === 109) {
        const image = response?.data?.attachmentUrl[0];
        if (type === "profileImage") {
          setProfilePicture(image);
        } else {
          moveImagesToUserForm(image);
        }
      }
    }
    setIsUploading({ loading: false, type: "" }); // Reset loading state after upload
  };

  const deleteImages = (ind) => {
    const data = [...userForm.images];
    data.splice(ind, 1);
    setUserForm((p) => ({
      ...p,
      images: data,
    }));
  };

  return { onUpload, deleteImages, isUploading };
};

export default useImageUpload;
