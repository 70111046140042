import React from "react";

function CustomSelectDropdown({
  options,
  value,
  onChange,
  placeholder,
  classes,
  defaultValue,
}) {
  return (
    <select
      //   value={value}
      onChange={(e) => onChange(e.target.value)}
      className={classes.selectDropdown}
    >
      {Object.keys(options).map((key) => (
        <option key={key} value={key}>
          {options[key]}
        </option>
      ))}
    </select>
  );
}

export default CustomSelectDropdown;
