import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ReactModal from "react-modal";

import { withTranslation } from "react-i18next";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import ReportedBy from "./ReportedBy.js";
import {
  Grid,
  makeStyles,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Checkbox,
  Box,
  styled,
  TextareaAutosize,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import Autocomplete from "@material-ui/lab/Autocomplete";

import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../../utils/APIRequest";
import FormValidation from "../../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";
import { Stack } from "@mui/material";
import ImagesUpload from "./ImagesUpload.jsx";
// import "./user.scss";
import landingImg from "../../../../../assets/images/Pic.png";
import DownloadIcon from "@mui/icons-material/Download";
// import Addpost from "./Addpost";
// import Reviews from "./Reviews";
// import PlanStack from "./PlanStack";
// import Modals from "./Modal1";
// import ImagesUpload from "./ImagesUpload";
import ModalsReject from "./RejectModal";
// import ReportedBy from "./ReportedBy";

import Modal from "react-modal";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DateConversion from "../../../../../utils/DateConversion";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const postDetails = {
  tagNo: "",
  sector: [],
  occupationType: [],
  author: {
    name: "",
    mobileNo: "",
  },

  description: "",
  startTime: "",

  scheduledTime: "",

  validTill: null,

  range: 45000000,
  referenceUrl: "",
  media: [],
  attachment: [],
  location: {
    type: "Point",
    coordinates: ["", ""],
  },
  fragmentedAddress: "",
  isPermanent: false,
  note: "",
  subject: "",
  tag: [],
  active: true,
};

function AEVForm(props) {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [location, setLocation] = useState("");
  const [places, setPlaces] = useState([]);
  const [placeId, setPlaceId] = useState([]);

  const [selectedOption, setSelectedOption] = useState("");
  const [locationNames, setlocationNames] = useState("");
  const [selectedRowId, setSelectedRowId] = useState("");
  const [userForm, setUserForm] = useState(postDetails);
  const [previewImage, setPreviewImage] = useState(null);
  const handlePreviewImage = (image) => {
    setPreviewImage(image);
  };
  const handleClosePreview = () => {
    setPreviewImage(null);
  };
  const [flag, setFlag] = useState(true);
  const [flag1, setFlag1] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [companyName, setCompanyName] = useState([]);
  const [categorydata, setCategoryData] = useState([]);
  const [sectordata, setSectordata] = useState([]);
  const [addplan, setAddplan] = useState(false);
  const [planlist, setPlanList] = useState([]);
  const [viewplan, setViewPlan] = useState([]);
  const [checked, setChecked] = useState(false); // personal detals flag state
  const [checked1, setChecked1] = useState(false); // subscription detals flag state
  const [checked2, setChecked2] = useState(false); // Reviwes detals flag state
  const [plandata, setPlanData] = useState([]); // plan history state
  const [planhistory, setPlanHistory] = useState([]); // plan history data
  const [activecount, setActiveCount] = useState([]); // plan history data
  const [handloomchecked, setHandloomChecked] = useState(false);
  const [powerloomchecked, setPowerloomChecked] = useState(false);
  const [handloom, setHandloom] = useState(postDetails.sector);
  const [commentdata, setCommentdata] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleRejectClick = () => {
    setOpenModal(true);
  };
  const handleRejectConfirm = () => {
    setUserForm({ note: "" });
    setOpenModal(false);
  };

  useEffect(() => {}, []);
  useEffect(() => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setUserForm({ ...postDetails });
    }
  }, [props.page]);

  //made change here

  useEffect(() => {
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setUserForm({ ...postDetails });
    } else {
      setUserForm(postDetails);
    }
  };

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "GET",
        ConfigAPIURL.postDetails + `?recordId=` + `${props.rowDetails._id}`
        // console.log(props.rowDetails._id, "props.rowDetails._id")
      ).then((response) => {
        // console.log(response);
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let postResult = response?.data?.rows;
            // console.log(postResult, "This is postResult");
            setCompanyName(postResult);
            // console.log("printing full postDetails", postResult);
            // console.log(postResult?.author.name, "postResult?.author.name");
            setUserForm({
              ...userForm,

              tagNo: postResult?.tagNo,

              sector: postResult?.sector,
              occupationType: postResult?.occupationType,
              author: postResult?.author,
              mobileNo: postResult?.author?.mobileNo,
              fragmentedAddress: postResult?.fragmentedAddress,
              description: postResult?.description,
              note: postResult?.note,
              startTime: postResult?.startTime,
              scheduledTime: new Date(postResult?.scheduledTime) * 1000,

              validTill: new Date(postResult?.validTill) * 1000,
              // endTime: new Date(postResult?.endTime * 1000),
              range: postResult?.range,
              referenceUrl: postResult?.referenceUrl,
              media: postResult?.media,
              isPermanent: postResult?.isPermanent,
              attachment: postResult?.attachment,
              subject: postResult?.subject,
              tag: postResult?.tag,
              // location: {
              //   type: postResult.location.type,
              //   coordinates: [
              //     postResult.location.coordinates[0],
              //     postResult.location.coordinates[1],
              //   ],
              // },
              active: postResult?.active,
            });
            // console.log(userForm, "priting complete userForm for setUser");
            setSelectedRowId(postResult._id);
          }
        }
      });
    }
  };

  const Reported = () => {
    APIRequest.request(
      "GET",
      ConfigAPIURL.postReport + "?recordId=" + `${props?.rowDetails?._id}`,
      ""
    ).then((response) => {
      let userResult = response?.data?.result;
      // setPostReport(userResult);
      // console.log(response);
    });
  };
  // console.log(postReport);
  useEffect(() => {
    Reported();
  }, []);

  const categorylist = () => {
    APIRequest.request("GET", ConfigAPIURL.listcategory, "").then(
      (response) => {
        let postDetails = response?.data?.result;
        setCategoryData(postDetails);
        // console.log(response, "response");
      }
    );
  };
  useEffect(() => {
    categorylist();
  }, []);

  const sectorlist = () => {
    APIRequest.request("GET", ConfigAPIURL.sectorData, "").then((response) => {
      let postResult = response?.data?.result;
      setSectordata(postResult);
    });
  };

  useEffect(() => {
    sectorlist();
  }, []);

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    let url = ConfigAPIURL.postCreate;
    let method = "POST";

    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.postUpdate;
      userForm["recordId"] = selectedRowId;
      method = "PUT";
    }
    // console.log("above validartion");
    let fieldValidation = [];
    FormValidation.validation(fieldValidation, userForm).then(
      (ValidationResult) => {
        let formCopy = { ...userForm };
        formCopy["scheduledTime"] = new Date(userForm["scheduledTime"]) / 1000;
        formCopy["startTime"] = Math.floor(
          new Date(userForm?.startTime).getTime() / 1000
        );

        formCopy["validTill"] = userForm?.isPermanent
          ? null
          : new Date(userForm["validTill"]) / 1000;

        APIRequest.request(method, url, JSON.stringify(formCopy)).then(
          (response) => {
            if (response !== undefined && response !== null) {
              if (response.code === 100 && response.data.responseCode === 109) {
                SnackbarUtils.sucess(
                  props.t("snackbar.updatedSuccess"),
                  "bottomCenter",
                  3000
                ).then((notification) => {
                  props.publishNotification(notification);
                });
                props.handleFormDialog();
                props.tableQuery({
                  keyword: "",
                  page: 0,
                  pageSize: 10,
                  sortField: "",
                  userType: "all",
                  sortOrder: "false",
                  active: true,
                  postStatus: "all",
                });
                setUserForm(postDetails);
                setSelectedRowId("");
                // console.log("below validartion");
              }
              if (response.code === 100 && response.data.responseCode === 114) {
                SnackbarUtils.error(
                  props.t("snackbar.dupliRecords"),
                  "bottomCenter",
                  3000
                ).then((notification) => {
                  props.publishNotification(notification);
                });
              }
            }
          }
        );
      }
    );
  };

  // status Approve function
  const handelLogin = (event) => {
    //  console.log("krishna")
    setChecked(!checked);
    if (checked == true) {
      userForm.status = "pending";

      // console.log(userForm);
    }
    if (checked == false) {
      userForm.status = "approve";
    }

    setUserForm({ ...userForm });
    sendToServer();
    // setUserForm(userForm)
  };

  // status reject or block function
  const handelLoginNo = (event) => {
    setChecked1(!checked2);
    if (checked1 == true) {
      userForm.status = "pending";
    }
    // console.log(checked1, "checked1");
    if (checked1 == false) {
      userForm.status = "rejected";
      // userForm.note = "data";
    }
    setUserForm({ ...userForm });
    // setUserForm(userForm)
    sendToServer();
  };

  const handelreject = () => {
    setChecked2(!checked1);

    if (checked2 == true) {
      userForm.status = "pending";
    }
    // console.log(checked1, "checked1");
    if (checked2 == false) {
      userForm.note = comment;
      userForm.status = "rejected";
      // userForm.commentByAdmin="data"
    }
    setUserForm({ ...userForm });
    // setUserForm(userForm)
    sendToServer();
  };
  const handelCommit = (value) => {
    handelreject();
    setCommentdata(value);
  };

  //functions for getting location cordinates
  const handleAddressChange = async (event) => {
    const placeName = event.target.value;

    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getLocation}?place=${placeName}`
    ).then((res) => {
      // console.log(res.data.result.predictions);
      setPlaces(res.data.result.predictions);
    });
    setLocation("");
  };
  const handleOptionClick = (option) => {
    // console.log(option.place_id);
    // setLocation(option.place_id);

    setSelectedOption(option);
    handleLocation(option.place_id);
  };

  const handleLocation = async () => {
    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getLonLan}?place_id=${placeId}`
    ).then((res) => {
      // console.log(res);
      setLocation(res.data?.result?.result?.geometry?.location);
      setUserForm({
        ...userForm,
        location: {
          ...userForm.location,
          coordinates: [
            res.data?.result?.result?.geometry?.location?.lat,
            res.data?.result?.result?.geometry?.location?.lng,
          ],
        },
      });
    });
  };

  useEffect(() => {
    if (selectedOption) {
      // setSelectedOption(selectedOption);
      setlocationNames(selectedOption.description);
      setPlaceId(selectedOption);
      places.find((option, i) => {
        if (option?.description === selectedOption) {
          setPlaceId(option?.place_id);
        }
      });

      handleLocation();
    }
  }, [selectedOption, placeId]);

  const [value, setValue] = React.useState(dayjs("2014-08-18T21:11:54"));

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const [comment, setComment] = useState("");
  //here we are testing the modal

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const rangeOptions = [5, 10, 15, 25, 35, "max"];

  //for fetching all tags for creation of post
  const [allTags, setAllTags] = useState([]);

  const fetchAllTags = async () => {
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.getAllTagsForPost,
      ""
    );
    if (response?.data?.responseCode === 109) {
      setAllTags(response.data.result);
    }
  };

  useEffect(() => {
    fetchAllTags();
  }, []);

  console.log(userForm, "userForm");

  return (
    <div
      className={classes.root}
      style={{
        marginTop: props.marginTop,
        fontFamily: "Lato",
        position: "relative",
        top: "-13px",
      }}
    >
      <Stack style={{ padding: "20px" }}>
        {/* buttons stack */}

        <Stack
          sx={{
            flexDirection: { lg: "row", sm: "row", md: "row" },
            justifyContent: "space-between",
            rowGap: "10px",
            background: "#FFECD3",
            fontFamily: "Lato",
            height: " 60px",
            zIndex: "1",
          }}
        >
          {/* personal button  stacks */}

          <Stack style={{ flexDirection: "row", gap: "7px" }}>
            <Stack
              style={{
                paddingBottom: "3px",
                // borderBottom: flag ? " 2.3px solid #FF8D00" : "none",
              }}
            >
              <Typography
                className="personaldetails"
                style={{
                  fontWeight: "800",
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "20px",
                  underline: "none",
                  position: "relative",
                  top: "10px",
                  left: "27px",
                }}
              >
                Post Details
              </Typography>
            </Stack>
          </Stack>

          <Stack
            style={{ flexDirection: "row", gap: "7px", marginRight: "2rem" }}
          >
            <Stack>
              <button
                className="approve"
                // onClick={handelLogin}
                //  style={{border:"none"}}
                style={{
                  background: "#00FF00",
                  color: "black",
                  border: "black",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                <Typography className="personaldetails">
                  {DateConversion.checkPostStatus(userForm?.validTill)}
                </Typography>
              </button>
            </Stack>

            {/* <Stack>
              <ModalsReject
                data={companyName}
                handelfunction={handelLoginNo}
                hadelreject={handelreject}
                handelCommit={handelCommit}
                setUserForm={setUserForm}
                userForm={userForm}
                buttonList={props.buttonList}
              />
            </Stack>*/}
          </Stack>
        </Stack>

        {/* details stack of users  starts here*/}

        <Stack
          style={{
            padding: "20px",
            background: "white",
            borderRadius: "8px",
            marginTop: "20px",
            // height: "580px",
            position: "relative",
            top: "-24px",
          }}
        >
          <>
            <Stack>
              <Grid container spacing={2}>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="name"
                    label={props.t("Post Id")}
                    autoComplete="something-unsupported"
                    value={userForm?.tagNo}
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    style={{
                      width: "100%",
                      // border: "0.499378px solid #BDBDBD",
                      borderRadius: "5px",
                    }}
                    disabled={true}
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="name"
                    label={props.t("Name")}
                    autoComplete="something-unsupported"
                    value={userForm?.author?.name}
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    style={{
                      width: "100%",
                      // border: "0.499378px solid #BDBDBD",
                      borderRadius: "5px",
                    }}
                    disabled={true}
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <Autocomplete
                    options={["Active", "Inactive"]}
                    value={userForm?.active ? "Active" : "Inactive"}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        label="Active"
                        {...params}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,

                          style: {
                            background: "#FFFFFF",
                            borderRadius: "10px",
                          },
                        }}
                      />
                    )}
                    onChange={(event, value) => {
                      setUserForm({
                        ...userForm,
                        active: value === "Active" ? true : false,
                      });
                    }}
                    sx={{ width: "500px" }}
                  />
                </Grid>
              </Grid>

              {/* second grid  */}
              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },

                  marginTop: "16px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="multiple-limit-tags"
                      options={categorydata}
                      value={userForm?.occupationType}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          label="Work/Business Type"
                          {...params}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,

                            style: {
                              background: "#FFFFFF",
                              borderRadius: "10px",
                            },
                          }}
                        />
                      )}
                      onChange={(event, value) => {
                        setUserForm({
                          ...userForm,
                          occupationType: value,
                        });
                      }}
                      sx={{ width: "500px" }}
                    />
                  </Grid>
                  {/*below is the dropdown for adding tags for post*/}
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="multiple-limit-tags"
                      options={allTags}
                      value={userForm?.tag}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          label="Post/Tag Type"
                          {...params}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,

                            style: {
                              background: "#FFFFFF",
                              borderRadius: "10px",
                            },
                          }}
                        />
                      )}
                      onChange={(event, value) => {
                        setUserForm({
                          ...userForm,
                          tag: value,
                        });
                      }}
                      sx={{ width: "500px" }}
                    />
                  </Grid>

                  {/*tag section  ends here */}
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="multiple-limit-tags"
                      options={sectordata}
                      value={userForm?.sector}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          label="Sector"
                          {...params}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,

                            style: {
                              background: "#FFFFFF",
                              borderRadius: "10px",
                            },
                          }}
                        />
                      )}
                      onChange={(event, value) => {
                        setUserForm({
                          ...userForm,
                          sector: value,
                        });
                      }}
                      sx={{ width: "500px" }}
                    />
                  </Grid>
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <Autocomplete
                      id="validityType"
                      options={[
                        { label: props.t("Permanent"), value: true },
                        { label: props.t("Temporary"), value: false },
                      ]}
                      getOptionLabel={(option) => option.label}
                      value={
                        userForm.isPermanent
                          ? { label: props.t("Permanent"), value: true }
                          : { label: props.t("Temporary"), value: false }
                      }
                      onChange={(event, newValue) => {
                        setUserForm({
                          ...userForm,
                          isPermanent: newValue.value,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={props.t("Validity Type")}
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              background: "#FFFFFF",
                              borderRadius: "10px",
                              width: "100%",
                            },
                          }}
                          autoComplete="something-unsupported"
                          fullWidth
                          style={{
                            width: "100%",
                            borderRadius: "5px",
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Start Date"
                        minDate={new Date()}
                        inputFormat="DD/MM/YYYY"
                        value={userForm?.scheduledTime || null}
                        InputProps={{
                          style: {
                            height: "54px",
                            background: "#FFFFFF",
                            borderRadius: "10px",
                          },
                        }}
                        onChange={(date) => {
                          setUserForm({
                            ...userForm,
                            scheduledTime: date || null,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  {!userForm?.isPermanent && (
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{
                        marginTop: "16px",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          label="End Date"
                          minDate={new Date()}
                          inputFormat="DD/MM/YYYY"
                          value={
                            userForm?.isPermanent ? "-" : userForm?.validTill
                          }
                          InputProps={{
                            style: {
                              height: "54px",
                              background: "#FFFFFF",
                              borderRadius: "10px",
                            },
                          }}
                          disabled={userForm?.isPermanent}
                          onChange={(date) => {
                            setUserForm({
                              ...userForm,
                              validTill: date || null,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  )}
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      id="referenceUrl"
                      InputProps={{
                        style: {
                          background: "#FFFFFF",
                          borderRadius: "10px",
                        },
                      }}
                      label={props.t("Reference Link")}
                      autoComplete="something-unsupported"
                      value={userForm.referenceUrl}
                      fullWidth
                      style={{ width: "100%", borderRadius: "5px" }}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          referenceUrl: event.target.value,
                        })
                      }
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                      label={props.t("Reference Link")} // Add the default label prop
                    />
                  </Grid>
                  <Grid
                    item
                    xl={8}
                    lg={8}
                    md={8}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={places?.map((option, i) => option?.description)}
                      // sx={{ width: 380 }}
                      value={userForm?.fragmentedAddress || ""}
                      // getOptionLabel={(option) => option.description}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleAddressChange}
                          label={"Address"}
                          variant="outlined"
                          // value={addForm.locationName}
                        />
                      )}
                      onChange={(event, value) => {
                        setSelectedOption(value);
                        setUserForm({
                          ...userForm,
                          fragmentedAddress: value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Stack>

              {/* third grid */}
              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },

                  marginTop: "16px",
                }}
              >
                <Grid container spacing={2}>
                  {/*  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Start Time"
                        value={dayjs(userForm?.startTime)}
                        onChange={(newTime) =>
                          setUserForm({
                            ...userForm,
                            startTime: newTime,
                          })
                        }
                        InputProps={{
                          style: {
                            height: "54px",
                            background: "#FFFFFF",
                            borderRadius: "10px",
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid> */}
                </Grid>
              </Stack>

              {/* fourth grid */}
              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },

                  marginTop: "16px",
                }}
              >
                <Grid container spacing={2}>
                  {/* <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <Autocomplete
                      id="range"
                      options={rangeOptions}
                      getOptionLabel={(option) =>
                        option === "max" || !option ? option : `${option} km`
                      }
                      value={userForm.range}
                      onChange={(event, newValue) =>
                        setUserForm({
                          ...userForm,
                          range:
                            newValue !== "max" ? parseInt(newValue) : newValue,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={props.t("Range")}
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              background: "#FFFFFF",
                              borderRadius: "10px",
                            },
                          }}
                          autoComplete="something-unsupported"
                          fullWidth
                          style={{
                            width: "100%",
                            borderRadius: "5px",
                          }}
                          disabled={
                            props.formDialog.divType === "new" ||
                            props.formDialog.divType === "edit"
                              ? false
                              : true
                          }
                        />
                      )}
                    />
                  </Grid> */}
                </Grid>
              </Stack>

              {/* Here we are giving the subject field */}

              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },

                  marginTop: "16px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      multiline
                      fullWidth
                      rows={2}
                      id="description"
                      size="normal"
                      InputProps={{
                        style: {
                          // width: "314%",

                          background: "#FFFFFF",
                          borderRadius: "10px",
                        },
                      }}
                      label={props.t("Subject")}
                      autoComplete="something-unsupported"
                      value={userForm.subject}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          subject: event.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Stack>

              {/* fifth grid */}
              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },

                  marginTop: "16px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      multiline
                      fullWidth
                      rows={2}
                      id="description"
                      size="normal"
                      InputProps={{
                        style: {
                          // width: "314%",

                          background: "#FFFFFF",
                          borderRadius: "10px",
                        },
                      }}
                      label={props.t("Description")}
                      autoComplete="something-unsupported"
                      value={userForm.description}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          description: event.target.value,
                        })
                      }
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },

                  marginTop: "16px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <ImagesUpload
                      setUserForm={setUserForm}
                      userForm={userForm}
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Stack>
                <Grid
                  style={{
                    marginTop: "80px",
                  }}
                >
                  <ReportedBy rowDetails={props?.rowDetails} />
                </Grid>
              </Stack>
            </Stack>
          </>
        </Stack>
      </Stack>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AEVForm)
);
