/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

import {
  Cart24Filled,
  DocumentOnePage24Filled,
  PeopleTeam24Filled,
} from "@fluentui/react-icons";
import { connect } from "react-redux";
import BarGraph from "./components/BarGraph";
import GraphHeader from "./components/GraphHeader";
import RevenueSection from "./components/RevenueSection";
import StatisticsCard from "./components/StatisticsCard";
import useServices from "./hooks/useServices";
import { useStyles } from "./styles/style";
import LocalStorage from "../../../../config/LocalStorage";
import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import { Separator } from "@fluentui/react";
import WelcomeSection from "./components/WelcomeSection";
import StatsAndGraph from "./components/StatsAndGraph";

const statsConstData = [
  {
    color: "linear-gradient(to right, #005CEA, #12B1DE)",
    label: "Users",
    icon: <PeopleTeam24Filled style={{ color: "#ffff" }} />,
  },
  {
    color: "linear-gradient(to right, #F84E6B, #F67086)",
    label: "Plans",
    icon: <DocumentOnePage24Filled style={{ color: "#ffff" }} />,
  },
  {
    color: "linear-gradient(to right, #F66B2F, #EFA55F)",
    label: "Subscriptions",
    icon: <Cart24Filled style={{ color: "#ffff" }} />,
  },
]; //constant

function getEpoch30DaysBack(epochInSeconds) {
  const secondsIn30Days = 30 * 24 * 60 * 60; // 30 days in seconds
  return epochInSeconds - secondsIn30Days;
}

const graphFilter = {
  dateType: "day", //year , day , month , week
  module: "users", // orders
  startDate: getEpoch30DaysBack(Math.floor(new Date() / 1000)),
  endDate: Math.floor(new Date() / 1000),
  state: "",
}; //constant

const pieChartFilter = {
  startDate: null,
  endDate: Math.floor(new Date() / 1000),
  moduleType: "looms",
};

//constant
function Dashboard(props) {
  const classes = useStyles();

  const [graphFiltersData, setGraphFiltersData] = useState({ ...graphFilter });
  const [pieChartFiltersData, setPieChartFiltersData] = useState({
    ...pieChartFilter,
  });

  console.log(graphFiltersData, "graphFiltersData");

  const services = useServices({ graphFiltersData, pieChartFiltersData });
  console.log(services?.statisticsData, "statisticsData");

  return (
    <div
      style={{
        overflowY: "scroll",
      }}
    >
      <WelcomeSection />

      <Box className={classes.mainDashboardContainer}>
        <Box className={classes.dashBoardStatAndGraphContainer}>
          <StatsAndGraph
            setGraphFiltersData={setGraphFiltersData}
            graphFiltersData={graphFiltersData}
            services={services}
          />
        </Box>
        <Box className={classes.totalRevenueContainer}>
          <RevenueSection
            classes={classes}
            services={services}
            setPieChartFiltersData={setPieChartFiltersData}
            pieChartFiltersData={pieChartFiltersData}
          />
        </Box>
      </Box>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(Dashboard)
);
