import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
// import TextField from "material-ui/TextField";
import {
  Box,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaymentDialogOpen(props) {
  const [amount, setAmount] = useState(null);
  // useEffect(() => {
  //   if (props.title === "Seeker Refund") {
  //     setAmount(props.totalAmount);
  //   } else if (props.amount) {
  //     setAmount(props.amount);
  //   }
  //   // if (props.amount) {

  //   // }
  // }, [props.amount, props.totalAmount]);
  console.log(props);
  useEffect(() => {
    if (props.slideInDialog) {
      if (props.title === "Seeker Refund") {
        setAmount((props.totalAmount - 50).toFixed(2));
        // setAmount(props.totalAmount - 50);
      } else if (props.amount) {
        setAmount(parseFloat(props.amount).toFixed(2));
        // setAmount(props.amount);
      }
    }
  }, [props.slideInDialog, props.title, props.amount, props.totalAmount]);

  return (
    <div>
      <Dialog
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}
        open={props.slideInDialog}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => props.setSlideInDialog(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {props.title !== undefined && props.title !== null ? (
          <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
        ) : (
          <></>
        )}
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description">
            {props.contentComponent}
          </DialogContentText> */}
          {/* <Grid item xs={4}> */}
          <label
            style={{
              fontSize: "16px",
              fontWeight: 600,
              padding: "6px",
            }}
          >
            Enter Amount
          </label>
          <TextField
            fullWidth
            variant="outlined"
            style={{
              //   height: "40px",
              //   borderRadius: "8px",
              //   border: "1px solid #E5E5E5",
              width: "100%",
              padding: "6px",
            }}
            placeholder="Eg: RS 2400"
            type="number"
            value={amount}
            onChange={(e) => {
              const value = e.target.value;
              // if (/^\d*$/.test(value)) {
              setAmount(value);
              // }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      <Box>
                        <Typography>
                          Total Amount: ₹{props.totalAmount}
                        </Typography>
                        <hr style={{ width: "100%" }} />
                        <Typography>
                          Provider Amount: ₹{props.amount}
                        </Typography>
                        <Typography>GST Amount: ₹{props.gstAmount}</Typography>
                        <Typography>Convenience Fee: ₹50</Typography>
                      </Box>
                    }
                    arrow
                    placement="bottom"
                  >
                    <Info style={{ cursor: "pointer", color: "#666" }} />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          {/* </Grid> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.setSlideInDialog(false)} color="primary">
            {props.t("Cancel")}
          </Button>
          <Button
            onClick={() => props.handlePaymentRequest(amount)}
            color="primary"
          >
            {props.t("Continue")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default withTranslation("translations")(PaymentDialogOpen);
