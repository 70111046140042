import { useEffect, useState } from "react";
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";

const list = {
  infotainmentDuration: null,
  requirementDuration: null,
  salesDuration: null,
  servicesAmount: null,
  serviceDuration: null,
  loomsAmount: null,
  loomsDuration: null,
};

const useLoomHook = (userId, props, handleSlideInDialog, handleClose) => {
  console.log(userId);
  const [loomList, setLoomList] = useState([]);
  const [userForm, setUserForm] = useState(list);

  const [createData, setCreateData] = useState({
    loomRegistrationNo: "",
    loomNo: "",
    loomType: "",
    description: "",
    images: [],
  });

  const [loading, setLoading] = useState(true); // Loading state

  const listLooms = async (keyword) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.userLoomsList,
        JSON.stringify({ userId: userId, keyword })
      );
      if (response?.data?.responseCode === 109) {
        setLoomList(response?.data?.rows);
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    } finally {
      setLoading(false); // Stop loading after fetch
    }
  };
  useEffect(() => {
    listLooms();
  }, []);

  const handleDeleteRequest = (response, recordId) => {
    if (response === "agree") {
      APIRequest.request(
        "POST",
        ConfigAPIURL.loomDelete,
        JSON.stringify({ recordIds: [recordId] })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            // listLooms();
            SnackbarUtils.sucess(
              props.t("snackbar.delete"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            handleSlideInDialog();
            listLooms();
          }
        }
      });
    } else {
      handleSlideInDialog();
      //   setRowDetails(null);
      //   setOpenForm({ status: false, title: "", divType: "", functionName: "" });
    }
  };

  const getLoomDetails = (recordId) => {
    let url = ConfigAPIURL.loomDetails;
    let method = "POST";

    APIRequest.request(
      method,
      url,
      JSON.stringify({
        recordId: recordId,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          let loomDetails = response?.data?.result;
          setCreateData({
            loomRegistrationNo: loomDetails?.loomRegistrationNo,
            loomNo: loomDetails?.loomNo,
            loomType: loomDetails?.loomType,
            images: loomDetails?.images,
            description: loomDetails?.description,
          });
        }
        if (response.code === 100 && response.data.responseCode === 114) {
          SnackbarUtils.error(
            "Something went wrong! Please try again later.",
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        }
      }
    });
  };

  const sendToServer = (recordId) => {
    let url = ConfigAPIURL.loomUpdate;
    let method = "POST";

    APIRequest.request(
      method,
      url,
      JSON.stringify({
        ...createData,
        recordId: recordId,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          SnackbarUtils.sucess(
            "Loom updated successfully!",
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
          listLooms();
          handleClose();
        }
        if (response.code === 100 && response.data.responseCode === 114) {
          SnackbarUtils.error(
            "Something went wrong! Please try again later.",
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        }
      }
    });
  };

  //   const updateSettings = async (userForm) => {
  //     try {
  //       const response = await APIRequest.request(
  //         "POST",
  //         ConfigAPIURL.updateSettings,
  //         JSON.stringify(userForm)
  //       );
  //       if (response?.data?.responseCode === 109) {
  //         console.log("Settings List:", response);
  //         listSettings();
  //         SnackbarUtils.sucess(
  //           props.t("snackbar.updatedSuccess"),
  //           "bottomCenter",
  //           3000
  //         ).then((notification) => {
  //           props.publishNotification(notification);
  //         });
  //         return;
  //       }
  //     } catch (error) {
  //       console.error("Error fetching settings:", error);
  //       throw error; // Optionally re-throw the error if needed
  //     }
  //   };

  return {
    loomList,
    handleDeleteRequest,
    createData,
    setCreateData,
    getLoomDetails,
    sendToServer,
    listLooms,
  };
};
export default useLoomHook;
