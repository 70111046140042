import { data } from "jquery";

class TableHeader {
  /* -------------------------------- INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER --------------------------------
      
          id:- Use for uniquely identify each column in data table,
          accessor: - Use to locate the record from json objcet, If want to access the record from the child, use . operator.
                      Example:- 
                                  1> name, is from parent, ( {name:'name'} )
                                  2> owner.name, where owner is parent & name is inside owner object, ( owner:{name:'name'} )
          dataType:- This value can be string, number, boolean, date. This use for alignment of table header & table body.
                      1> string & date - left (handle in DataTable.js)
                      2> boolean- center (handle in DataTable.js)
                      3> number-  right (handle in DataTable.js)
          sortable:- Use to enable & disable sorting for perticuler column.
          disablePadding:- Use to enable padding.
          visibility:- Use to toggle column visibility.
          width:- Use to set individual column min-width in px. It's should be number format.
          label:- Refer the values from translations file, (./src/translations/**.js).
          className:- It's basically the js file name & all the file should be available in utils folder.
          functionName:-  It's basically the function name, which should be available inside above className 
          
      ----------------------------- END OF INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER -----------------------------*/

  static adminTableHeader = [
    {
      id: "tagNo",
      accessor: "orderTag",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      // width: 50,
      label: "Order Id",
      className: "",
      functionName: "",
    },

    {
      id: "providerName",
      accessor: "serviceDetails.user.name",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      label: "Provider Name",
      className: "",
      functionName: "",
    },

    {
      id: "userMobileNo",
      accessor: "serviceDetails.user.mobileNo",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      // width: "50px",
      label: "Mobile No",
      className: "",
      functionName: "",
    },
    {
      id: "name",
      accessor: "seekerId.name",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      label: "Seeker Name",
      className: "",
      functionName: "",
    },

    {
      id: "mobileNo",
      accessor: "seekerId.mobileNo",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      // width: "50px",
      label: "Seeker MobileNo",
      className: "",
      functionName: "",
    },
    {
      id: "amount",
      accessor: "serviceAmount",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 50,
      label: "Amount",
      className: "",
      functionName: "",
    },
    {
      id: "status",
      accessor: "adminListStatus",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: "150px",
      label: "Status",
      className: "",
      functionName: "",
    },

    {
      id: "createdBy",
      accessor: "createdBy.name",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      // width: "50px",
      label: "Created By",
      className: "",
      functionName: "",
    },
    {
      id: "createdAt",
      accessor: "createdAt",
      dataType: "date",
      sortable: true,
      disablePadding: false,
      visibility: true,
      label: "nekaramitrausers.createdAt",
      className: "DateConversion",
      functionName: "unixToLocaleDate",
    },
    {
      id: "updatedAt",
      accessor: "updatedAt",
      dataType: "date",
      sortable: true,
      disablePadding: false,
      visibility: true,
      label: "nekaramitrausers.updatedAt",
      className: "DateConversion",
      functionName: "unixToLocaleDate",
    },
    // {
    //   id: "scheduledTime",
    //   accessor: "scheduledTime",
    //   dataType: "Date",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,

    //   // width: "100px",
    //   label: "Start Date",
    //   className: "DateConversion",
    //   functionName: "unixToLocaleDate",
    // },

    // {
    //   id: "validTill",
    //   accessor: "validTill",
    //   dataType: "date",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   label: "End Date",
    //   className: "DateConversion",
    //   functionName: "unixToLocaleDate",
    // },

    // {
    //   id: "status",
    //   accessor: "status",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,

    //   label: "Status",
    //   className: "",
    //   functionName: "",
    // },
  ];
}

export default TableHeader;
