import React, { useState } from "react";
import useImageUpload from "./useImageUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Stack, Typography } from "@mui/material";

const ImagesUpload = ({ setUserForm, userForm, label, value, disabled }) => {
  const { onUpload, deleteImages } = useImageUpload(setUserForm, userForm);

  const handleDelete = () => {
    setUserForm({
      ...userForm,
      [value]: null, // Clear the specific field
    });
  };

  return (
    <Stack style={{ gap: "4px", width: "100%" }}>
      <Typography
        variant="subtitle1"
        style={{
          position: "relative",
          fontWeight: "bold",
          fontSize: "13px",
        }}
      >
        {label}
        <span style={{ color: "red" }}>*</span>
      </Typography>
      <Stack
        horizontal
        horizontalAlign="space-between"
        style={{
          background: "white",
          padding: "16px",
          height: "142px",
          borderRadius: "8px",
          gap: "8px",
          border: "1px solid #E5E5E5",
          overflowX: "auto",
        }}
      >
        <Stack horizontal>
          {/* Display existing attachment */}
          {userForm?.[value] && (
            <Stack style={{ position: "relative" }}>
              <Stack
                verticalAlign="center"
                horizontalAlign="center"
                style={{
                  width: "28px",
                  height: "28px",
                  background: "white",
                  position: "absolute",
                  borderRadius: "100%",
                  top: -8,
                  right: -5,
                  cursor: disabled ? "not-allowed" : "pointer",
                }}
                onClick={!disabled && handleDelete}
              >
                <DeleteIcon />
              </Stack>
              {userForm?.[value].endsWith(".pdf") ? (
                <img
                  src="https://cdn-icons-png.flaticon.com/512/337/337946.png"
                  style={{
                    width: "100px",
                    height: "100px",
                    cursor: "pointer",
                  }}
                  alt="PDF Icon"
                  onClick={() => window.open(userForm?.[value], "_blank")}
                />
              ) : (
                <img
                  src={userForm?.[value]}
                  style={{
                    borderRadius: "10px",
                    objectFit: "cover",
                    cursor: "pointer",
                    width: "100px",
                    height: "100px",
                  }}
                  alt="Uploaded File"
                  onClick={() => window.open(userForm?.[value], "_blank")}
                />
              )}
            </Stack>
          )}
        </Stack>

        {/* Show upload icon only if no attachment is present */}
        {!userForm?.[value] && (
          <Stack>
            <label htmlFor={value}>
              <CloudUploadOutlinedIcon style={{ cursor: "pointer" }} />
            </label>
            <input
              accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
              id={value}
              type="file"
              style={{ display: "none" }}
              onChange={(e) => onUpload(e, value)}
              disabled={disabled}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default ImagesUpload;
