import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Box, Grid, Input, makeStyles, Typography } from "@material-ui/core";
import ThemeSetting from "./theme";
import { Label, TextField } from "@fluentui/react";
import Button from "@mui/material/Button";
import useHook from "./hook";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  Setting: {
    fontSize: "28px",
    fontWeight: 700,
    padding: "16px",
  },
  label: {
    fontSize: "16px",
    fontWeight: 600,
    padding: "6px",
  },
  textField: {
    height: "40px",
    borderRadius: "8px",
    border: "1px solid #E5E5E5",
    width: "100%",
    padding: "6px",
  },
  btnBox: {
    textAlign: "center",
    marginTop: "50px",
  },
}));

function Setting(props) {
  console.log(props);
  const classes = useStyles();

  const { listSettings, userForm, setUserForm, updateSettings, loading } =
    useHook(props);
  console.log(userForm);

  const handleSubmit = () => {
    const requiredFields = [
      "infotainmentDuration",
      "requirementDuration",
      "salesDuration",
      "servicesAmount",
      "serviceDuration",
      "loomsAmount",
      "loomsDuration",
    ];

    const isFormValid = requiredFields.every(
      (field) => userForm[field]?.toString().trim() !== ""
    );

    if (!isFormValid) {
      SnackbarUtils.error(
        props.t("Please fill all the required fields"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }
    updateSettings(userForm);
  };

  if (loading) {
    return <div>Loading...</div>; // Display a loader while data is being fetched
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.Setting}>Settings</Typography>
      <Grid container spacing={3}>
        {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <ThemeSetting />
        </Grid> */}
        <Grid item xs={4}>
          <label className={classes.label}>
            Infotainment Post Duration<span style={{ color: "red" }}>*</span>
          </label>
          <input
            className={classes.textField}
            placeholder="Eg: 2 (days)"
            value={userForm?.infotainmentDuration || ""}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setUserForm((prev) => ({
                  ...prev,
                  infotainmentDuration: value,
                }));
              }
              {
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <label className={classes.label}>
            Requirement Post Duration<span style={{ color: "red" }}>*</span>
          </label>
          <input
            className={classes.textField}
            placeholder="Eg: 2 (days)"
            value={userForm?.requirementDuration}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setUserForm((prev) => ({
                  ...prev,
                  requirementDuration: value,
                }));
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <label className={classes.label}>
            Sales Post Duration<span style={{ color: "red" }}>*</span>
          </label>
          <input
            className={classes.textField}
            placeholder="Eg: 2 (days)"
            value={userForm?.salesDuration}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setUserForm((prev) => ({
                  ...prev,
                  salesDuration: value,
                }));
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <label className={classes.label}>
            Services Amount<span style={{ color: "red" }}>*</span>
          </label>
          <input
            className={classes.textField}
            placeholder="Eg: RS 2400"
            value={userForm?.servicesAmount}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setUserForm((prev) => ({
                  ...prev,
                  servicesAmount: value,
                }));
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <label className={classes.label}>
            Services Duration<span style={{ color: "red" }}>*</span>
          </label>
          <input
            className={classes.textField}
            placeholder="Eg: 365 (days)"
            value={userForm?.serviceDuration}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setUserForm((prev) => ({
                  ...prev,
                  serviceDuration: value,
                }));
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <label className={classes.label}>
            Looms Amount<span style={{ color: "red" }}>*</span>
          </label>
          <input
            className={classes.textField}
            placeholder="Eg: RS 2400"
            value={userForm?.loomsAmount}
            type="number"
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setUserForm((prev) => ({
                  ...prev,
                  loomsAmount: value,
                }));
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <label className={classes.label}>
            Looms Duration<span style={{ color: "red" }}>*</span>
          </label>
          <input
            className={classes.textField}
            placeholder="Eg: 365 (days)"
            value={userForm?.loomsDuration}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setUserForm((prev) => ({
                  ...prev,
                  loomsDuration: value,
                }));
              }
            }}
          />
        </Grid>
      </Grid>
      <Box className={classes.btnBox}>
        <Button variant="contained" onClick={handleSubmit}>
          Save
        </Button>
      </Box>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
// export default withTranslation("translations")(Setting);
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(Setting)
);
