import React, { useEffect, useState, useRef } from "react";
import { Modal } from "antd";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Tooltip,
  Checkbox,
  IconButton,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import SlideInDialog from "../../../../templates/dialog/SlideInDialog";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { connect } from "react-redux";
import { MdDeleteSweep, MdCloudUpload } from "react-icons/md";
import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import LocalStorage from "../../../../config/LocalStorage";
import FileUploadTableCheckBox from "../../../../templates/FileUploadTableCheckBox";
import FileUpload from "../../../../templates/FileUpload";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import FormValidation from "../../../../utils/FormValidation";
import Toaster from "../../../../utils/Toaster";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Box from "@mui/material/Box";
import imageCompression from "browser-image-compression";
// import landingImg from "../../../../../assets/images/Pic.png";
import DateConversion from "../../../../utils/DateConversion";
// import "./classifiedsPro.scss";
import { Stack } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";

import { styled } from "@mui/material/styles";
import { IoIosInformationCircle } from "react-icons/io";
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
    marginTop: 60,
  },
  paper: {
    padding: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },

  formGrid: {
    marginBottom: 10,
  },
}));
const addFormDetails = {
  title: "",
  sector: {
    handloom: false,
    powerloom: false,
  },
  occupationType: "",

  logo: "",
  active: true,
  // image:""
};
function AEVForm(props) {
  const classes = useStyles();
  const [addForm, setAddForm] = useState(addFormDetails);
  const [bannerListVal, setBannerListVal] = useState({
    image: "",
    siteLink: "",
    searchKey: [],
    category: "",
  });
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [logoUploadStatus, setLogoUploadStatus] = useState(false);
  const [galleryFileBase64, setGalleryFileBase64] = useState([]);

  const [bannerCheckBox, setBannerCheckBox] = useState([]);

  const [aws] = useState(LocalStorage.aws);
  const [attachmentsFileBase64, setattachmentsFileBase64] = useState([]);
  const [file, setFile] = useState("");
  const [fileUploadStatus, setFileUploadStatus] = useState(false);

  const bannerFileUpload = useRef();

  const galleryFileUpload = useRef();

  const [adPositionOptions, setAdPositionOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [checked, setChecked] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [handloom, setHandloom] = useState(addFormDetails.sector);
  const [handloomchecked, setHandloomChecked] = useState(false);
  const [powerloomchecked, setPowerloomChecked] = useState(false);

  // const handelChange = (event, i) => {
  //   let handloomdata = [...addForm?.sector];
  //   handloomdata[0] = event.target.value;
  //   // handloomdata[0]===false;
  //   // handloomdata.splice(0,1)
  //   if (handloomdata[0] === false) {
  //     return handloomdata.splice(0, 1);
  //   }
  //   setAddForm({
  //     ...addForm,
  //     sector: handloomdata,
  //   });
  //   // if(userForm?.sector[0]===false){
  //   //   return(
  //   //     userForm?.sector[0].splice(0,1)
  //   //   )
  //   // }
  //   // if(userForm?.sector[1]===false){
  //   //   return(
  //   //     userForm?.sector[1].splice(1,1)
  //   //   )
  //   // }
  // };
  const handleChange = (event, i) => {
    if (i == 1) {
      if (!handloomchecked) {
        handloom.handloom = event;
        setHandloomChecked(!handloomchecked);
      } else {
        handloom.handloom = event;

        setHandloomChecked(!handloomchecked);
      }
    }

    if (i == 2) {
      if (!powerloomchecked) {
        handloom.powerloom = event;
        setPowerloomChecked(!powerloomchecked);
      } else {
        handloom.powerloom = event;
        // if(handloom[1]=""){
        //   handloom[1].splice(0,1)
        // }
        setPowerloomChecked(!powerloomchecked);
      }
    }
    setHandloom(handloom);
    // setUserForm({ ...userForm });
  };
  useEffect(() => {
    getCategoryOptions();
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
      // classifides()
    }
  }, [props.formDialog]);

  const resetForm = () => {
    setAddForm(addFormDetails);
    setGalleryFileBase64([]);
    setBannerListVal({ image: "", siteLink: "", searchKey: [], category: "" });
  };

  const [moreInfoOpen, setMoreInfoOpen] = useState(false);

  const handleTooltipOpen = () => {
    setMoreInfoOpen(true);
  };

  const getCategoryOptions = () => {
    //  APIRequest.request(
    //    'POST',
    //    ConfigAPIURL.getOptions,
    //    JSON.stringify({ name: 'category' })
    //  ).then((response) => {
    //    if (response !== undefined && response !== null) {
    //      if (
    //        response.data.responseCode !== undefined &&
    //        response.data.responseCode !== null
    //      ) {
    //        if (response.data.responseCode === 109) {
    //          setCategoryOptions(response.data.options[0].options);
    //        }
    //      }
    //    }
    //  });
  };

  const handleTooltipClose = () => {
    setMoreInfoOpen(false);
  };

  const handleGalleryImage = (event) => {
    if (
      aws !== "" &&
      event.target.files[0] !== undefined &&
      event.target.files[0] !== null
    ) {
      if (
        event.target.files[0].type.endsWith("jpeg") ||
        event.target.files[0].type.endsWith("png")
      ) {
        [...event.target.files].map(async (data) => {
          let img = new Image();

          img.src = window.URL.createObjectURL(data);

          img.onload = () => {
            // if (img.width !== 436) {
            //   SnackbarUtils.error(
            //     props.t('advertisements.imageWidthRequired') + img.width,
            //     'bottomCenter',
            //     3000
            //   ).then((notification) => {
            //     props.publishNotification(notification);
            //   });
            //   return;
            // }
            // if (img.height !== 224) {
            //   SnackbarUtils.error(
            //     props.t('advertisements.imageHeightRequired') + img.height,
            //     'bottomCenter',
            //     3000
            //   ).then((notification) => {
            //     props.publishNotification(notification);
            //   });
            //   return;
            // }

            let reader = new FileReader();
            let url = "";
            if (data.size < 1000000) {
              url = reader.readAsDataURL(data);
            } else {
              SnackbarUtils.error(
                props.t("snackbar.fileLarge"),
                "bottomCenter",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
              return;
            }
            SnackbarUtils.sucess(
              props.t("snackbar.uploadReady"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });

            reader.onloadend = function () {
              setGalleryFileBase64(reader.result.split("base64,")[1]);
              setFile(data);
              setFileUploadStatus(true);
              galleryFileUpload.current._s3Upload();
            }.bind(this);
          };

          return;
        });
      } else {
        setFileUploadStatus(false);
        setGalleryFileBase64("");
        setFile("");
        SnackbarUtils.error(
          props.t("snackbar.onlyImage"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } else {
      setFileUploadStatus(false);
      setGalleryFileBase64("");
      setFile("");
      SnackbarUtils.error(
        props.t("snackbar.errorUploading"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  const imageTableRowDetails = (selected) => {
    if (selected !== undefined && selected !== null) {
      setSelectedImages(selected);
    }
  };
  const handleLogoUpload = (event) => {
    console.log("krishna");
    if (event.target.files[0] !== undefined && event.target.files[0] !== null) {
      setLogoUploadStatus(true);
      [...event.target.files].map(async (data) => {
        let img = new Image();
        img.src = window.URL.createObjectURL(data);
        console.log(img, "img data");

        img.onload = async () => {
          let formData = new FormData();
          if (data.size > 3000000) {
            SnackbarUtils.error(
              props.t("snackbar.fileLarge"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }

          // if (data.size > 300000) {
          //   var options = {
          //     maxSizeMB: 1,
          //     maxWidthOrHeight: 1920,
          //     useWebWorker: true,
          //   };

          //   let compressedFile = await imageCompression(
          //     event?.target?.files[0],
          //     options
          //   );

          //   formData.append(`attachment`, compressedFile);
          // } else {
          //   formData.append(`attachment`, data);
          // }
          formData.append(`attachment`, data);

          formData.append("bucketName", "profile");

          APIRequest.multipartForDataRequest(
            "PUT",
            ConfigAPIURL.uploadFile,
            formData
          ).then((response) => {
            console.log(response, "response");
            if (response !== undefined && response !== null) {
              setTimeout(function () {
                setLogoUploadStatus(false);
                setAddForm({
                  ...addForm,
                  logo: response.data.attachmentUrl[0],
                });
              }, 1000);
              console.log(addForm);
            } else {
              setLogoUploadStatus(false);
            }
          });
        };
      });
    } else {
      setLogoUploadStatus(false);
      SnackbarUtils.error(
        props.t("snackbar.errorUploading"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };
  const _s3GalleryImageUrl = (url) => {
    let user = addForm;
    user.images.push(url);
    setAddForm(user);
    SnackbarUtils.sucess(
      props.t("snackbar.successUpload"),
      "bottomCenter",
      3000
    ).then((notification) => {
      props.publishNotification(notification);
    });
    setFileUploadStatus(false);
    setGalleryFileBase64("");
    setFile("");
  };

  const _deleteS3Files = (event) => {
    let form = addForm;
    selectedImages.map((selectedimages, idx) => {
      let index = form.images.indexOf(selectedimages);
      if (index !== -1) {
        form.images.splice(index, 1);
      }
    });
    setAddForm({ ...addForm, form });
  };

  const handleCategoryOptions = (event) => {
    setAddForm({
      ...addForm,
      category: event.target.value,
    });
  };
  const handleBannerCategoryOptions = (event) => {
    setBannerListVal({
      ...bannerListVal,
      category: event.target.value,
    });
  };

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "GET",
        ConfigAPIURL.categoryId + "?recordId=" + `${props.rowDetails._id}`,
        ""
      ).then((response) => {
        console.log(response);
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let userResult = response?.data?.rows;
            console.log(userResult);
            //  userResult.startAt = new Date(userResult.startAt * 1000);
            //  userResult.endAt = new Date(userResult.endAt * 1000);
            // userResult.searchKey =
            //   userResult.searchKey !== ''
            //     ? userResult.searchKey.split(',')
            //     : [];
            if (userResult?.sector[0] == "handloom") {
              setChecked(true);
            }
            if (userResult?.sector[1] == "powerloom") {
              setChecked1(true);
            }
            setAddForm({
              ...addForm,

              title: userResult.title,
              occupationType: userResult?.occupationType,
              sector: userResult?.sector,
              active: userResult?.active,
              // typeOfLink: userResult.typeOfLink,

              logo: userResult?.logo,
            });
            // setSelectedRowId(userResult._id)
            // setAddForm(userResult)
            // console.log(userResult)
          }
        }
      });
    }
  };
  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        handleChange();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    let url = ConfigAPIURL.categorycreate;
    let method = "POST";

    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.categoryupdate;
      addForm["recordId"] = props.rowDetails?._id;
      // addForm.sector=handloom
      method = "PUT";
    }

    console.log(addForm, handloom);

    let fieldValidation = ["occupationType", "sector", "logo"];
    // addForm.typeOfLink === 'external'
    //   ? ['title', 'endAt', 'startAt', 'typeOfLink', 'siteLink']
    //   : addForm.typeOfLink === 'internal'
    //   ? ['title', 'searchKey', 'endAt', 'category', 'startAt', 'typeOfLink']
    //   : ['title', 'typeOfLink'];
    FormValidation.validation(fieldValidation, addForm).then(
      (ValidationResult) => {
        if (ValidationResult) {
          // if (
          //   (addForm.typeOfLink === 'internal' ||
          //     addForm.typeOfLink === 'external') &&
          //   addForm.images.length < 1
          // ) {
          //   Toaster.error(props.t('toaster.uploadImages'), 'topRight');
          //   return;
          // }
          let productIds = [];
          if (addForm.typeOfLink === "product") {
            addForm.products.map((productsSelected) => {
              productIds.push(productsSelected._id);
            });
          }

          if (!addForm.sector.handloom && !addForm.sector.powerloom) {
            SnackbarUtils.error(
              props.t("Please select at least one sector."),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            return;
          }
          console.log(addForm, "addForm");
          APIRequest.request(method, url, JSON.stringify(addForm)).then(
            (response) => {
              console.log(response);
              if (response !== undefined && response !== null) {
                if (
                  response.code === 100 &&
                  response.data.responseCode === 109
                ) {
                  if (props.formDialog.divType === "new") {
                    SnackbarUtils.sucess(
                      props.t("snackbar.savedSuccess"),
                      "bottomCenter",
                      3000
                    ).then((notification) => {
                      props.publishNotification(notification);
                    });
                  } else {
                    SnackbarUtils.sucess(
                      props.t("snackbar.updatedSuccess"),
                      "bottomCenter",
                      3000
                    ).then((notification) => {
                      props.publishNotification(notification);
                    });
                  }
                  props.handleFormDialog();
                  props.tableQuery({
                    keyword: "",
                    page: 0,
                    pageSize: 10,
                    sortField: "",
                    userType: "all",
                    sortOrder: "false",
                    active: true,
                    status: "all",
                  });
                  props.setQuery({
                    active: true,
                    status: "all",
                    pageSize: 10,
                    page: 0,
                    startDate: null,
                    endDate: null,
                  });
                  setAddForm(addFormDetails);
                }
                if (
                  response.code === 100 &&
                  response.data.responseCode === 114
                ) {
                  SnackbarUtils.error(
                    props.t("Category Name Already exists"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                }
              }
            }
          );
        }
      }
    );
  };

  const handleBannerImage = (event) => {
    if (aws !== "") {
      [...event.target.files].map(async (data) => {
        let reader = new FileReader();
        let url = "";
        if (data.size < 3000000) {
          url = reader.readAsDataURL(data);
        } else {
          SnackbarUtils.error(
            props.t("snackbar.fileLarge"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });

          return;
        }
        SnackbarUtils.sucess(
          props.t("snackbar.uploadReady"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });

        reader.onloadend = function () {
          // SnackbarUtils.error('Error uploading file',   'bottomCenter', 3000).then(
          //   (notification) => {
          //     props.publishNotification(notification);
          //   }
          // );
          setattachmentsFileBase64(reader.result.split("base64,")[1]);
          setFile(data);
          setFileUploadStatus(true);
          bannerFileUpload.current._s3Upload();
        }.bind(this);
      });
    } else {
      setFileUploadStatus(false);
      setattachmentsFileBase64("");
      setFile("");
      SnackbarUtils.error(
        props.t("snackbar.errorUploading"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  const _s3BannerImageUrl = (url) => {
    setFileUploadStatus(false);
    setattachmentsFileBase64("");
    setFile("");
    setBannerListVal({
      ...bannerListVal,
      image: url,
    });
    SnackbarUtils.sucess(
      props.t("snackbar.successUpload"),
      "bottomCenter",
      3000
    ).then((notification) => {
      props.publishNotification(notification);
    });
  };

  const handleBannerPush = () => {
    let fieldValidation = ["image", "siteLink", "searchKey", "category"];
    FormValidation.validation(fieldValidation, bannerListVal).then(
      (ValidationResult) => {
        if (ValidationResult) {
          let adform = addForm;
          adform.bannerList.push(bannerListVal);
          let bannercheckbox = bannerCheckBox;
          bannercheckbox.push(false);
          setBannerListVal({ ...bannerCheckBox }, bannercheckbox);
          setAddForm(adform);
          setBannerListVal({
            ...bannerListVal,
            image: "",
            siteLink: "",
            searchKey: [],
            category: "",
          });
        }
      }
    );
  };

  const handleCheckBox = (event, index) => {
    let bannerCheck = bannerCheckBox;
    bannerCheck[index] = event.target.checked;
    setBannerCheckBox([...bannerCheckBox], bannerCheck);
  };

  const handleDeleteBanner = () => {
    let form = addForm;
    bannerCheckBox.map((banners, index) => {
      if (banners) {
        delete form.bannerList[index];
      }
    });
    let temp = [];
    form.bannerList.map((banner, idx) => {
      if (banner) {
        temp.push(banner);
      }
    });

    form.bannerList = temp;
    setBannerCheckBox([false]);

    setAddForm({ ...addForm, form });
  };

  const handleStartAtDateChange = (date) => {
    if (
      Math.floor(date.getTime()) ===
      (addForm.endAt.setSeconds(0), addForm.endAt.setMilliseconds(0))
    ) {
      SnackbarUtils.error(
        props.t("snackbar.cannotBeEqual"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }
    if (
      Math.floor(date.getTime()) >
      (addForm.endAt.setSeconds(0), addForm.endAt.setMilliseconds(0))
    ) {
      SnackbarUtils.error(
        props.t("toaster.startDateLesser"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }
    setAddForm({
      ...addForm,
      startAt: date,
    });
  };
  const handleEndAtDateChange = (date) => {
    if (
      Math.floor(date.getTime()) ===
      (addForm.startAt.setSeconds(0), addForm.startAt.setMilliseconds(0))
    ) {
      SnackbarUtils.error(
        props.t("snackbar.cannotBeEqual"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }
    if (
      Math.floor(date.getTime()) <
      (addForm.startAt.setSeconds(0), addForm.startAt.setMilliseconds(0))
    ) {
      SnackbarUtils.error(
        props.t("toaster.endDateLesser"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }
    setAddForm({
      ...addForm,
      endAt: date,
    });
  };

  const handleDelete = () => {
    setAddForm((prev) => ({
      ...prev,
      logo: "",
    }));
  };
  console.log(addForm);
  return (
    <div className={classes.root}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container>
              <Grid
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: "20px", marginTop: "9px" }}

                // className={classes.formGrid}
              >
                <TextField
                  id="occupationType"
                  label={props.t("nekaramitracategory.category")}
                  autoComplete="off"
                  variant="outlined"
                  required
                  value={addForm.occupationType}
                  fullWidth
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      occupationType: event.target.value,
                    })
                  }
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                />
              </Grid>

              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: "20px" }}
              >
                <TextField
                  variant="outlined"
                  id="active"
                  fullWidth
                  label={props.t("user.active")}
                  //  className={classes.userTypeMenuItem}
                  select
                  style={{
                    marginTop: "6px",
                  }}
                  // required

                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                  defaultValue={addForm?.active}
                  value={addForm?.active}
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      active: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={true}>
                    {props.t("user.active")}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    {props.t("user.inactive")}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: "20px" }}
              >
                {/* <TextField
            variant="outlined"
            id="sector"
            fullWidth
            label={props.t("nekaramitracategory.sector")}
            //  className={classes.userTypeMenuItem}
            select
            style={{
              marginTop: "6px",
            }}
            // required
            disabled={
              props.formDialog.divType === "new" ||
              props.formDialog.divType === "edit"
                ? false
                : true
            }
            
            defaultValue={addForm?.sector}
            value={addForm?.sector}
            onChange={(event) =>
              setAddForm({
                ...addForm,
                sector: event.target.value,
              })
            }
          >
            <MenuItem key={0} value={"handloom"}>
              {props.t("nekaramitracategory.handloom")}
            </MenuItem>
            <MenuItem key={1} value={"powerloom"}>
              {props.t("nekaramitracategory.powerloom")}
            </MenuItem>
            
          </TextField> */}
                {props.formDialog.divType === "new" ? (
                  <Box
                    style={{
                      width: "100%",
                      height: "58px",
                      border: "0.2px solid rgba(0, 0, 0, 0.26)",
                      marginTop: "5px",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography
                      style={{
                        position: "absolute",
                        marginLeft: "5px",
                        fontSize: "14px",
                      }}
                    >
                      Sector
                    </Typography>
                    <Box style={{ flexDirection: "row" }}>
                      <FormGroup
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          marginTop: "16px",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={handloomchecked}
                              onClick={(event) => {
                                // setHandloomChecked(event.target.checked)
                                handleChange(event.target.checked, 1);
                              }}
                              // value={addForm?.sector[0]=="handloom"?true:false}
                            />
                          }
                          label="Handloom"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={powerloomchecked}
                              onClick={(event) => {
                                // setPowerloomChecked(event.target.checked)
                                handleChange(event.target.checked, 2);
                                // console.log(event.target.checked)
                                // handleChange("powerloom",2);
                              }}
                              // value={addForm?.sector[1]=="powerloom"?true:false}
                            />
                          }
                          label="Powerloom"
                        />
                      </FormGroup>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
                {props.formDialog.divType === "edit" ? (
                  <Box
                    style={{
                      width: "100%",
                      height: "58px",
                      border: "0.2px solid rgba(0, 0, 0, 0.26)",
                      marginTop: "5px",
                      borderRadius: "4px",
                    }}
                  >
                    <Box style={{ flexDirection: "row" }}>
                      <FormGroup
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          marginTop: "10px",
                        }}
                      >
                        <FormControlLabel
                          style={{ fontFamily: "Lato" }}
                          control={
                            <Checkbox
                              checked={addForm?.sector?.handloom ? true : false}
                              onChange={(event) => {
                                let handloomdata = {
                                  ...addForm?.sector,
                                };
                                handloomdata.handloom = event.target.checked;
                                setAddForm({
                                  ...addForm,
                                  sector: handloomdata,
                                });
                              }}
                              value={addForm?.sector?.handloom}
                            />
                          }
                          label="Handloom"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                addForm?.sector?.powerloom ? true : false
                              }
                              onChange={(event) => {
                                let powerloomdata = {
                                  ...addForm?.sector,
                                };

                                powerloomdata.powerloom = event.target.checked;
                                // // //  handelChange()
                                // // console.log(powerloomdata,"powerloomdata")
                                // // setHandloom(event.target.checked)
                                // // //  setPowerloomChecked(event.target.checked)
                                setAddForm({
                                  ...addForm,
                                  sector: powerloomdata,
                                });
                              }}
                              // value={userForm?.sector?.powerloom}
                            />
                          }
                          label="powerloom"
                          style={{ fontFamily: "Lato" }}
                        />
                      </FormGroup>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
              </Grid>

              {/* <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: '-2px' }}
                className={classes.formGrid}
              >
                <TextField
                    label={props.t('advertisements.logolink')}
                    
                    disabled={
                      props.formDialog.divType === 'new' ||
                      props.formDialog.divType === 'edit'
                        ? false
                        : true
                    }
                    id='logo'
                    defaultValue={addForm.logo}
                    value={addForm.logo}
                    required
                    fullWidth
                    onChange={(event) => {
                      setAddForm({
                        ...addForm,
                        logo: event.target.value,
                        
                      });
                      setBannerListVal({
                        ...bannerListVal,
                        image: '',
                        siteLink: '',
                        searchKey: [],
                        category: '',
                      });
                    }}
                  ></TextField>
              
                
              </Grid> */}
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ padding: "20px" }}
              >
                <Grid container>
                  <Grid
                    xl={8}
                    lg={8}
                    md={8}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                  >
                    <TextField
                      id="logo"
                      label={props.t("Logo")}
                      fullWidth
                      variant="outlined"
                      value={addForm.logo}
                      required
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {/* <Tooltip title="Upload Image upto 3 Mb"> */}
                            {addForm?.logo && (
                              <IconButton
                                aria-label="add"
                                onClick={handleDelete}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                            {/* </Tooltip> */}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography style={{ fontSize: "12px", color: "gray" }}>
                      Upload Image upto 3 Mb
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                    style={{}}
                  >
                    <Grid item xs={7}>
                      <input
                        // accept="image/*"
                        accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                        style={{ display: "none" }}
                        id="logoImage"
                        // multiple
                        disabled={
                          logoUploadStatus === true
                            ? true
                            : (logoUploadStatus === false &&
                                props.formDialog.divType === "new") ||
                              (logoUploadStatus === false &&
                                props.formDialog.divType === "edit")
                            ? false
                            : true
                        }
                        onChange={(event) => {
                          handleLogoUpload(event);
                        }}
                        type="file"
                      />
                      <label htmlFor="logoImage">
                        <Button
                          align="left"
                          color="primary"
                          component="span"
                          className={"mr-2"}
                          size="large"
                        >
                          <CloudUploadIcon
                            style={{
                              fontSize: 20,
                              marginRight: "20px",
                            }}
                          />
                          Upload
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={4}>
                      {logoUploadStatus === true ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        logoUploadStatus === false &&
                        addForm.logo !== "" && (
                          <img
                            src={addForm.logo}
                            style={{
                              width: "80px",
                              height: "55px",
                              border: "1px solid black",
                            }}
                          />
                        )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: '13px' }}
                className={classes.formGrid}
              >
                <TextField
                  id='priority'
                  label={props.t('advertisements.priority')}
                  // className={classes.userTypeMenuItem}
                  select
                  disabled={
                    props.formDialog.divType === 'new' ||
                    props.formDialog.divType === 'edit'
                      ? false
                      : true
                  }
                  required
                  fullWidth
                  defaultValue={addForm.priority}
                  value={addForm.priority}
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      priority: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={'low'}>
                    {props.t('advertisements.low')}
                  </MenuItem>
                  <MenuItem key={1} value={'normal'}>
                    {props.t('advertisements.normal')}
                  </MenuItem>
                  <MenuItem key={2} value={'high'}>
                    {props.t('advertisements.high')}
                  </MenuItem>
                </TextField>
              </Grid>
            */}
              {/* <Grid
                xl={3}
                lg={3}
                md={6}
                sm={6}
                xs={12}
                style={{ marginTop: '15px' }}
                // className={classes.formGrid}
              >
                <TextField
                  id='active'
                  label={props.t('advertisements.active')}
                  // className={classes.userTypeMenuItem}
                  select
                  required
                  disabled={
                    props.formDialog.divType === 'new' ||
                    props.formDialog.divType === 'edit'
                      ? false
                      : true
                  }
                  fullWidth
                  defaultValue={addForm.active}
                  value={addForm.active}
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      active: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={true}>
                    {props.t('advertisements.active')}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    {props.t('advertisements.inactive')}
                  </MenuItem>
                </TextField>
              </Grid> */}

              {/* <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id='adPosition'
                  label={props.t('advertisements.position')}
                  // className={classes.userTypeMenuItem}
                  select
                  required
                  fullWidth
                  disabled={
                    props.formDialog.divType === 'new' ||
                    props.formDialog.divType === 'edit'
                      ? false
                      : true
                  }
                  defaultValue={addForm.adPosition}
                  value={addForm.adPosition}
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      adPosition: event.target.value,
                    })
                  }
                >
                  {adPositionOptions.map((options, index) => (
                    <MenuItem key={index} value={options.value}>
                      {options.display}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> */}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <FileUpload
        ref={galleryFileUpload}
        aws={aws}
        file={file}
        base64={galleryFileBase64}
        bucket={aws.advertisementBucket}
        callback={_s3GalleryImageUrl}
      />
      <FileUpload
        ref={bannerFileUpload}
        aws={aws}
        file={file}
        base64={attachmentsFileBase64}
        bucket={aws.advertisementBucket}
        callback={_s3BannerImageUrl}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AEVForm)
);
