import React, { useEffect, useState } from "react";
import { Translation, withTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  // MuiPickersUtilsProvider,
  KeyboardTimePicker,
  // KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Box,
  styled,
  Checkbox,
  TextareaAutosize,
  Input,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { Stack } from "@mui/material";
import { Chip } from "@mui/material";
import landingImg from "../../../../assets/images/Pic.png";
import DownloadIcon from "@mui/icons-material/Download";
// import ImageUpload from "./ImageUpload";
import Toaster from "../../../../utils/Toaster";
import isEmpty from "../../../../utils/isEmpty";
// import { DatePicker } from "material-ui";
// import Checkbox from '@mimport Checkbox from '@mui/material/Checkbox';
// import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ImagesUpload from "./ImagesUpload.jsx";
import { event } from "jquery";
import { CheckValidation } from "../../../../utils/Validator.js";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  approveButton: {
    color: "white",
    backgroundColor: "red",
    boxShadow: "0 2px 4px red",
  },
  rejectButton: {
    color: "black",
    backgroundColor: "yellow",
    boxShadow: "0 2px 4px red",
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const postDetails = {
  sector: [],
  occupationType: [],

  description: "",
  startTime: null,

  scheduledTime: new Date(),
  isPermanent: false,

  validTill: new Date(),
  range: 45000000,
  referenceUrl: "",

  location: {
    type: "Point",
    coordinates: ["", ""],
  },
  fragmentedAddress: "",
  attachment: [],

  media: [],
  subject: "",
  tag: [],
};

function AddUser(props) {
  const [selectedOption, setSelectedOption] = useState("");

  const [location, setLocation] = useState("");
  const [places, setPlaces] = useState([]);
  const [placeId, setPlaceId] = useState([]);
  const [locationNames, setlocationNames] = useState("");
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [userForm, setUserForm] = useState(postDetails);
  const [categorydata, setCategoryData] = useState([]);
  const [sectorlist, setSectorlist] = useState([]);
  const [flag, setFlag] = useState(false);

  const [flag1, setFlag1] = useState(false);
  const [addplan, setAddPlan] = useState([]);
  const [plan, setPlan] = useState("");
  const [handloomchecked, setHandloomChecked] = useState(false);
  const [powerloomchecked, setPowerloomChecked] = useState(false);
  // const [handloom, setHandloom] = useState(postDetails.sector);

  useEffect(() => {
    //changes made here
    // setUserForm(postDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (props?.formDialog?.functionName === "reset") {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    setUserForm(JSON.parse(JSON.stringify(postDetails)));
  };
  const categorylist = () => {
    APIRequest.request("GET", ConfigAPIURL.listcategory, "").then(
      (response) => {
        let userResult = response?.data?.result;
        setCategoryData(userResult);
        // console.log(categorydata, "categorydata");
      }
    );
  };

  useEffect(() => {
    categorylist();
  }, []);
  // console.log(categorydata, "data");

  //getting data for sector
  const sectorListData = () => {
    APIRequest.request("GET", ConfigAPIURL.sectorData, "").then((response) => {
      let userResult = response?.data?.result;
      setSectorlist(userResult);
      // console.log(sectorlist, "sectorData");
    });
  };

  useEffect(() => {
    sectorListData();
  }, []);

  // console.log(sectorlist, "sectorlist");

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "GET",
        ConfigAPIURL.postDetails + "?recordId=" + `${props.rowDetails}`
      ).then((response) => {
        // console.log(response, "response");
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let postResult = response?.data;

            setUserForm({
              ...userForm,

              sector: postResult?.sector,
              occupationType: { ...postResult?.occupationType },
              // businessName: postResult?.businessName,
              author: { ...postResult?.author },
              // address: postResult?.address,
              description: postResult?.description,
              startTime: postResult?.startTime,
              scheduledTime: new Date(postResult?.scheduledTime) * 1000,
              // validityType: postResult?.validityType,
              validTill: new Date(postResult?.validTill) * 1000,
              tag: postResult?.tag,
              // endTime: postResult?.endTime,
              range: postResult?.range,
              referenceUrl: postResult?.referenceUrl,
              // media: postResult?.media,
              media: { ...postResult?.media },
              isPermanent: postResult?.isPermanent,
              location: {
                type: postResult.location.type,
                coordinates: [
                  postResult.location.coordinates[0],
                  postResult.location.coordinates[1],
                ],
              },
              fragmentedAddress: postResult?.fragmentedAddress,
              attachment: { ...postResult?.attachment },
              subject: postResult?.subject,
              // images: postResult?.images,
            });

            setSelectedRowId(postResult._id);
          }
        }
      });
    }
  };

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        // handleChange();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    let url = ConfigAPIURL.postCreate;
    let method = "POST";

    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.postUpdate;
      userForm["recordId"] = selectedRowId;
      method = "PUT";
    }

    let formCopy = { ...userForm };
    delete formCopy.attachment;
    delete formCopy.range;
    delete formCopy.fragmentedAddress;
    delete formCopy.location;
    delete formCopy.referenceUrl;
    delete formCopy?.startTime;
    delete formCopy?.media;
    delete formCopy?.subject;

    const missingFields = CheckValidation(formCopy);
    if (missingFields.length > 0) {
      SnackbarUtils.error(
        "Please fill all the mandatory fields",
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }

    let userFormCopy = { ...userForm };

    userFormCopy["scheduledTime"] = new Date(userForm["scheduledTime"]) / 1000;
    userFormCopy["startTime"] =
      new Date(userForm["startTime"]).getTime() / 1000;
    userFormCopy["validTill"] = userForm?.isPermanent
      ? null
      : new Date(userForm["validTill"]) / 1000;

    APIRequest.request(method, url, JSON.stringify(userFormCopy)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            SnackbarUtils.sucess(
              props.t("snackbar.updatedSuccess"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            props.handleFormDialog();
            props.tableQuery({
              keyword: "",
              page: 0,
              pageSize: 10,
              sortField: "",
              userType: "all",
              sortOrder: "false",
              postStatus: "all",
              active: true,
            });
            setUserForm(postDetails);
            setSelectedRowId("");
          }
          if (response.code === 100 && response.data.responseCode === 114) {
            SnackbarUtils.error(
              props.t("snackbar.dupliRecords"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      }
    );
  };
  //functions for getting location cordinates
  const handleChange = async (event) => {
    const placeName = event.target.value;

    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getLocation}?place=${placeName}`
    ).then((res) => {
      // console.log(res.data.result.predictions);
      setPlaces(res.data.result.predictions);
    });
    setLocation("");
  };
  const handleOptionClick = (option) => {
    // console.log(option.place_id);
    // setLocation(option.place_id);

    setSelectedOption(option);
    handleLocation(option.place_id);
  };
  // const place_id = selectedOption?.place_id;
  // console.log("selected placeid is", place_id);
  // console.log("selected option is", selectedOption);

  const handleLocation = async () => {
    // console.log("this is evee optionsss", eve);
    // setlocationNames(selectedOption.description);

    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getLonLan}?place_id=${placeId}`
    ).then((res) => {
      setLocation(res.data?.result?.result?.geometry?.location);
      setUserForm({
        ...userForm,
        location: {
          ...userForm.location,
          coordinates: [
            res.data?.result?.result?.geometry?.location?.lat,
            res.data?.result?.result?.geometry?.location?.lng,
          ],
        },
      });
    });
  };
  useEffect(() => {
    handleLocation();
  }, [placeId]);

  useEffect(() => {
    if (selectedOption) {
      // setSelectedOption(selectedOption);
      setlocationNames(selectedOption.description);
      setPlaceId(selectedOption);
      places.find((option, i) => {
        if (option?.description === selectedOption) {
          setPlaceId(option?.place_id);
        }
      });
      // setAddForm({
      //   ...addForm,
      //   locationName: selectedOption.description,
      // });
      handleLocation();
    }
  }, [selectedOption, placeId]);

  const getPlans = () => {
    APIRequest.request("POST", ConfigAPIURL.plans).then((response) => {
      let userResult = response?.data?.rows;
      setAddPlan(userResult);
      // console.log(userResult);
    });
  };
  useEffect(() => {
    getPlans();
  }, []);
  // console.log("plan", addplan);
  // console.log(userForm, "userForm");

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: "black",
  }));
  // console.log(plan, "planid");
  const expiresOn = "companyName"?.startDate + "companyName"?.endAt * 86400;
  const rangeOptions = [5, 10, 15, 25, 35, "max"];
  const validityTypeOptions = [
    { label: props.t("Permanent"), value: true },
    { label: props.t("Temporary"), value: false },
  ];

  //handaling range function here
  const handleRangeChange = (event, newValue) => {
    let updatedRange = newValue;

    if (newValue === "max") {
      updatedRange = null; // Set range to null when "max" is selected
    } else if (newValue && !Number.isNaN(Number(newValue))) {
      updatedRange = parseInt(newValue); // Convert numeric range values to integers
    }

    setUserForm({
      ...userForm,
      range: updatedRange,
    });
  };

  //for fetching all tags for creation of post
  const [allTags, setAllTags] = useState([]);

  const fetchAllTags = async () => {
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.getAllTagsForPost,
      ""
    );
    if (response?.data?.responseCode === 109) {
      setAllTags(response.data.result);
    }
  };

  useEffect(() => {
    fetchAllTags();
  }, []);

  return (
    <div
      className={classes.root}
      style={{
        marginTop: props.marginTop,
        fontFamily: "Lato",
        position: "relative",
        top: "-13px",
      }}
    >
      <Stack style={{ padding: "20px" }}>
        {/* buttons stack */}

        <Stack
          style={{ background: "#FFECD3", height: " 60px", zIndex: "1" }}
          sx={{
            flexDirection: { lg: "row", sm: "row", md: "row" },
            justifyContent: "space-between",
            rowGap: "10px",
          }}
        >
          <Stack style={{ flexDirection: "row", gap: "7px" }}>
            <Stack
              style={{
                paddingBottom: "3px",
              }}
            >
              <Typography
                className="personaldetails"
                style={{
                  fontWeight: "800",
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "20px",
                  underline: "none",
                  position: "relative",
                  top: "10px",
                  left: "27px",
                }}
              >
                Post Details
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        {/* details stack of users */}

        <Stack
          style={{
            padding: "20px",
            background: "white",
            borderRadius: "8px",
            marginTop: "20px",
            position: "relative",
            top: "-24px",
          }}
        >
          <>
            <Stack>
              <Grid container spacing={2}>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <Autocomplete
                    multiple
                    name="sector"
                    limitTags={2}
                    options={sectorlist}
                    value={userForm?.sector}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    // style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        label="Sector"
                        id="sector"
                        required
                        {...params}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,

                          style: {
                            background: "#FFFFFF",
                            borderRadius: "10px",
                          },
                        }}
                      />
                    )}
                    onChange={(event, value) => {
                      setUserForm({
                        ...userForm,
                        sector: value,
                      });
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <Autocomplete
                    multiple
                    limitTags={2}
                    label="Work/Business Type"
                    value={userForm?.occupationType}
                    options={categorydata}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        variant="outlined"
                        label="Work/Business Type"
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            background: "#FFFFFF",
                            borderRadius: "10px",
                          },
                        }}
                        size="small" // Adjust the size as per your preference
                      />
                    )}
                    onChange={(event, value) => {
                      setUserForm({
                        ...userForm,
                        occupationType: value,
                      });
                    }}
                  />
                </Grid>

                {/*below is the dropdown for adding tags for post*/}
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <Autocomplete
                    multiple
                    limitTags={2}
                    value={userForm?.tag}
                    label="Post/Tag Type"
                    options={allTags}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        required
                        label="Post/Tag Type"
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            background: "#FFFFFF",
                            borderRadius: "10px",
                          },
                        }}
                        size="small" // Adjust the size as per your preference
                      />
                    )}
                    onChange={(event, value) => {
                      setUserForm({
                        ...userForm,
                        tag: value,
                      });
                    }}
                  />
                </Grid>

                {/*tag section  ends here */}

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  {/* <Autocomplete
                    id="validityType"
                    options={[
                      { label: props.t("Permanent"), value: true },
                      { label: props.t("Temporary"), value: false },
                    ]}
                    getOptionLabel={(option) => option.label}
                    // value={validityTypeOptions.find(
                    //   (option) => option.value === userForm.isPermanent
                    // )}
                    // onChange={(event, newValue) => {
                    //   setUserForm({
                    //     ...userForm,
                    //     isPermanent: newValue ? newValue.value : false,
                    //   });
                    // }}
                    disabled
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={props.t("Validity Type")}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            background: "#FFFFFF",
                            borderRadius: "10px",
                            width: "100%",
                          },
                        }}
                        autoComplete="something-unsupported"
                        fullWidth
                        style={{
                          width: "100%",
                          borderRadius: "5px",
                        }}
                        disabled={
                          props.formDialog.divType === "new" ||
                          props.formDialog.divType === "edit"
                        }
                      />
                    )}
                  /> */}
                  <Autocomplete
                    id="validityType"
                    options={[
                      { label: props.t("Permanent"), value: true },
                      { label: props.t("Temporary"), value: false },
                    ]}
                    getOptionLabel={(option) => option.label}
                    value={
                      userForm.isPermanent
                        ? { label: props.t("Permanent"), value: true }
                        : { label: props.t("Temporary"), value: false }
                    }
                    onChange={(event, newValue) => {
                      setUserForm({
                        ...userForm,
                        isPermanent: newValue.value,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        required
                        label={props.t("Validity Type")}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            // background: "#FFFFFF",
                            borderRadius: "10px",
                            width: "100%",
                          },
                        }}
                        // autoComplete="something-unsupported"
                        fullWidth
                        style={{
                          width: "100%",
                          borderRadius: "5px",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Start Date"
                      inputFormat="DD/MM/YYYY"
                      minDate={new Date()}
                      id="startDate"
                      value={userForm?.scheduledTime || null}
                      InputProps={{
                        style: {
                          height: "54px",
                          background: "#FFFFFF",
                          borderRadius: "10px",
                        },
                      }}
                      onChange={(date) => {
                        setUserForm({
                          ...userForm,
                          scheduledTime: date,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          id="startDate"
                          variant="outlined"
                          fullWidth
                          size="small"
                          InputLabelProps={{
                            shrink: userForm.scheduledTime, // Set shrink property based on the condition
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                {!userForm?.isPermanent && (
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="End Date"
                        id="endDate"
                        inputFormat="DD/MM/YYYY"
                        disabled={userForm.isPermanent}
                        minDate={new Date()}
                        value={userForm?.validTill || null} // Use null if the value is initially empty
                        InputProps={{
                          style: {
                            height: "54px",
                            background: "#FFFFFF",
                            borderRadius: "10px",
                          },
                        }}
                        onChange={(date) => {
                          setUserForm({
                            ...userForm,
                            validTill: date || null,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="endDate"
                            required
                            variant="outlined"
                            fullWidth
                            size="small"
                            InputLabelProps={{
                              shrink: userForm.validTill, // Set shrink property based on the condition
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                )}
              </Grid>
              {/* second grid  */}
              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },

                  marginTop: "16px",
                }}
              >
                <Grid container spacing={2}>
                  {/* <Grid
                  //   item
                  //   xl={4}
                  //   lg={4}
                  //   md={4}
                  //   sm={12}
                  //   xs={12}
                  //   style={{
                  //     marginTop: "16px",
                  //   }}
                  // >
                  //   <LocalizationProvider dateAdapter={AdapterDayjs}>
                  //     <TimePicker
                  //       label="Start Time"
                  //       value={userForm?.startTime || null}
                  //       onChange={(newTime) =>
                  //         setUserForm({
                  //           ...userForm,
                  //           startTime: newTime,
                  //         })
                  //       }
                  //       InputProps={{
                  //         style: {
                  //           height: "54px",
                  //           background: "#FFFFFF",
                  //           borderRadius: "10px",
                  //         },
                  //       }}
                  //       renderInput={(params) => (
                  //         <TextField
                  //           {...params}
                  //           variant="outlined"
                  //           fullWidth
                  //           size="small"
                  //           InputLabelProps={{
                  //             shrink: !!userForm?.startTime,
                  //           }}
                  //         />
                  //       )}
                  //     />
                  //   </LocalizationProvider>
                   </Grid>*/}
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={places?.map((option, i) => option?.description)}
                      // sx={{  height:"54px"}}
                      value={userForm?.fragmentedAddress || ""}
                      // getOptionLabel={(option) => option.description}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          onChange={handleChange}
                          label={"Select location"}
                          variant="outlined"
                          // value={addForm.locationName}
                        />
                      )}
                      onChange={(event, value) => {
                        setSelectedOption(value);
                        setUserForm({
                          ...userForm,
                          fragmentedAddress: value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      id="referenceUrl"
                      InputProps={{
                        style: {
                          background: "#FFFFFF",
                          borderRadius: "10px",
                        },
                      }}
                      label={props.t("Reference Link")}
                      autoComplete="something-unsupported"
                      value={userForm.referenceUrl}
                      fullWidth
                      style={{ width: "100%", borderRadius: "5px" }}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          referenceUrl: event.target.value,
                        })
                      }
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                    />
                  </Grid>
                </Grid>
              </Stack>

              {/* third grid */}
              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },
                  marginTop: "16px",
                }}
              >
                <Grid container spacing={2}>
                  {/* <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <Autocomplete
                      id="range"
                      options={rangeOptions}
                      getOptionLabel={(option) =>
                        option === "max" || !option ? option : `${option} km`
                      }
                      value={userForm.range}
                      onChange={handleRangeChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={props.t("Range")}
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              background: "#FFFFFF",
                              borderRadius: "10px",
                            },
                          }}
                          autoComplete="something-unsupported"
                          fullWidth
                          style={{
                            width: "100%",
                            borderRadius: "5px",
                          }}
                          disabled={
                            props.formDialog.divType === "new" ||
                            props.formDialog.divType === "edit"
                              ? false
                              : true
                          }
                        />
                      )}
                    />
                  </Grid> */}
                </Grid>
              </Stack>

              {/* fourth grid */}

              {/* here we are adding the subject */}
              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },

                  marginTop: "16px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      multiline
                      id="subject"
                      fullWidth
                      rows={2}
                      size="normal"
                      InputProps={{
                        style: {
                          // width: "314%",

                          background: "#FFFFFF",
                          borderRadius: "10px",
                        },
                      }}
                      label={props.t("Subject")}
                      autoComplete="something-unsupported"
                      value={userForm.subject}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          subject: event.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Stack>

              {/* fifth grid */}

              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },
                  marginTop: "16px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      multiline
                      rows={2}
                      id="description"
                      size="normal"
                      InputProps={{
                        style: {
                          background: "#FFFFFF",
                          borderRadius: "10px",
                        },
                      }}
                      label={props.t("Description")}
                      autoComplete="something-unsupported"
                      value={userForm.description}
                      fullWidth
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          description: event.target.value,
                        })
                      }
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },
                  marginTop: "16px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <ImagesUpload
                      setUserForm={setUserForm}
                      userForm={userForm}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          </>
        </Stack>
      </Stack>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AddUser)
);
