import { Typography, useTheme } from "@material-ui/core";
import React from "react";
import { useStyles } from "../styles/style";
import LocalStorage from "../../../../../config/LocalStorage";

function WelcomeSection() {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.dashboardHeading}>
      <Typography
        variant="title"
        style={{
          fontWeight: "600",
          fontFamily: "Segoe UI",
        }}
      >
        Hi{" "}
        <span
          style={{
            color: theme?.palette?.primary?.main,
          }}
        >
          {LocalStorage.userDetails?.name}
        </span>
        , Welcome back
      </Typography>
      <Typography
        style={{
          color: "#868686",
          fontWeight: "400",
          marginTop: "8px",
          fontFamily: "Segoe UI",
        }}
      >
        Let's take a look at some comprehensive statistics about the application
      </Typography>
    </div>
  );
}

export default WelcomeSection;
