import {
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  TextField,
} from "@material-ui/core";
import React from "react";
import { withTranslation } from "react-i18next";

import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: 250,
  },
  innerPadding: {
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function TableQuery(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Grid container className={classes.innerPadding}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label={props.t("query.activeStatus")}
              select
              defaultValue={props.query.active}
              value={props.query.active}
              onChange={(event) =>
                props.setQuery({ ...props.query, active: event.target.value })
              }
              style={{ width: "100%", marginBottom: 10 }}
            >
              <MenuItem key={0} value={true}>
                {props.t("role.active")}
              </MenuItem>
              <MenuItem key={1} value={false}>
                {props.t("role.inactive")}
              </MenuItem>
            </TextField>
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label={props.t("Advertisement Type")}
              select
              defaultValue={props.query.typeOfLink}
              value={props.query.typeOfLink}
              onChange={(event) =>
                props.setQuery({
                  ...props.query,
                  typeOfLink: event.target.value,
                })
              }
              style={{ width: "100%", marginBottom: 10 }}
            >
              <MenuItem key={0} value="banner">
                {props.t("Banner")}
              </MenuItem>
              <MenuItem key={1} value="video">
                {props.t("Video")}
              </MenuItem>
            </TextField>
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label={props.t("Module Type")}
              select
              defaultValue={props.query.moduleType}
              value={props.query.moduleType}
              onChange={(event) =>
                props.setQuery({
                  ...props.query,
                  moduleType: event.target.value,
                })
              }
              style={{ width: "100%", marginBottom: 10 }}
            >
              <MenuItem key={0} value="all">
                {props.t("All")}
              </MenuItem>
              <MenuItem key={1} value="homeScreen">
                {props.t("Home Screen")}
              </MenuItem>
              <MenuItem key={0} value="infotainment">
                {props.t("Infotainment")}
              </MenuItem>
              <MenuItem key={1} value="requirement">
                {props.t("Requirement")}
              </MenuItem>
              <MenuItem key={0} value="sales">
                {props.t("Sales")}
              </MenuItem>
              <MenuItem key={1} value="services">
                {props.t("Services")}
              </MenuItem>
              <MenuItem key={1} value="myLoom">
                {props.t("My Looms")}
              </MenuItem>
            </TextField>
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="startDate"
                  fullWidth
                  maxDate={
                    props.query.endDate !== null
                      ? new Date(props.query.endDate * 1000)
                      : new Date()
                  }
                  label={props.t("query.startFrom")}
                  format="dd/MM/yyyy"
                  value={
                    props.query.startDate !== null
                      ? new Date(props.query.startDate * 1000)
                      : null
                  }
                  style={{ width: "100%", marginBottom: 10 }}
                  onChange={(date) =>
                    props.setQuery({
                      ...props.query,
                      startDate: Math.floor(
                        new Date(
                          new Date(new Date(date).setHours(0)).setMinutes(0)
                        ).setSeconds(0) / 1000
                      ),
                    })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="endDate"
                  fullWidth
                  minDate={
                    props.query.startDate !== null
                      ? new Date(props.query.startDate * 1000)
                      : new Date(0)
                  }
                  maxDate={new Date()}
                  label={props.t("query.endBefore")}
                  format="dd/MM/yyyy"
                  value={
                    props.query.endDate !== null
                      ? new Date(props.query.endDate * 1000)
                      : null
                  }
                  onChange={(date) =>
                    props.setQuery({
                      ...props.query,
                      endDate: Math.floor(
                        new Date(
                          new Date(new Date(date).setHours(23)).setMinutes(59)
                        ).setSeconds(59) / 1000
                      ),
                    })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
export default withTranslation("translations")(TableQuery);
