import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
// import { Modal } from "antd"
import { Button, Grid, TextareaAutosize, Typography } from "@material-ui/core";
import "./styles/user.scss";

import { Stack } from "@mui/material";
import PlanImage1 from "../../../../assets/images/Plan1.png";
import PlanImage2 from "../../../../assets/images/plan2.png";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import { Close } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "600px",
    // border:"none",
    border: "none",
    height: "500px",
    overflow: "auto",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function Modals(props) {
  //   console.log(props);
  const { userForm, setUserForm, setAddplan, addplan, data } = props;
  console.log(userForm, "userForm");
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [getplans, setGetPlans] = useState([]);
  const [plansdata, setPlansData] = useState([]);
  const [clickvalue, setClickValue] = useState(false);
  const [clickind, setClickind] = useState();
  const [clickedid, setClickedid] = useState({
    planId: "",
    note: "",
  });
  const [snackPack, setSnackPack] = React.useState([]);
  const [open1, setOpen1] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen1(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen1(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClick = (message) => () => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };

  const handleClose1 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen1(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };
  console.log("clickedid", clickedid);

  const handleOpen = () => {
    setOpen(true);
  };
  const hadelreject = () => {
    props.handelfunction();
    setOpen(false);
  };
  const imagesarr = [PlanImage1, PlanImage2];

  const handleClose = () => {
    setOpen(false);
    setClickedid({
      planId: "",
      note: "",
    });
    setClickind(null);
  };
  // const { confirm } = Modal;

  // function showDeleteConfirm() {
  //   confirm({
  //     title: "Are you sure Reject the User",
  //     content: "Reject the user",
  //     okText: "Yes",
  //     okType: "danger",
  //     cancelText: "No",
  //     onOk() {
  //      props.handelfunction();
  //     },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // }
  const handelclickno = () => {
    console.log("no");
  };
  const getPlans = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.plans,
      JSON.stringify({
        // recordId: "6321a9060d00da3c7010555a",
        userId: data?._id,
      })
    ).then((response) => {
      let userResult = response?.data?.rows;
      setGetPlans(userResult);
      console.log(userResult);
    });
  };
  useEffect(() => {
    getPlans();
  }, []);

  return (
    <div>
      <Stack>
        <button
          onClick={handleOpen}
          className="renew"
          style={{ cursor: "pointer" }}
        >
          Renew
        </button>
      </Stack>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            className={classes.paper}
            style={{
              borderRadius: "10px",
              background: "#FFFFFF",
              position: "relative",
            }}
          >
            <IconButton
              style={{ position: "absolute", right: 10, top: 10 }}
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <Stack>
              <Grid container spacing={2} style={{ padding: "10px" }}>
                {getplans &&
                  getplans.length > 0 &&
                  getplans.map((option, ind) => {
                    const value = Math.floor(Math.random() * (2 - 1 + 1)) + 1;
                    return (
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{}}
                        onClick={handleClick("Message A")}
                      >
                        <Stack
                          style={{
                            padding: "10px",
                            border:
                              clickind === ind
                                ? "1.33784px solid #FF8D00"
                                : "1.34459px solid #F5F5F5",
                            boxShadow:
                              "0px 1.34459px 5.37838px rgba(0, 0, 0, 0.08)",
                            borderRadius: "16px",
                            background: "#FFFFFF",
                          }}
                          onClick={() => {
                            setClickind(ind);
                            setClickedid((p) => ({
                              ...p,
                              planId: option?._id,
                            }));
                          }}
                        >
                          <Stack
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography
                              className="plantypename"
                              style={{
                                fontSize: "18px",
                                color: "#000000",
                                marginTop: "5px",
                                textTransform: "capitalize",
                              }}
                            >
                              {option?.title}
                            </Typography>

                            <Stack>
                              <img
                                style={{
                                  width: "39px",
                                  height: "39px",
                                }}
                                src={imagesarr[value - 1]}
                                alt=""
                              />
                            </Stack>
                          </Stack>
                          <Stack>
                            <Stack
                              style={{
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  background: "#E2FCFF",
                                  borderRadius: "10px",
                                  marginTop: "7px",
                                }}
                              >
                                {option?.description}
                              </Typography>
                            </Stack>
                          </Stack>

                          <Stack style={{ marginTop: "13px" }}>
                            <Typography
                              className="plantypename1"
                              style={{ fontSize: "32px" }}
                            >
                              {option?.offerPrice}
                            </Typography>
                          </Stack>

                          <Stack style={{ marginTop: "-5px" }}>
                            <Typography
                              className="plantypename"
                              style={{
                                textDecoration: "line-through",
                                fontSize: "18px",
                                color: "#868686",
                              }}
                            >
                              {"₹" + option?.price}
                            </Typography>
                          </Stack>
                          <Stack style={{ marginTop: "-5px" }}>
                            <Typography
                              className="plantypename"
                              style={{ color: "#000000", fontSize: "18px" }}
                            >
                              {Math.floor(option?.validity / 30) +
                                " " +
                                "Months"}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    );
                    // console.log(option, "values");
                  })}
              </Grid>
              <Stack>
                {userForm?.subscriptionPlanId !== clickedid?.planId ? (
                  <Stack>
                    <Snackbar
                      style={{ marginLeft: "230px" }}
                      key={messageInfo ? messageInfo.key : undefined}
                      open={open1}
                      autoHideDuration={6000}
                      onClose={handleClose1}
                      TransitionProps={{ onExited: handleExited }}
                      message={
                        "You are subscribing a new plan your validity will be lost if you subscribe a new plan"
                      }
                      action={
                        <React.Fragment>
                          <Button
                            color="secondary"
                            size="small"
                            onClick={handleClose1}
                          >
                            UNDO
                          </Button>
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.5 }}
                            onClick={handleClose1}
                          >
                            <CloseIcon />
                          </IconButton>
                        </React.Fragment>
                      }
                    />
                  </Stack>
                ) : (
                  ""
                )}
              </Stack>
              {clickedid?.planId && (
                <Stack
                  style={{
                    background: "#FFFFFF",
                    boxShadow: "0px 1.35135px 5.40541px rgba(0, 0, 0, 0.25)",
                    borderRadius: "16.2162px",
                    padding: "10px",
                  }}
                >
                  <textarea
                    id="note"
                    style={{
                      fontWeight: "400",
                      fontSize: "1rem",
                      color: "#868686",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontFamily: "Lato",
                      minHeight: "50px",
                    }}
                    onChange={(event) => {
                      const value = event?.target?.value ?? ""; // Ensure value is defined

                      setClickedid((p) => ({
                        ...p,
                        note: value,
                      }));
                    }}
                    aria-label="Note"
                    placeholder="Note"
                  />
                </Stack>
              )}

              <Stack style={{ marginTop: "20px" }}>
                <button
                  className="reniewsubmit"
                  onClick={(event) => {
                    let planId = { ...clickedid };
                    console.log(planId, "planId");
                    setUserForm({
                      ...userForm,
                      planId: planId?.planId,
                      note: planId?.note,
                    });
                    handleClose();
                    setAddplan(true);
                  }}
                  // onClick={handleClose}
                >
                  Submit
                </button>
              </Stack>
            </Stack>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
