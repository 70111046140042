import React, { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Box,
  styled,
  TextareaAutosize,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { Stack } from "@mui/material";
import Rating from "@mui/material/Rating";
import "./styles/reviews.scss";
const Reviews = (data) => {
  console.log(data.data?._id, "dataid");
  console.log("data");
  const [value, setValue] = useState(2);
  const [ratings, setRatings] = useState([]);
  const [avgrating, setAvgRating] = useState([]);
  const reviewsdata = () => {
    APIRequest.request(
      "GET",
      ConfigAPIURL.userratingsbyid + "?recordId=" + `${data?.data?._id}`,
      ""
    ).then((response) => {
      let userResult = response?.data?.result;
      setRatings(userResult);
      console.log(response, "response");
    });
  };
  const ratingsavg = () => {
    APIRequest.request(
      "GET",
      ConfigAPIURL.useravgrating + "?recordId=" + `${data?.data?._id}`,
      ""
    ).then((response) => {
      let userResult = response?.data?.result;
      setAvgRating(userResult);
      console.log(response, "response");
    });
  };
  useEffect(() => {
    reviewsdata();
    ratingsavg();
  }, []);
  console.log(avgrating, "ratings");
  const ratingsdata =
    avgrating?.averageRating === "undefined"
      ? "No Ratings"
      : avgrating?.averageRating + "/5";
  return (
    <div style={{ borderRadius: "8px" }}>
      <Stack style={{}}>
        <Stack
          style={{
            justifyItems: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Typography className="avgrating">
            {console.log(avgrating?.averageRating, "avg")}
            Overall ratings :{avgrating?.averageRating + "/5"}
            {/* {Math.floor(3.44)} */}
          </Typography>
        </Stack>

        {ratings &&
          ratings?.length > 0 &&
          ratings?.map((option, index) => {
            console.log(option, "option");
            return (
              <Stack
                style={{
                  background: "#FAFAFB",
                  padding: "10px",
                  borderRadius: "8px",
                  marginTop: "10px",
                }}
              >
                <Stack
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack className="name1" style={{ color: "black" }}>
                    {option?.userId?.name}
                  </Stack>
                  <Stack
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    <Stack
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        margin: "auto",
                      }}
                    >
                      <Typography className="ratings">Work</Typography>
                      <Box>
                        <Rating
                          name="simple-controlled"
                          style={{ fontSize: "17px" }}
                          value={option?.ratings?.work}
                          //   onChange={(event, newValue) => {
                          //     setValue(4);
                          //   }}
                        />
                      </Box>
                    </Stack>
                    <Stack
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="ratings">Delivery</Typography>
                      <Box>
                        <Rating
                          name="simple-controlled"
                          style={{ fontSize: "17px" }}
                          value={option?.ratings?.delievery}
                          //   onChange={(event, newValue) => {
                          //     setValue(4);
                          //   }}
                        />
                      </Box>
                    </Stack>
                    <Stack
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="ratings">Payment</Typography>
                      <Box>
                        <Rating
                          name="simple-controlled"
                          style={{ fontSize: "17px" }}
                          value={option?.ratings?.payment}
                          //   onChange={(event, newValue) => {
                          //     setValue(4);
                          //   }}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack>
                  <Typography></Typography>
                  <Typography className="feedback">
                    {option?.feedback}
                  </Typography>
                </Stack>
              </Stack>
            );
          })}
      </Stack>
    </div>
  );
};

export default Reviews;
