import React, { useEffect, useRef, useState } from "react";
import { Stack } from "@mui/material";
import SearchImg from "../../../../../assets/images/SearchImg.png";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import logo from "../../../../../assets/images/logo.png";
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import useLoomHook from "./useLoomHook";
import SlideInDialog from "../../../../../templates/dialog/SlideInDialog";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Create from "./Create";

const useStyles = makeStyles((theme) => ({
  text: {
    color: "#222222",
    fontSize: "14px",
    fontWeight: 700,
  },
  textLabel: {
    color: "#222222",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: 2,
  },
}));

const arr = [
  {
    service: "00987",
    text: "A textile is a material made of thin fibers or filaments spun into yarn and woven into fabric. Raw material is a unique substance in any production oriented textile industry. It plays a vital role in continuous production and for high quality fabric. We provide all the raw materials for every type of services.",
    images: [logo],
  },
  {
    service: "00987",
    text: "A textile is a material made of thin fibers or filaments spun into yarn and woven into fabric. Raw material is a unique substance in any production oriented textile industry. It plays a vital role in continuous production and for high quality fabric. We provide all the raw materials for every type of services.",
    images: [logo, logo],
  },
];

// const images = { logo, logo, logo };

const LoomList = (props) => {
  const { userId } = props;
  console.log(userId);
  const classes = useStyles();
  const [focused, setFocused] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [slideInDialog, setSlideInDialog] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const handleSlideInDialog = () => {
    setSlideInDialog(false);
  };

  const handleDelete = () => {
    setSlideInDialog(true);
  };
  const handleClose = () => {
    setOpenEdit(false);
  };
  const {
    loomList,
    handleDeleteRequest,
    getLoomDetails,
    createData,
    setCreateData,
    sendToServer,
    listLooms,
  } = useLoomHook(userId, props, handleSlideInDialog, handleClose);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedId(null);
  };

  //   const scrollRefs = useRef({});

  //   useEffect(() => {
  //     const intervals = Object.keys(scrollRefs.current).map((key) => {
  //       const ref = scrollRefs.current[key];

  //       if (!ref) return null;

  //       return setInterval(() => {
  //         if (ref.scrollLeft + ref.offsetWidth >= ref.scrollWidth) {
  //           ref.scrollTo({ left: 0, behavior: "smooth" });
  //         } else {
  //           ref.scrollBy({ left: 120, behavior: "smooth" }); // Scroll amount
  //         }
  //       }, 3000);
  //     });

  //     return () =>
  //       intervals.forEach((interval) => interval && clearInterval(interval));
  //   }, [loomList]);

  console.log(loomList);
  return (
    <div>
      <Stack spacing={1} direction="row" justifyContent="flex-end">
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "0px 8px 18px 0px #06C05D14",
            padding: "5px",
            width: "30%",
            marginBottom: "1rem",
            border: "1px solid #EEEEEE",
            outline: focused ? "1px solid #06C05D" : "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              listLooms(e.target.value);
            }}
            placeholder="Search Here"
            style={{
              backgroundColor: "white",
              border: "none",
              outline: "none",
              padding: "5px",
              flex: 1,
            }}
          />
          <img
            src={SearchImg}
            alt="Search Icon"
            style={{
              height: "38px",
              width: "38px",
              padding: "4px",
              cursor: "pointer",
            }}
          />
        </Box>
      </Stack>
      <br />
      {loomList?.length > 0 &&
        loomList.map((val, ind) => (
          <>
            <Card
              style={{
                padding: "16px",
                boxShadow: "0px 8px 18px 0px #00000014",
                borderRadius: "8px",
                maxWidth: "800px",
                margin: "auto",
                backgroundColor: "#fff",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  height: "130px",
                }}
              >
                {/* Image Section */}
                {/* <Box
                  ref={(el) => (scrollRefs.current[ind] = el)}
                  sx={{
                    display: "flex",
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                    gap: 2,
                    padding: "10px 0",
                    width: "140px", // Adjust as needed
                  }}
                >
                  {val?.images?.map((img, imgIndex) => (
                    <CardMedia
                      key={imgIndex}
                      component="img"
                      style={{
                        width: "120px",
                        height: "100px",
                        borderRadius: "8px",
                        objectFit: "fill",
                      }}
                      image={img.url}
                      alt={`Image ${imgIndex + 1}`}
                    />
                  ))}
                </Box> */}

                {/* Service Details */}
                <CardContent style={{ flex: 1, paddingLeft: "16px" }}>
                  <Typography className={classes.textLabel}>
                    Loom Reg No:{" "}
                    <Typography component="span" className={classes.text}>
                      {val.loomRegistrationNo}
                    </Typography>
                  </Typography>
                  <Typography className={classes.textLabel}>
                    Loom Number:{" "}
                    <Typography component="span" className={classes.text}>
                      {val.loomNo}
                    </Typography>
                  </Typography>
                  <Typography className={classes.textLabel}>
                    Loom Type:{" "}
                    <Typography component="span" className={classes.text}>
                      {val.loomType}
                    </Typography>
                  </Typography>
                </CardContent>

                {/* Action Icon */}
                <IconButton
                  sx={{
                    marginLeft: "16px",
                  }}
                  onClick={handleMenuOpen}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  style={{
                    boxShadow: "0px 8px 18px 0px #00000014",
                    "& .MuiPaper-elevation8": {
                      boxShadow: "0px 8px 18px 0px #00000014",
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setOpenEdit(true);
                      setSelectedId(val._id);
                      getLoomDetails(val._id);
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      handleDelete();
                      setSelectedId(val._id);
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </Box>

              {/* Description */}
              <Typography
                variant="body2"
                style={{
                  fontSize: "16px",
                  color: "#737373",
                }}
              >
                {val.description}
              </Typography>
              <Box
                style={{
                  display: "flex",
                  gap: "16px",
                  marginTop: "16px",
                  flexWrap: "wrap",
                  backgroundColor: "#F9F9F9",
                }}
              >
                {val.images && val.images.length > 0 ? (
                  val?.images.map((image, index) => (
                    <Box
                      key={index}
                      style={{
                        position: "relative",
                        width: "120px",
                        height: "100px",
                        borderRadius: "8px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={image.url}
                        alt={`Loom ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                      {/* <IconButton
                style={{
                  position: "absolute",
                  top: "4px",
                  right: "4px",
                  backgroundColor: "#fff",
                  color: "#f44336",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                }}
                size="small"
                onClick={() => console.log("Delete image", index)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton> */}
                    </Box>
                  ))
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{
                      marginTop: "8px",
                      fontSize: "14px",
                    }}
                  >
                    No additional images available.
                  </Typography>
                )}
              </Box>
            </Card>
            <br />
          </>
        ))}

      <SlideInDialog
        title={props.t("Delete Loom")}
        contentComponent={props.t("dialog.delete")}
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus={true}
        slideInDialog={slideInDialog}
        setSlideInDialog={handleSlideInDialog}
        handleDeleteRequest={handleDeleteRequest}
        selectedId={selectedId}
      />

      {openEdit && (
        <Create
          open={openEdit}
          handleClose={handleClose}
          recordId={selectedId}
          createData={createData}
          setCreateData={setCreateData}
          sendToServer={sendToServer}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(LoomList)
);
// export default LoomList;
