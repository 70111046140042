import React, { useRef } from "react";
import useImageUpload from "./useImageUpload";
import ReactDOM from "react-dom";

import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@mui/icons-material/Add";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import download from "../../../../../assets/images/download.jpeg";

import {
  Typography,
  Dialog,
  Box,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import pdf from "../../../../../assets/images/pdfIcon.png";
import { useState } from "react";
import Item from "antd/es/list/Item";
import { Delete20Regular } from "@fluentui/react-icons";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Stack } from "@fluentui/react";

const ImagesUpload = ({ setUserForm, userForm }) => {
  const [previewImageIndex, setPreviewImageIndex] = useState(null);
  const handlePreviewImage = (index) => {
    setPreviewImageIndex(index);
  };
  const fileInputRef = useRef(null);
  const handleClosePreview = () => {
    setPreviewImageIndex(null);
  };
  const { onUpload, deleteImages } = useImageUpload(
    setUserForm,
    userForm,
    handleClosePreview
  );
  // console.log(userForm);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewVideo, setPreviewVideo] = useState(null);
  const [openFileUrl, setOpenFileUrl] = useState(null);

  const Icon = () => {
    return <img src={pdf} alt="pdf" width={24} height={24} />;
  };

  const handlePreviewVideo = (video) => {
    setPreviewVideo(video.url || video);
    setPreviewImage(null);
  };

  const handleOpenFile = (attachment) => {
    setOpenFileUrl(attachment.url);
  };

  const handleDownloadFile = (attachment) => {
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(7);
    const fileName = `file_${timestamp}_${randomString}.pdf`;

    const link = document.createElement("a");
    link.href = attachment.url;
    link.download = fileName;
    link.click();
  };

  const handleClosePreview2 = () => {
    setOpenFileUrl(null);
  };

  const displayImage = userForm?.media?.[0];
  const handleOpenImageInNewTab = (imageUrl) => {
    const newTab = window.open("", "_blank");
    newTab.document.write(
      `<html><body style="margin: 0;"><img src="${imageUrl}" style="width: 100%; height: 100%; object-fit: contain;"></body></html>`
    );
  };

  // const openPDFINNewTab = (pdf) => {
  //   const newTab = window.open();
  //   newTab.document.write(`
  //     <html>
  //       <body style="margin: 0;">
  //         <iframe src=${pdf} width="100%" height="100%" style="border: none;">
  //           <p>Your browser does not support PDFs. <a href=${pdf}>Download the PDF</a></p>
  //         </iframe>
  //       </body>
  //     </html>
  //   `);
  // };

  const openPDFINNewTab = (pdf) => {
    window.open(pdf, "_blank");
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  return (
    // <Stack style={{ gap: "4px", width: "100%" }}>
    //   {/* <Backdrop open={isUploading} />
    //   <Field className={classes.label} label={label}  required/> */}
    //   <Typography
    //     variant="subtitle1"
    //     style={{
    //       position: "relative",
    //       // top: "-25px",
    //       fontWeight: "bold",
    //       fontSize: "13px",
    //     }}
    //   >
    //     Uploads<span style={{ color: "red" }}>*</span>
    //   </Typography>
    //   <Stack
    //     horizontal
    //     horizontalAlign="space-between"
    //     style={{
    //       background: "white",
    //       padding: "16px",
    //       height: "142px",
    //       borderRadius: "8px",
    //       gap: "8px",
    //       border: "1px solid #E5E5E5",
    //       overflowX: "auto",
    //     }}
    //   >
    //     <Stack horizontal>
    //       {/* image array */}
    //       {userForm?.attachment?.length > 0 &&
    //         userForm?.attachment?.map(
    //           (value, i) =>
    //             value?.url && (
    //               <Stack style={{ position: "relative" }}>
    // <Stack
    //   verticalAlign="center"
    //   horizontalAlign="center"
    //   style={{
    //     width: "28px",
    //     height: "28px",
    //     background: "white",
    //     position: "absolute",
    //     borderRadius: "100%",
    //     top: -8,
    //     right: -5,
    //     cursor: "pointer",
    //   }}
    // >
    //   <Delete20Regular
    //     onClick={() => deleteImages(i)}
    //     className="deleteIcon"
    //     style={{ color: "#FF8D00" }}
    //   />
    // </Stack>
    //                 {value.url.endsWith(".pdf") ? (
    //                   <div
    //                     style={{ cursor: "pointer" }}
    //                     onClick={() => window.open(value.url, "_blank")}
    //                   >
    //                     <img
    //                       // src={value.url}
    //                       src="https://cdn-icons-png.flaticon.com/512/337/337946.png"
    //                       style={{
    //                         width: "100px",
    //                         height: "100px",
    //                         border: "none",
    //                         cursor: "pointer",
    //                       }}
    //                       title="PDF Preview"
    //                     />
    //                   </div>
    // ) : value.url.endsWith(".doc") ||
    //   value.url.endsWith(".docx") ? (
    //   <img
    //     style={{
    //       borderRadius: "10px",
    //       objectFit: "cover",
    //       cursor: "pointer",
    //     }}
    //     height={100}
    //     width={100}
    //     src="https://cdn-icons-png.flaticon.com/512/281/281760.png"
    //     alt="document"
    //     onClick={() => window.open(value.url, "_blank")}
    //   />
    // ) : value.url.endsWith(".mp4") ? (
    //   <div
    //     style={{ cursor: "pointer" }}
    //     onClick={() => window.open(value.url, "_blank")}
    //   >
    //     <img
    //       src={download} // Video icon
    //       style={{
    //         width: "100px",
    //         height: "100px",
    //         borderRadius: "10px",
    //         objectFit: "cover",
    //         cursor: "pointer",
    //       }}
    //       alt="Video Icon"
    //       title="Video Preview"
    //     />
    //   </div>
    // ) : (
    //                   <img
    //                     style={{
    //                       borderRadius: "10px",
    //                       objectFit: "cover",
    //                       cursor: "pointer",
    //                     }}
    //                     height={100}
    //                     width={100}
    //                     onClick={() => window.open(value.url, "_blank")}
    //                     src={value.url}
    //                     alt="clipperImage"
    //                   />
    //                 )}
    //               </Stack>
    //             )
    //         )}

    //       {/* <img
    //                   style={{
    //                     borderRadius: "10px",
    //                     width: "117px",
    //                     height: "100%",
    //                     objectFit: "cover",
    //                     cursor: "pointer",
    //                   }}
    //                   key={i}
    //                   onClick={() => {
    //                     const newWindow = window.open("", "_blank");
    //                     if (newWindow) {
    //                       const imgElement = new Image();
    //                       imgElement.onload = function () {
    //                         newWindow.resizeTo(
    //                           1000,
    //                           (imgElement.height / imgElement.width) * 1000
    //                         );
    //                         imgElement.style.width = "1000px";
    //                         newWindow.document.body.appendChild(imgElement);
    //                       };
    //                       imgElement.src = value?.url;
    //                     } else {
    //                       console.error("Failed to open new window");
    //                     }
    //                   }}
    //                   src={value?.url}
    //                   alt="clipperImage"
    //                 /> */}
    //       {/* </Stack>
    //             )
    //           )} */}
    //     </Stack>

    //     <Stack>
    //       <Stack>
    //         <label htmlFor="file4">
    //           <CloudUploadOutlinedIcon style={{ cursor: "pointer" }} />
    //         </label>

    //         <input
    //           accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .mp4"
    //           multiple
    //           id="file4"
    //           type="file"
    //           style={{ display: "none" }}
    //           onChange={onUpload}
    //         />
    //       </Stack>
    //     </Stack>
    //   </Stack>
    // </Stack>
    <div style={{ width: "100%", maxWidth: "100%" }}>
      {/* New UI: Drop files to attach or Browse */}
      <div
        style={{
          border: "1px dashed #ccc",
          borderRadius: "8px",
          padding: "16px",
          textAlign: "center",
          height: "80px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pinter",
          //   backgroundColor: "#f9f9f9",
          // cursor: disabled ? "not-allowed" : "pointer",
        }}
        onClick={handleUploadClick}
      >
        <input
          multiple
          ref={fileInputRef}
          type="file"
          // accept="image/*,.pdf"
          accept="video/*, .png, .jpg, .jpeg, .pdf, .doc, .docx, .mp4"
          style={{ display: "none" }}
          onChange={onUpload}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            cursor: "pinter",
          }}
        >
          <CloudUploadOutlinedIcon style={{ color: "#666" }} />
          <span style={{ color: "#666" }}>Choose files to attach or</span>
          <span style={{ color: "#0078d4", textDecoration: "underline" }}>
            Browse
          </span>
        </div>
      </div>

      {/* Display uploaded files below */}
      <Stack horizontal wrap style={{ marginTop: "16px", gap: "12px" }}>
        {userForm?.attachment?.map((attachment, index) => (
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100px",
              border: "1px solid #EFEFEF",
              borderRadius: "8px",
              overflow: "hidden",
              boxShadow:
                "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
              backgroundColor: "#fff",
              marginRight: "12px",
            }}
            key={index}
          >
            {/* Thumbnail for Images and Placeholder for PDFs */}
            {attachment.url.endsWith(".pdf") ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                  width: "100px",
                  backgroundColor: "#f5f5f5",
                }}
                onClick={() => window.open(attachment.url, "_blank")}
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                  alt="PDF Icon"
                  style={{ height: "50%", width: "50%" }}
                />
              </div>
            ) : attachment.url.endsWith(".doc") ||
              attachment.url.endsWith(".docx") ? (
              <img
                style={{
                  borderRadius: "10px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                height={100}
                width={100}
                src="https://cdn-icons-png.flaticon.com/512/281/281760.png"
                alt="document"
                onClick={() => window.open(attachment.url, "_blank")}
              />
            ) : attachment.url.endsWith(".mp4") ? (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => window.open(attachment.url, "_blank")}
              >
                <img
                  src={download} // Video icon
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "10px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  alt="Video Icon"
                  title="Video Preview"
                />
              </div>
            ) : (
              <img
                src={attachment.url}
                alt={`Attachment ${index}`}
                style={{
                  height: "100px",
                  width: "100px",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                onClick={() => window.open(attachment.url, "_blank")}
              />
            )}

            {/* Delete Icon */}
            {/* <BundleIcon
              regularIcon={Delete20Regular}
              filledIcon={Delete20Filled}
              style={{
                position: "absolute",
                top: "4px",
                right: "4px",
                backgroundColor: "#fff",
                borderRadius: "50%",
                padding: "4px",
                cursor: "pointer",
                visibility: disabled ? "hidden" : "visible",
              }}
              onClick={() => handleDeleteAttachment(index)}
            /> */}
            <Stack
              verticalAlign="center"
              horizontalAlign="center"
              style={{
                width: "38px",
                height: "38px",
                background: "white",
                position: "absolute",
                borderRadius: "100%",
                top: -8,
                right: -5,
                cursor: "pointer",
              }}
            >
              <Delete20Regular
                onClick={() => deleteImages(index)}
                className="deleteIcon"
                style={{ color: "#FF8D00" }}
              />
            </Stack>
          </div>
        ))}
      </Stack>
    </div>
  );
};

export default ImagesUpload;
