import React, { useEffect, useRef, useState } from "react";
import { Translation, withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Box,
  styled,
  Checkbox,
  TextareaAutosize,
  Input,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../../utils/APIRequest.js";
import FormValidation from "../../../../../utils/FormValidation.js";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL.js";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../../utils/SnackbarUtils.js";
import { Stack } from "@mui/material";
import { Chip } from "@mui/material";
import landingImg from "../../../../../assets/images/Pic.png";
import DownloadIcon from "@mui/icons-material/Download";
// import ImageUpload from "./ImageUpload";
import Toaster from "../../../../../utils/Toaster.js";
import isEmpty from "../../../../../utils/isEmpty.js";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ImagesUpload from "./ImagesUpload.jsx";
import MicIcon from "@material-ui/icons/Mic";
import LocalStorage from "../../../../../config/LocalStorage.js";
import { CheckValidation } from "../../../../../utils/Validator.js";
// import WaveSurfer from "wavesurfer.js";
import {
  Delete20Filled,
  Pause20Filled,
  Play20Filled,
} from "@fluentui/react-icons";
import { DateConvert } from "../../../../../utils/DataConvert.js";
import { Button } from "@fluentui/react";
import { Divider } from "material-ui";
import LogMessage from "./LogMessages.jsx";
import ChatInput from "./ChartInput.jsx";
import { WhatsApp } from "@material-ui/icons";
import LogMessages from "../../../../../utils/LogMessages.js";
import SlideInDialog from "../../../../../templates/dialog/SlideInDialog.js";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  approveButton: {
    color: "white",
    backgroundColor: "red",
    boxShadow: "0 2px 4px red",
  },
  rejectButton: {
    color: "black",
    backgroundColor: "yellow",
    boxShadow: "0 2px 4px red",
  },
  approve: {
    color: "green",
    borderColor: "green",
    background: "#FFECD3",
    padding: "10px",
    borderRadius: "10px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#FFECD3",
      color: "green",
    },
  },
  reject: {
    color: "red",
    borderColor: "red",
    background: "#FFECD3",
    padding: "10px",
    borderRadius: "10px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#FFECD3",
      color: "red",
    },
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const postDetails = {
  description: "",
  referenceUrl: "",
  fragmentedAddress: "",
  mobileNo: "",
  name: "",
  title: "",
  serviceTag: "",
  businessWhatsappNumber: "",
  shopLicense: "",
  coverImage: "",
  panCard: "",
  gst: "",
  bankAccount: "",
  location: {
    type: "Point",
    coordinates: ["", ""],
    name: "",
  },
  sendRequest: "",
  upiId: "",
  ifcNo: "",
  bankNumber: "",
};

function AddUser(props) {
  const [selectedOption, setSelectedOption] = useState("");

  const [location, setLocation] = useState("");
  const [places, setPlaces] = useState([]);
  const [placeId, setPlaceId] = useState([]);
  const [locationNames, setlocationNames] = useState("");
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [userForm, setUserForm] = useState(postDetails);
  const [categorydata, setCategoryData] = useState([]);
  const [sectorlist, setSectorlist] = useState([]);
  const [addplan, setAddPlan] = useState([]);
  const [selectedButton, setSelectedButton] = useState("");
  const [createdFor, setCreatedFor] = useState("");
  // const [handloom, setHandloom] = useState(postDetails.sector);
  const userDetails = LocalStorage.userDetails;
  const { listLogMessages, listLogs, createLogMessage } = LogMessages();
  const [isOpen, setIsOpen] = useState(false);
  const disabledData = props.formDialog.divType === "view";

  useEffect(() => {
    if (selectedRowId) {
      listLogMessages("members", selectedRowId);
    }
  }, [selectedRowId]);

  useEffect(() => {
    //changes made here
    // setUserForm(postDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (props?.formDialog?.functionName === "reset") {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    setUserForm(JSON.parse(JSON.stringify(postDetails)));
  };
  const categorylist = () => {
    APIRequest.request("GET", ConfigAPIURL.listcategory, "").then(
      (response) => {
        let userResult = response?.data?.result;
        setCategoryData(userResult);
        // console.log(categorydata, "categorydata");
      }
    );
  };

  useEffect(() => {
    categorylist();
  }, []);
  // console.log(categorydata, "data");

  //getting data for sector
  const sectorListData = () => {
    APIRequest.request("GET", ConfigAPIURL.sectorData, "").then((response) => {
      let userResult = response?.data?.result;
      setSectorlist(userResult);
      // console.log(sectorlist, "sectorData");
    });
  };

  useEffect(() => {
    sectorListData();
  }, []);

  // console.log(sectorlist, "sectorlist");

  const getEditable = () => {
    if (
      (props.rowDetails?._id || selectedRowId) &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.getServiceDetails,
        JSON.stringify({ recordId: props.rowDetails?._id || selectedRowId })
        //  +
        //   "?recordId=" +
        //   `${props.rowDetails?._id}`
      ).then((response) => {
        // console.log(response, "response");
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let serviceDetails = response?.data?.result;
            console.log(serviceDetails, "postResult");
            setUserForm({
              ...userForm,
              fragmentedAddress: serviceDetails?.fragmentedAddress,
              attachment: serviceDetails?.attachment,
              subject: serviceDetails?.subject,
              title: serviceDetails?.title,
              serviceTag: serviceDetails?.serviceTag,
              createdAt: serviceDetails?.createdAt,
              name: serviceDetails?.userId?.name,
              mobileNo: serviceDetails?.userId?.mobileNo,
              sendRequest: serviceDetails?.sendRequest,
              businessWhatsappNumber: serviceDetails?.businessWhatsappNumber,
              shopLicense: serviceDetails?.shopLicense,
              coverImage: serviceDetails?.coverImage,
              panCard: serviceDetails?.panCard,
              gst: serviceDetails?.gst,
              bankAccount: serviceDetails?.bankAccount,
              upiId: serviceDetails?.upiId,
              ifcNo: serviceDetails?.ifcNo,
              bankNumber: serviceDetails?.bankNumber,
              location: {
                type: serviceDetails.location.type,
                coordinates: [
                  serviceDetails?.location?.coordinates[0],
                  serviceDetails?.location?.coordinates[1],
                ],
                name: serviceDetails?.location?.name,
              },
              // images: postResult?.images,
            });
            setCreatedFor(serviceDetails?.userId?._id);
            setSelectedRowId(serviceDetails._id);
          }
        }
      });
    }
  };

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        // handleChange();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    let url = ConfigAPIURL.postCreate;
    let method = "POST";

    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.getServiceUpdate;
      userForm["recordId"] = selectedRowId;
      method = "POST";
    }

    let formCopy = { ...userForm };
    // delete formCopy.attachment;
    delete formCopy.range;
    delete formCopy.fragmentedAddress;
    delete formCopy.location;
    delete formCopy.referenceUrl;
    delete formCopy?.startTime;
    delete formCopy?.media;
    delete formCopy?.subject;
    delete formCopy?.tag;
    delete formCopy?.mobileNo;
    delete formCopy?.name;
    delete formCopy?.address;
    delete formCopy?.title;
    delete formCopy?.audio;
    delete formCopy?.tagNo;
    delete formCopy?.sector;
    delete formCopy?.isPermanent;

    const missingFields = CheckValidation(formCopy);
    console.log(missingFields, "misssingFields");
    // if (missingFields.length > 0 || formCopy?.attachment?.length === 0) {
    //   SnackbarUtils.error(
    //     "Please fill all the mandatory fields",
    //     "bottomCenter",
    //     3000
    //   ).then((notification) => {
    //     props.publishNotification(notification);
    //   });
    //   return;
    // }
    if (selectedButton) {
      userForm.sendRequest = selectedButton;
    }
    let userFormCopy = { ...userForm };

    APIRequest.request(method, url, JSON.stringify(userFormCopy)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            SnackbarUtils.sucess(
              props.t("snackbar.updatedSuccess"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            props.handleFormDialog();
            props.tableQuery({
              keyword: "",
              page: 0,
              pageSize: 10,
              sortField: "",
              userType: "all",
              sortOrder: "false",
              postStatus: "all",
              active: true,
            });
            setUserForm(postDetails);
            setSelectedRowId("");
          }
          if (response.code === 100 && response.data.responseCode === 114) {
            SnackbarUtils.error(
              props.t("snackbar.dupliRecords"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      }
    );
  };
  //functions for getting location cordinates
  const handleChange = async (event) => {
    const placeName = event.target.value;

    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getLocation}?place=${placeName}`
    ).then((res) => {
      // console.log(res.data.result.predictions);
      setPlaces(res.data.result.predictions);
    });
    setLocation("");
  };

  const handleLocation = async () => {
    // console.log("this is evee optionsss", eve);
    // setlocationNames(selectedOption.description);

    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getLonLan}?place_id=${placeId}`
    ).then((res) => {
      setLocation(res.data?.result?.result?.geometry?.location);
      setUserForm((prev) => ({
        ...prev,
        location: {
          ...prev?.location,
          coordinates: [
            res.data?.result?.result?.geometry?.location?.lng,
            res.data?.result?.result?.geometry?.location?.lat,
          ],
        },
      }));
    });
  };
  useEffect(() => {
    handleLocation();
  }, [placeId]);

  useEffect(() => {
    if (selectedOption) {
      // setSelectedOption(selectedOption);
      setlocationNames(selectedOption.description);
      setPlaceId(selectedOption);
      places.find((option, i) => {
        if (option?.description === selectedOption) {
          setPlaceId(option?.place_id);
        }
      });
      // setAddForm({
      //   ...addForm,
      //   locationName: selectedOption.description,
      // });
      handleLocation();
    }
  }, [selectedOption, placeId]);

  const getPlans = () => {
    APIRequest.request("POST", ConfigAPIURL.plans).then((response) => {
      let userResult = response?.data?.rows;
      setAddPlan(userResult);
      // console.log(userResult);
    });
  };
  useEffect(() => {
    getPlans();
  }, []);
  // console.log("plan", addplan);
  // console.log(userForm, "userForm");

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: "black",
  }));
  // console.log(plan, "planid");
  const expiresOn = "companyName"?.startDate + "companyName"?.endAt * 86400;
  const rangeOptions = [5, 10, 15, 25, 35, "max"];
  const validityTypeOptions = [
    { label: props.t("Permanent"), value: true },
    { label: props.t("Temporary"), value: false },
  ];

  //handaling range function here
  const handleRangeChange = (event, newValue) => {
    let updatedRange = newValue;

    if (newValue === "max") {
      updatedRange = null; // Set range to null when "max" is selected
    } else if (newValue && !Number.isNaN(Number(newValue))) {
      updatedRange = parseInt(newValue); // Convert numeric range values to integers
    }

    setUserForm({
      ...userForm,
      range: updatedRange,
    });
  };
  const handleRejectApprove = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.getServiceUpdate,
        JSON.stringify({ recordId: selectedRowId, sendRequest: selectedButton })
      );

      if (response?.data?.responseCode === 109) {
        getEditable();
        setIsOpen(false);
        SnackbarUtils.sucess(
          props.t(response?.data?.message),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      } else {
        console.log(response);
        SnackbarUtils.sucess(
          props.t(response?.data?.message),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } catch (error) {
      console.error("Error fetching location:", error.message || error);
    }
  };
  console.log(userForm, "userForm");
  return (
    <div
      className={classes.root}
      style={{
        marginTop: props.marginTop,
        fontFamily: "Lato",
        position: "relative",
        top: "-13px",
      }}
    >
      <Stack style={{ padding: "20px" }}>
        {/* buttons stack */}

        <Stack
          style={{ background: "#FFECD3", height: " 60px", zIndex: "1" }}
          sx={{
            flexDirection: { lg: "row", sm: "row", md: "row" },
            justifyContent: "space-between",
            rowGap: "10px",
          }}
        >
          <Stack style={{ flexDirection: "row", gap: "7px" }}>
            <Stack
              style={{
                paddingBottom: "3px",
              }}
            >
              <Typography
                className="personaldetails"
                style={{
                  fontWeight: "800",
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "20px",
                  underline: "none",
                  position: "relative",
                  top: "10px",
                  left: "27px",
                }}
              >
                Member Details (Service)
              </Typography>
            </Stack>
          </Stack>
          {props.formDialog.divType === "view" && (
            <Stack
              style={{
                flexDirection: "row",
                gap: "7px",
                marginTop: "10px",
                marginRight: "30px",
              }}
            >
              <Button
                className={classes.reject}
                onClick={() => {
                  setSelectedButton("rejected");
                  setIsOpen(true);
                }}
                style={{
                  color: selectedButton === "rejected" ? "white" : "red",
                  backgroundColor: selectedButton === "rejected" ? "red" : null,
                  display: userForm?.sendRequest === "approved" && "none",
                }}
                disabled={userForm?.sendRequest !== "pending"}
              >
                Reject
              </Button>
              <Button
                className={classes.approve}
                onClick={() => {
                  setSelectedButton("approved");
                  setIsOpen(true);
                }}
                style={{
                  color: selectedButton === "approved" ? "white" : "green",
                  backgroundColor:
                    selectedButton === "approved" ? "green" : null,
                  display: userForm?.sendRequest === "rejected" && "none",
                }}
                disabled={userForm?.sendRequest !== "pending"}
              >
                Approve
              </Button>
            </Stack>
          )}
        </Stack>

        {/* details stack of users */}

        <Stack
          style={{
            padding: "20px",
            background: "white",
            borderRadius: "8px",
            marginTop: "20px",
            position: "relative",
            top: "-24px",
          }}
        >
          <>
            <Stack>
              <Grid container spacing={2}>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    label={props.t("ID Number")}
                    autoComplete="something-unsupported"
                    value={userForm.serviceTag}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    // onChange={(event) =>
                    //   setUserForm({
                    //     ...userForm,
                    //     referenceUrl: event.target.value,
                    //   })
                    // }
                    disabled={true}
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    label={props.t("Name")}
                    autoComplete="something-unsupported"
                    value={userForm?.name}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        name: event.target.value,
                      })
                    }
                    disabled={true}
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    label={props.t("Mobile Number")}
                    autoComplete="something-unsupported"
                    value={userForm?.mobileNo}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        mobileNo: event.target.value,
                      })
                    }
                    disabled={true}
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    label={props.t("Title")}
                    autoComplete="something-unsupported"
                    value={userForm.title}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        title: event.target.value,
                      })
                    }
                    disabled={
                      props.formDialog.divType === "new" ||
                      props.formDialog.divType === "edit"
                        ? false
                        : true
                    }
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={places?.map((option, i) => option?.description)}
                    // sx={{  height:"54px"}}
                    value={userForm?.location?.name || ""}
                    // getOptionLabel={(option) => option.description}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handleChange}
                        label={"Select location"}
                        variant="outlined"
                        // value={addForm.locationName}
                      />
                    )}
                    onChange={(event, value) => {
                      setSelectedOption(value);
                      setUserForm((prev) => ({
                        ...prev,
                        location: {
                          ...prev.location,
                          name: value,
                        },
                      }));
                    }}
                    disabled={disabledData}
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    label={props.t("Whatsapp Number")}
                    autoComplete="something-unsupported"
                    value={userForm?.businessWhatsappNumber}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        businessWhatsappNumber: event.target.value,
                      })
                    }
                    disabled={disabledData}
                  />
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    label={props.t("UPI Id")}
                    autoComplete="something-unsupported"
                    value={userForm?.upiId}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        upiId: event.target.value,
                      })
                    }
                    disabled={disabledData}
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    label={props.t("Bank Account")}
                    autoComplete="something-unsupported"
                    value={userForm?.bankNumber}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        bankNumber: event.target.value,
                      })
                    }
                    disabled={disabledData}
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="referenceUrl"
                    InputProps={{
                      style: {
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    }}
                    label={props.t("IFSC COde")}
                    autoComplete="something-unsupported"
                    value={userForm?.ifcNo}
                    fullWidth
                    style={{ width: "100%", borderRadius: "5px" }}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        ifcNo: event.target.value,
                      })
                    }
                    disabled={disabledData}
                  />
                </Grid>
              </Grid>

              <Stack
                style={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                  },
                  marginTop: "16px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <ImagesUpload
                      setUserForm={setUserForm}
                      userForm={userForm}
                      label="Cover Image"
                      value="coverImage"
                      disabled={disabledData}
                    />
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <ImagesUpload
                      setUserForm={setUserForm}
                      userForm={userForm}
                      label="Shop License"
                      value="shopLicense"
                      disabled={disabledData}
                    />
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <ImagesUpload
                      setUserForm={setUserForm}
                      userForm={userForm}
                      label="GST"
                      value="gst"
                      disabled={disabledData}
                    />
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <ImagesUpload
                      setUserForm={setUserForm}
                      userForm={userForm}
                      label="Pan Card"
                      value="panCard"
                      disabled={disabledData}
                    />
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <ImagesUpload
                      setUserForm={setUserForm}
                      userForm={userForm}
                      label="Bank Account"
                      value="bankAccount"
                      disabled={disabledData}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
            <br />
            <hr />
            <br />
            {listLogs?.length > 0
              ? listLogs?.map((val, ind) => (
                  <LogMessage
                    message={val?.sendMessage}
                    userName={val?.createdBy?.name}
                    time={val?.createdAt}
                    isShowLast={listLogs?.length - 1 === ind}
                  />
                ))
              : "No logs present"}

            <ChatInput
              createLogMessage={createLogMessage}
              recordId={selectedRowId}
              listLogMessages={listLogMessages}
              moduleType="members"
              createdFor={createdFor}
            />
          </>
        </Stack>
      </Stack>

      <SlideInDialog
        // title={props.t("Delete Post")}
        contentComponent={props.t(
          `Are you sure you want to ${
            selectedButton === "rejected" ? "Reject" : "Approve"
          } ?`
        )}
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus="ok"
        slideInDialog={isOpen}
        setSlideInDialog={setIsOpen}
        handleDeleteRequest={handleRejectApprove}
        enquires={true}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AddUser)
);
